import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { filterTimeByUserIdForAdmin } from "../../actions/admin.time.action";
import jsPDF from "jspdf";
import "jspdf-autotable";
import formatDate, {
  formatMonthDate,
  API,
  holidays,
  unBilledDesignations,
  customStyles,
} from "../../utils/services";
import Select from "react-select";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Menu,
  MenuItem,
  Typography,
  IconButton,
} from "@mui/material";
import { Download, Mail, Search } from "@mui/icons-material";
import { useMattersOptions } from "../../helpers/hooks/useMattersOptions";
import { useEmployeeOptions } from "../../helpers/hooks/useEmployeesOptions";
import axiosInstance from "../../helpers/axios";
import NoDataFound from "../../components/NoDataFound";
import { COLORS } from "../../constants/theme";
import TableSkeletonLoader from "../../components/ui/TableSkeletonLoader";
import CustomTooltip from "../../components/ui/CustomTootltip";
import CustomDateRangePicker from "../../components/ui/CustomDateRangePicker";

const PerformanceReport = () => {
  const dispatch = useDispatch();

  const [filteredWorkHour, setFilteredWorkHour] = useState([]);
  const [writeOffHours, setWriteOffHours] = useState([]);

  const { matters, filteredMatters } = useMattersOptions();
  const { employeesData, filteredEmployees } = useEmployeeOptions();

  //redux states
  const auth = useSelector((state) => state.auth);

  //useStates
  const [client, setClient] = useState("");
  const [matterId, setMatterId] = useState(""); //matter id
  const [selectedMatterName, setSelectedMatterName] = useState(""); //matter name
  const [selectedLawyer, setSelectedLawyer] = useState("");
  const [startSelectedDate, setStartSelectedDate] = useState(() => {
    const today = new Date();
    const pastDate = new Date(today.setDate(today.getDate() - 6));
    return formatDate(pastDate);
  });
  const [endSelectedDate, setEndSelectedDate] = useState(
    formatDate(new Date())
  );
  const [showResourceData, setShowResourceData] = useState(true);
  const [showMatterData, setShowMatterData] = useState(false);
  const [leaves, setLeaves] = useState([]);
  const needFin = false;
  const [loading, setLoading] = useState(true);

  const [sortedData, setSortedData] = useState([]);
  const [totalTeamTime, setTotalTeamTime] = useState(0);
  const [totalTeamBillableTime, setTotalTeamBillableTime] = useState(0);
  const [totalNonBillableTime, setTotalNonBillableTime] = useState(0);
  const [totalDays, setTotalDays] = useState(0);

  const [anchorElSearch, setAnchorElSearch] = useState(null);
  // State for download menu
  const [anchorElDownload, setAnchorElDownload] = useState(null);

  // Handle search menu open and close
  const handleSearchClick = (event) => {
    setAnchorElSearch(event.currentTarget);
  };

  const handleSearchClose = () => {
    setAnchorElSearch(null);
  };

  // Handle download menu open and close
  const handleDownloadClick = (event) => {
    setAnchorElDownload(event.currentTarget);
  };

  const handleDownloadClose = () => {
    setAnchorElDownload(null);
  };

  const limit = 0;
  const page = 0;

  //dates variables
  const formattedStartDate = formatMonthDate(startSelectedDate);
  const formattedEndDate = formatMonthDate(endSelectedDate);

  //varirables for matterwise data
  const adminId = auth.user._id;
  let allMatterTotalBillableAmount = 0;
  let allMatterTotalBilledAmount = 0;
  let teamTotalDiscount = 0;
  let totalHoursClocked = 0;
  let leaveDays;
  let finalDays;

  //useEffects
  useEffect(() => {
    const loadData = async () => {
      setLoading(false);
      setLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 1500)); // Simulating API call delay
    };
    filterBetweenDates();
    filterAndSortData();
    loadData();
  }, [dispatch]);

  useEffect(() => {
    if (leaves && filteredWorkHour) {
      // Assuming you have states to track these
      filterAndSortData();
    }
  }, [leaves, filteredWorkHour]);

  let sendMail = false;

  const totalTeamAverageBillableHourPerDay =
    totalDays !== 0 ? parseFloat(totalTeamTime / totalDays).toFixed(2) : 0;

  const totalTeamAverageBilledHourPerDay =
    totalDays !== 0
      ? parseFloat(totalTeamBillableTime / totalDays).toFixed(2)
      : 0;

  // console.log("Employees", employeesData)

  const uniqueNames =
    employeesData.length > 0
      ? employeesData
          .filter((item) => !unBilledDesignations.includes(item.designation))
          .map((item) => item?.fullName)
      : [];

  const filteredNames =
    filteredWorkHour && filteredWorkHour.length > 0
      ? [
          ...new Set(
            filteredWorkHour &&
              filteredWorkHour.map((item) => item.user?.fullName)
          ),
        ]
      : [];

  const commonNames = uniqueNames.filter((name) =>
    filteredNames.includes(name)
  );

  //sort the matters to show data in clientwise format
  const sortedMatters = matters
    .filter((item) => item.billable === true && item.status === "enable")
    .sort((a, b) => {
      const clientA = a.client.name.toLowerCase();
      const clientB = b.client.name.toLowerCase();

      if (clientA < clientB) {
        return -1; // a comes before b
      }
      if (clientA > clientB) {
        return 1; // a comes after b
      }
      return 0; // a and b are equal
    });

  //Matterwise sorted data filteration
  const matterwiseSortedData = sortedMatters
    .map((matter) => {
      let currency;

      if (matter) {
        currency = matter.currency === "USD" ? "$" : "Rs. ";
        // console.log("Currency: ", currency);
        // Use the currency value as needed
      } else {
        currency = "";
        // console.log("Matter not found.");
      }

      let matterTotalBilledAmount = 0;
      let persontotalHours = 0;
      let teamTotalHours = 0;
      let teamTotalBilledHours = 0;
      let teamTotalBilledAmount = 0;
      let teamTotalBillableAmount = 0;

      commonNames.forEach((name) => {
        let personWorkHours = 0;
        let personWriteoffHours = 0;

        filteredWorkHour &&
          filteredWorkHour
            .filter(
              (result) =>
                result.user?.fullName === name &&
                result.matter?.name === matter?.name
            )
            .forEach((result) => {
              const workHour = parseFloat(result.workHour);
              personWorkHours += workHour;
            });

        writeOffHours
          .filter(
            (result) =>
              result.user?.fullName === name && result.matter._id === matter._id
          )
          .forEach((result) => {
            const workHour = parseFloat(result.discountedHours);
            personWriteoffHours += workHour;
          });

        persontotalHours =
          parseFloat(personWorkHours) + parseFloat(personWriteoffHours);

        const newMatter = matters.find(
          (matterItem) => matterItem?.name.trim() === matter?.name.trim()
        );

        let price;
        if (newMatter) {
          if (newMatter.pricePerHour) {
            price = newMatter.pricePerHour.$numberDecimal;
          } else if (newMatter.resourceSpecificPrice.length !== 0) {
            const employeeEntry = newMatter.resourceSpecificPrice.find(
              (entry) => entry.includes(name)
            );

            if (employeeEntry) {
              const [, hours] = employeeEntry.split(":");
              price = hours.trim();
            }
          } else {
            price = 0;
          }
        }

        // console.log("Price ---------", price, persontotalHours);

        if (price) {
          const personTotalBilledAmount =
            matter.currency === "USD"
              ? price * personWorkHours
              : (price * personWorkHours) / 80;
          const personTotalBillableAmount =
            matter.currency === "USD"
              ? price * (personWorkHours + personWriteoffHours)
              : (price * (personWorkHours + personWriteoffHours)) / 80;
          teamTotalHours += persontotalHours;
          teamTotalBilledHours += personWorkHours;
          teamTotalBillableAmount += personTotalBillableAmount;
          teamTotalBilledAmount += personTotalBilledAmount;
        }
      });

      // Calculate teamTotalHours only if it's greater than 0
      if (teamTotalHours > 0) {
        allMatterTotalBillableAmount += teamTotalBillableAmount;
        allMatterTotalBilledAmount += teamTotalBilledAmount;
        teamTotalDiscount = teamTotalBillableAmount - teamTotalBilledAmount;
        totalHoursClocked += teamTotalHours;

        // Return the mapped object only if teamTotalHours is greater than 0
        return {
          matter,
          teamTotalBillableAmount,
          teamTotalBilledAmount,
          teamTotalHours,
        };
      }

      // If teamTotalHours is 0 or less, return null (or an empty object, as needed)
      return null;
    })
    .filter((mappedObject) => mappedObject !== null); // Remove null values from the result

  const generateMatterWisePR = () => {
    const doc = new jsPDF({
      orientation: "landscape", // Set the orientation to landscape
    });

    const tableColumns = [
      "Client",
      "Matter",
      "Actual Time Clocked",
      "Time Billed",
      "Discount",
      "Discount %",
      "Contribution To Total",
      "Hours Clocked",
      "ERPH",
    ];
    const tableData = [];

    matterwiseSortedData.forEach((rowData, index) => {
      const {
        matter,
        teamTotalBillableAmount,
        teamTotalBilledAmount,
        teamTotalHours,
      } = rowData;

      const tableDataArray = [
        matter?.client?.name,
        matter?.name,
        teamTotalBillableAmount
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        teamTotalBilledAmount
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        (teamTotalBillableAmount - teamTotalBilledAmount)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        ((
          ((teamTotalBillableAmount - teamTotalBilledAmount) /
            teamTotalBillableAmount) *
          100
        ).toFixed(2) !== "NaN"
          ? (
              ((teamTotalBillableAmount - teamTotalBilledAmount) /
                teamTotalBillableAmount) *
              100
            ).toFixed(2)
          : (0).toFixed(2)) + "%",
        ((teamTotalBilledAmount * 100) / allMatterTotalBilledAmount).toFixed(
          1
        ) + "%",
        teamTotalHours.toFixed(2),
        (teamTotalBilledAmount / teamTotalHours).toFixed(2) !== "NaN"
          ? (teamTotalBilledAmount / teamTotalHours).toFixed(2)
          : "0.00",
      ];

      tableData.push(tableDataArray);
    });

    const footerRow = [
      {
        content: "Total Amount  (billed in USD)",
        colSpan: 2,
        styles: {
          fillColor: [97, 68, 58],
          textColor: [255, 255, 255],
          fontStyle: "bold",
        },
      },
      {
        content: allMatterTotalBillableAmount
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        // colSpan: 3,
        styles: {
          fillColor: [97, 68, 58],
          textColor: [255, 255, 255],
          fontStyle: "bold",
        },
      },
      {
        content: allMatterTotalBilledAmount
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        // colSpan: 3,
        styles: {
          fillColor: [97, 68, 58],
          textColor: [255, 255, 255],
          fontStyle: "bold",
        },
      },
    ];

    const footerRow1 = [
      {
        content: "Total Amount (billed in Rs.)",
        colSpan: 2,
        styles: {
          fillColor: [97, 68, 58],
          textColor: [255, 255, 255],
          fontStyle: "bold",
        },
      },
      (allMatterTotalBillableAmount * 80)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      (allMatterTotalBilledAmount * 80)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      teamTotalDiscount
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      "",
      "",
      totalHoursClocked.toFixed(2),
    ];

    tableData.push(footerRow);
    tableData.push(footerRow1);

    //First Page
    doc.setFontSize(25);
    doc.setFont("helvetica", "bold");
    doc.text(
      "Matterwise Performance Report",
      (297 - doc.getTextWidth("Matterwise Performance Report")) / 2,
      20
    );
    doc.setFont("helvetica", "underline");
    doc.setFontSize(15);
    doc.text(
      `${formattedStartDate}` +
        (startSelectedDate !== endSelectedDate ? ` - ${formattedEndDate}` : ""),
      (297 -
        doc.getTextWidth(
          `${formattedStartDate}` +
            (startSelectedDate !== endSelectedDate
              ? ` - ${formattedEndDate}`
              : "")
        )) /
        2,
      30
    );

    doc.autoTable(tableColumns, tableData, {
      startY: 40,
      theme: "grid", // Set the theme to 'grid' to display internal lines
      tableLineColor: [0, 0, 0], // Color of the table lines (black in this example)
      tableLineWidth: 0.1, // Width of the table lines in units (default is 0.1)
      didParseCell: function (data) {
        if (data.row.index === 0 && data.row.section === "head") {
          data.cell.styles.fillColor = [136, 106, 71];
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
        }
        if (
          data.row.index === data.table.body.length - 1 &&
          data.row.section === "body"
        ) {
          data.cell.styles.fillColor = [97, 68, 58];
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.fontStyle = "bold";
        }
        if (data.row.section === "body") {
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
        }
        if (
          data.row.section === "body" &&
          data.column.index !== 0 &&
          data.column.index !== 1
        ) {
          data.cell.styles.halign = "right";
          data.cell.styles.cellWidth = 27;
        }
        if (data.column.index === 1 && data.column.index === 0) {
          data.cell.styles.cellWidth = 80;
        }
      },
    });

    doc.save("Matterwise_Performance_Report.pdf");
  };

  //sortedData for resourcewise performance report
  const filterAndSortData = () => {
    let totalTeamTime = 0;
    let totalTeamBillableTime = 0;
    let totalNonBillableTime = 0;
    let totalDays = 0; // Assuming this is what you meant by "total final days"

    // console.log("commonNames", commonNames);

    const data = commonNames
      .map((name) => {
        const personWorkHours =
          filteredWorkHour &&
          filteredWorkHour.filter((item) => item.user?.fullName === name);

        //writeoff entries data filteration
        const personWriteoffEntries = writeOffHours.filter(
          (result) => result.user?.fullName === name
        );

        const personWriteoffHours =
          personWriteoffEntries.reduce(
            (total, result) => total + parseFloat(result.discountedHours),
            0
          ) || 0.0;

        //billable and non billable entries data filteration
        const personBillableTime =
          personWorkHours
            .filter((item) => item.billable === true)
            .reduce((total, item) => total + parseFloat(item.workHour), 0) ||
          0.0;

        const personNonBillableTime =
          personWorkHours
            .filter((item) => item.billable === false)
            .reduce((total, item) => total + parseFloat(item.workHour), 0) ||
          0.0;

        //Calculating total leave days with holidays
        leaveDays = leaves
          .filter(
            (leave) =>
              leave.user.fullName === name &&
              leave.leaveStartDate >= startSelectedDate &&
              leave.leaveEndDate <= endSelectedDate
          )
          .reduce((total, item) => (total += parseFloat(item.leaveDays)), 0);

        // console.log("Leave Days", name, leaveDays);

        const holidaysWithinLeavePeriod = holidays.filter((holiday) => {
          return holiday >= startSelectedDate && holiday <= endSelectedDate;
        });

        const holidayDays = holidaysWithinLeavePeriod.length;
        const totalLeaveDaysWithHolidays = leaveDays + holidayDays;

        // console.log("totalLeaveDaysWithHolidays", name, leaveDays, "hd",holidayDays, totalLeaveDaysWithHolidays)

        let totalDaysAfterJoining = 0;

        const joiningDate =
          employeesData.filter((employee) => employee.fullName === name)[0]
            .joiningDate !== ""
            ? employeesData.filter((employee) => employee.fullName === name)[0]
                .joiningDate
            : "";
        // console.log("Joining date", name, joiningDate);

        if (startSelectedDate < joiningDate) {
          let currentDate = new Date(joiningDate); // Use joiningDate as the starting point
          while (currentDate <= new Date(endSelectedDate)) {
            const dayOfWeek = currentDate.getDay();
            if (dayOfWeek !== 0 && dayOfWeek !== 6) {
              // Increment totalDaysAfterJoining only on weekdays (Monday to Friday)
              totalDaysAfterJoining++;
            }
            currentDate.setDate(currentDate.getDate() + 1);
          }
        } else {
          let currentDate = new Date(startSelectedDate); // Use joiningDate as the starting point
          while (currentDate <= new Date(endSelectedDate)) {
            const dayOfWeek = currentDate.getDay();
            if (dayOfWeek !== 0 && dayOfWeek !== 6) {
              // Increment totalDaysAfterJoining only on weekdays (Monday to Friday)
              // console.log(dayOfWeek)
              totalDaysAfterJoining++;
            }
            currentDate.setDate(currentDate.getDate() + 1);
          }
        }

        finalDays = totalDaysAfterJoining - totalLeaveDaysWithHolidays;

        const averageBillableHoursPerDay =
          parseFloat(
            (personBillableTime + personWriteoffHours) / finalDays
          ).toFixed(2) !== "Infinity"
            ? parseFloat(
                (personBillableTime + personWriteoffHours) / finalDays
              ).toFixed(2)
            : "0.0";

        const averageBilledHoursPerDay =
          parseFloat(personBillableTime / finalDays).toFixed(2) !== "Infinity"
            ? parseFloat(personBillableTime / finalDays).toFixed(2)
            : "0.0";

        // Aggregating data
        if (personBillableTime > 0) {
          totalTeamTime += personBillableTime + personWriteoffHours;
          totalTeamBillableTime += personBillableTime;
          totalNonBillableTime += personNonBillableTime;
          totalDays += finalDays;
          // Return your mapped object
          return {
            finalDays,
            name,
            averageBillableHoursPerDay,
            averageBilledHoursPerDay,
            personBillableTime,
            personWriteoffHours,
            personNonBillableTime,
          };
        }
        return null; // For filtering out later
      })
      .filter((item) => item !== null) // Remove any nulls
      .sort(
        (a, b) => b.averageBillableHoursPerDay - a.averageBillableHoursPerDay
      );

    // Now set the sorted data
    setSortedData(data);

    // Additionally, update state for your aggregated values so they can be used outside
    setTotalTeamTime(totalTeamTime);
    setTotalTeamBillableTime(totalTeamBillableTime);
    setTotalNonBillableTime(totalNonBillableTime);
    setTotalDays(totalDays); // You'll need to manage state for these

    // console.log("sorted data", data, totalTeamTime);

    // If you need to return these from the function (e.g., if not using state), you could also do that:
    return {
      sortedData: data,
      totalTeamTime,
      totalTeamBillableTime,
      totalNonBillableTime,
      totalDays,
    };
  };

  const generateDailyPR = () => {
    const doc = new jsPDF({
      orientation: "landscape", // Set the orientation to landscape
    });

    const tableColumns = [
      "Resource",
      "Period / Day",
      "Working days",
      "Available Billable Hours",
      "Billable Hours Utilized",
      "Average Billable Hours/Day",
      "Available Non-Billable Hours",
      "Non-Billable Hours Utilized",
    ];

    const tableData = [];

    let totalBillableTime = 0;
    let totalNonBilledTime = 0;

    sortedData.forEach((rowData, index) => {
      const {
        finalDays,
        name,
        averageBillableHoursPerDay,
        personBillableTime,
        personWriteoffHours,
        personNonBillableTime,
      } = rowData;

      const employeeDataArray1 = [
        name,
        `${formattedStartDate}` +
          (startSelectedDate !== endSelectedDate
            ? `- ${formattedEndDate}`
            : ""),
        finalDays,
        7 * finalDays,
        parseFloat(personBillableTime + personWriteoffHours).toFixed(2),
        averageBillableHoursPerDay,
        2 * finalDays,
        parseFloat(personNonBillableTime).toFixed(2),
      ];

      tableData.push(employeeDataArray1);

      totalBillableTime +=
        parseFloat(personBillableTime) + parseFloat(personWriteoffHours);

      totalNonBilledTime += parseFloat(personNonBillableTime);
    });

    const footerRow1 = [
      "Total",
      "",
      "",
      commonNames.length * 7 * finalDays,
      totalBillableTime.toFixed(2),
      "",
      commonNames.length * 2 * finalDays,
      totalNonBilledTime.toFixed(2),
    ];

    tableData.push(footerRow1);

    //First Page
    doc.setFontSize(25);
    doc.setFont("helvetica", "bold");
    doc.text(
      "Time Spent Report",
      (297 - doc.getTextWidth("Time Spent Report")) / 2,
      20
    );

    doc.setFont("helvetica", "underline");
    doc.setFontSize(15);
    doc.text(
      `${formattedStartDate}` +
        (startSelectedDate !== endSelectedDate ? ` - ${formattedEndDate}` : ""),
      (297 -
        doc.getTextWidth(
          `${formattedStartDate}` +
            (startSelectedDate !== endSelectedDate
              ? ` - ${formattedEndDate}`
              : "")
        )) /
        2,
      30
    );

    doc.autoTable(tableColumns, tableData, {
      startY: 40,
      theme: "grid",
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.1,
      didParseCell: function (data) {
        if (data.row.index === 0 && data.row.section === "head") {
          data.cell.styles.fillColor = [136, 106, 71];
          data.cell.styles.textColor = [255, 255, 255];
        }
        if (
          data.row.index === data.table.body.length - 1 &&
          data.row.section === "body"
        ) {
          data.cell.styles.fillColor = [97, 68, 58];
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.fontStyle = "bold";
        }

        if (data.row.section === "body") {
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
          data.cell.styles.halign = "right";
          data.cell.styles.cellWidth = 30;
        }

        if (data.column.index === 0) {
          data.cell.styles.halign = "left";
          data.cell.styles.cellWidth = 38.8;
        }
        if (data.column.index === 1) {
          data.cell.styles.halign = "left";
          data.cell.styles.cellWidth = 50;
        }
      },
    });

    doc.save("Resource_Performance_Daily_Report.pdf");
  };

  const generateWeeklyPR = () => {
    const doc = new jsPDF({
      orientation: "landscape", // Set the orientation to landscape
    });

    const tableColumns = [
      "Resource",
      "No. of days worked",
      "Avail Billable Hours",
      "Billable Hours Utilized",
      "Actual Billed",
      "Discounting",
      "Avg Billable hrs/day",
      "Avg Billed hrs/day",
      "Avail Non-Billable Hours",
      "Non-Billable Hours Utilized",
      "Missed Billing Potential(7 hrs/day)",
    ];
    const tableData = [];

    let totalBillableHours = 0;
    let totalBilledTime = 0;
    let totalNonBilledTime = 0;
    const finalData = [];

    sortedData.forEach((rowDataWeek, index) => {
      const {
        finalDays,
        name,
        personBillableTime,
        personWriteoffHours,
        personNonBillableTime,
      } = rowDataWeek;

      const tableDataArray = [
        name,
        finalDays,
        7 * finalDays,
        parseFloat(personBillableTime + personWriteoffHours).toFixed(2),
        parseFloat(personBillableTime).toFixed(2),
        ((
          (1 -
            personBillableTime / (personBillableTime + personWriteoffHours)) *
          100
        ).toFixed(2) !== "NaN"
          ? (
              (1 -
                personBillableTime /
                  (personBillableTime + personWriteoffHours)) *
              100
            ).toFixed(2)
          : 0.0) + "%",
        parseFloat(
          (personBillableTime + personWriteoffHours) / finalDays
        ).toFixed(2),
        parseFloat(personBillableTime / finalDays).toFixed(2),
        2 * finalDays,
        personNonBillableTime.toFixed(2),
        parseFloat((1 - personBillableTime / (7 * finalDays)) * 100).toFixed(
          2
        ) + "%",
      ];

      totalBillableHours += parseFloat(
        personBillableTime + personWriteoffHours
      );

      totalBilledTime += parseFloat(personBillableTime);
      totalNonBilledTime += parseFloat(personNonBillableTime.toFixed(2));

      const rowData = {
        data: tableDataArray,
        value: parseFloat(
          (personBillableTime + personWriteoffHours) / finalDays
        ).toFixed(2),
      };

      finalData.push(rowData);
    });

    finalData.sort((a, b) => b.value - a.value);

    finalData.forEach((rowData) => {
      tableData.push(rowData.data);
    });

    const footerRow = [
      "Total Hours",
      totalDays,
      7 * totalDays,
      totalTeamTime
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      totalTeamBillableTime
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      parseFloat((1 - totalBilledTime / totalBillableHours) * 100).toFixed(2) +
        "%",
      parseFloat(totalTeamAverageBillableHourPerDay)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      parseFloat(totalTeamAverageBilledHourPerDay)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      2 * totalDays,
      totalNonBillableTime
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      "",
    ];

    tableData.push(footerRow);

    //First Page
    doc.setFontSize(25);
    doc.setFont("helvetica", "bold");
    doc.text(
      "Time Spent Report",
      (297 - doc.getTextWidth("Time Spent Report")) / 2,
      20
    );
    doc.setFont("helvetica", "underline");
    doc.setFontSize(15);
    doc.text(
      `${formattedStartDate}` +
        (startSelectedDate !== endSelectedDate ? ` - ${formattedEndDate}` : ""),
      (297 -
        doc.getTextWidth(
          `${formattedStartDate}` +
            (startSelectedDate !== endSelectedDate
              ? ` - ${formattedEndDate}`
              : "")
        )) /
        2,
      30
    );

    doc.autoTable(tableColumns, tableData, {
      startY: 40,
      theme: "grid", // Set the theme to 'grid' to display internal lines
      tableLineColor: [0, 0, 0], // Color of the table lines (black in this example)
      tableLineWidth: 0.1, // Width of the table lines in units (default is 0.1)
      didParseCell: function (data) {
        if (data.row.index === 0 && data.row.section === "head") {
          data.cell.styles.fillColor = [136, 106, 71];
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
        }
        if (
          data.row.index === data.table.body.length - 1 &&
          data.row.section === "body"
        ) {
          data.cell.styles.fillColor = [97, 68, 58];
          data.cell.styles.textColor = [255, 255, 255];
        }
        if (data.row.section === "body") {
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
          data.cell.styles.halign = "right";
          data.cell.styles.cellWidth = 23.5; // Set the width to 50 units
        }
        if (data.column.index === 0) {
          data.cell.styles.halign = "left";
          data.cell.styles.cellWidth = 33.7; // Set the width to 50 units
        }
      },
    });

    doc.save("Resource_Performance_Report.pdf");
  };

  // const generateResourceWiseAmountReport = () => {
  //   const doc = new jsPDF();

  //   const tableColumns = [
  //     // "Period / Day",
  //     "Resource Name",
  //     "Matter Names",
  //     "Billed Hours",
  //     "Total Amount",
  //     "Total Amount (All Matters)",
  //   ];

  //   const tableData = [];

  //   const sortedData = commonNames.map((name, index) => {
  //     const personWorkHours =
  //       filteredWorkHour &&
  //       filteredWorkHour.filter(
  //         (item) =>
  //           item.user?.fullName === name &&
  //           item.workDate >= startSelectedDate &&
  //           item.workDate <= endSelectedDate
  //       );

  //     const billableEntries = personWorkHours.filter(
  //       (item) => item.billable === true
  //     );

  //     const matterNames = [
  //       ...new Set(
  //         billableEntries
  //           .filter((entry) => entry.matter?.name)
  //           .map((entry) => entry.matter.name)
  //       ),
  //     ];

  //     // console.log("MatterNames", name, matterNames);

  //     const matterTimeAndAmounts = matterNames.map((matterName) => {
  //       const filteredEntries = billableEntries.filter(
  //         (entry) => entry.matter?.name === matterName
  //       );

  //       const totalTime = filteredEntries.reduce(
  //         (total, entry) => total + parseFloat(entry.workHour),
  //         0
  //       );

  //       const totalAmount = filteredEntries.reduce((total, entry) => {
  //         let price;

  //         if (entry.matter.pricePerHour) {
  //           price = parseFloat(entry.matter.pricePerHour.$numberDecimal);
  //         } else if (entry.matter.resourceSpecificPrice) {
  //           const resourcePriceEntry = entry.matter.resourceSpecificPrice.find(
  //             (data) => {
  //               const [resourceName, resourcePrice] = data
  //                 .split(":")
  //                 .map((item) => item.trim());
  //               return resourceName === name;
  //             }
  //           );
  //           if (resourcePriceEntry) {
  //             const [, resourcePrice] = resourcePriceEntry
  //               .split(":")
  //               .map((item) => item.trim());
  //             price = parseFloat(resourcePrice);
  //           }
  //         }

  //         if (typeof price === "number") {
  //           total +=
  //             price *
  //             entry.workHour *
  //             (entry.matter.currency === "USD" ? 80 : 1);
  //         }

  //         return total;
  //       }, 0);

  //       return { totalAmount, totalTime, matterName }; // Return an object containing both values
  //     });

  //     // console.log(
  //     //   startSelectedDate,
  //     //   endSelectedDate,
  //     //   name,
  //     //   matterNames,
  //     //   matterTimeAndAmounts
  //     // );

  //     return {
  //       startSelectedDate,
  //       endSelectedDate,
  //       name,
  //       matterNames,
  //       matterTimeAndAmounts,
  //     };
  //   });

  //   // Sort the data based on the total amount of all matters
  //   sortedData.sort((a, b) => {
  //     const totalAmountA = a.matterTimeAndAmounts.reduce(
  //       (total, entry) => total + entry.totalAmount,
  //       0
  //     );
  //     const totalAmountB = b.matterTimeAndAmounts.reduce(
  //       (total, entry) => total + entry.totalAmount,
  //       0
  //     );
  //     return totalAmountB - totalAmountA;
  //   });

  //   sortedData.forEach((data) => {
  //     let isFirstRowForEmployee = true;
  //     let totalAmountForEmployee = 0;

  //     data.matterTimeAndAmounts.forEach((entry) => {
  //       if (isFirstRowForEmployee) {
  //         tableData.push([data.name, "", "", "", ""]);
  //         isFirstRowForEmployee = false;
  //       }

  //       tableData.push([
  //         "",
  //         entry.matterName,
  //         entry.totalTime.toFixed(2),
  //         entry.totalAmount
  //           .toFixed(2)
  //           .toString()
  //           .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
  //         "",
  //       ]);

  //       totalAmountForEmployee += entry.totalAmount;
  //     });

  //     // Add summary row for the employee's total amount
  //     tableData.push([
  //       {
  //         content: "Total Amount for " + data.name,
  //         styles: { fillColor: [207, 207, 207] },
  //         colSpan: 4,
  //       },
  //       {
  //         content: totalAmountForEmployee
  //           .toFixed(2)
  //           .toString()
  //           .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
  //         styles: { fillColor: [207, 207, 207] },
  //       },
  //     ]);
  //   });

  //   // Calculate the total amount across all employees
  //   const totalAmountAllEmployees = sortedData.reduce((total, data) => {
  //     data.matterTimeAndAmounts.forEach((entry) => {
  //       total += entry.totalAmount;
  //     });
  //     return total;
  //   }, 0);

  //   // Add summary row for the total amount across all employees
  //   tableData.push([
  //     {
  //       content: "Total Amount (All Employees)",
  //       styles: { fillColor: [207, 207, 207] },
  //       colSpan: 4,
  //     },
  //     {
  //       content: totalAmountAllEmployees
  //         .toFixed(2)
  //         .toString()
  //         .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
  //       styles: { fillColor: [207, 207, 207] },
  //     },
  //   ]);

  //   //First Page
  //   doc.setFontSize(25);
  //   doc.setFont("helvetica", "bold");
  //   doc.text("Resource Performance Report", 40, 20);

  //   doc.setFont("helvetica", "underline");
  //   doc.setFontSize(15);
  //   doc.text(
  //     `${formattedStartDate}` +
  //       (startSelectedDate !== endSelectedDate ? ` - ${formattedEndDate}` : ""),
  //     (210 -
  //       doc.getTextWidth(
  //         `${formattedStartDate}` +
  //           (startSelectedDate !== endSelectedDate
  //             ? ` - ${formattedEndDate}`
  //             : "")
  //       )) /
  //       2,
  //     30
  //   );

  //   doc.autoTable(tableColumns, tableData, {
  //     startY: 40,
  //     theme: "grid", // Set the theme to 'grid' to display internal lines
  //     tableLineColor: [0, 0, 0], // Color of the table lines (black in this example)
  //     tableLineWidth: 0.1, // Width of the table lines in units (default is 0.1)
  //     didParseCell: function (data) {
  //       if (data.row.index === 0 && data.row.section === "head") {
  //         data.cell.styles.fillColor = [136, 106, 71];
  //         data.cell.styles.textColor = [255, 255, 255];
  //       }
  //       if (
  //         data.row.index === data.table.body.length - 1 &&
  //         data.row.section === "body"
  //       ) {
  //         data.cell.styles.fillColor = [97, 68, 58];
  //         data.cell.styles.textColor = [255, 255, 255];
  //         data.cell.styles.fontStyle = "bold";
  //       }
  //     },
  //   });

  //   doc.save("Resource_Performance_Report.pdf");
  // };

  async function filterBetweenDates() {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `${API}/workHour/filterWorkHourByUserId`,
        {
          params: {
            user: selectedLawyer,
            fromDate: startSelectedDate,
            toDate: endSelectedDate,
            matterId,
            adminId,
            needFin,
            sendMail,
            limit: 0,
            page: 0,
          },
        }
      );

      if (response.data) {
        setFilteredWorkHour(response.data.workHours);
      }

      const { data } = await axiosInstance.get(
        `${API}/writeoffs/getWriteoffHoursByAdmin`,
        {
          params: {
            adminId,
            user: selectedLawyer,
            fromDate: startSelectedDate,
            toDate: endSelectedDate,
            matterId,
            limit: 0,
            page: 0,
          },
        }
      );

      setWriteOffHours(data.workHours);

      fetchAllLeaves();
      setLoading(false);
      filterAndSortData();
    } catch (error) {
      // Handle the error here
      console.error("Error occurred:", error);
      // Optionally, you can set an error state or display an error message to the user
    }
  }

  const fetchAllLeaves = async () => {
    try {
      const response = await axiosInstance.get(`${API}/leaves`, {
        params: {
          adminId: auth.user._id,
          userId: selectedLawyer,
          startSelectedDate,
          endSelectedDate,
          limit: 0,
          page,
        },
      });

      setLeaves(
        response.data.leavesWithDays.filter(
          (leave) => leave.leaveType === "leave"
        )
      );
      // console.log("Leaves data in performance report------", leaves);
    } catch (error) {
      console.error("Error fetching leaves:", error);
    }
  };

  const columns = [
    "Resource",
    "Days worked",
    "Available Clocked Hours",
    "Clocked Hours",
    "Billed Hours",
    "W/O",
    "Average Clocked hrs/day",
    // "Average Billed hrs/day",
    // "Available Non-Billable",
    // "Non-Billable Hours Utilized",
    // "Missed Billing Potential (7 hrs/day)",
  ];

  const columns2 = [
    "Client",
    "Matter",
    "Clocked Hours",
    "Clocked Amount (USD)",
    "Billed Amount (USD)",
    // "Discount",
    "Discount %",
    "Contribution To Total %",
    // "ERPH",
  ];

  // states and functions for date range picker
  const [selectedDateRange, setSelectedDateRange] = useState({
    start: "",
    end: "",
  });

  // Function to receive selected dates from DateRangePickerTst
  const handleDateRangeChange = (startDate, endDate) => {
    setSelectedDateRange({ start: startDate, end: endDate });
    setStartSelectedDate(startDate);
    setEndSelectedDate(endDate);
    console.log(startDate, endDate);
  };

  return (
    <>
      {/* Main Heading */}
      <Paper elevation={0} className="rounded-full">
        <Grid container spacing={1} alignItems="center" marginBottom="1rem">
          <Grid item xs={12} sm={7} md={4}>
            <Typography variant="h5">
              {showResourceData ? "Resources " : "Matters "} Performance Report
            </Typography>
          </Grid>

          {/* Matters */}
          <Grid item xs={12} sm={7} md={3}>
            <Select
              options={filteredMatters}
              value={filteredMatters.find(
                (option) => option.label === `${selectedMatterName} | ${client}`
              )}
              onChange={(e) => {
                setMatterId(e.value);
                setSelectedMatterName(e.label.split("|")[0]);
                setClient(e.label.split("|")[1]);
              }}
              isSearchable
              placeholder="Select Matter"
              styles={customStyles}
            />
          </Grid>

          {/* Resources */}
          <Grid item xs={12} sm={5} md={1.5}>
            <Select
              options={filteredEmployees}
              value={filteredEmployees.find(
                (resOption) => resOption.label === `${selectedLawyer}`
              )}
              onChange={(e) => {
                setSelectedLawyer(e.value);
              }}
              isSearchable
              placeholder="Resource"
              styles={customStyles}
            />
          </Grid>

          {/* Date Range Picker */}
          <Grid
            item
            xs={2}
            sm={2}
            md={2}
            display="flex"
            alignItems="center"
            gap={1}
          >
            <CustomTooltip text={"Select Dates"}>
              <CustomDateRangePicker
                startDate={selectedDateRange.start}
                endDate={selectedDateRange.end}
                onDateRangeChange={handleDateRangeChange}
                iconSize={30}
              />
            </CustomTooltip>

            <Typography>
              {formatMonthDate(startSelectedDate)}
              {startSelectedDate !== endSelectedDate &&
                ` - ${formatMonthDate(endSelectedDate)}`}
            </Typography>
          </Grid>

          {/* Search Icon Button */}
          <Grid item xs={4} sm={4} md={0.4}>
            <CustomTooltip text="Search workhours">
              <IconButton
                onClick={handleSearchClick}
                className="text-black hover:bg-gray-200"
                style={{
                  padding: 5, // Adjust padding as needed
                  borderRadius: "50%", // Full rounded for circular button
                }}
              >
                <Search style={{ fontSize: "20px" }} /> {/* Custom icon size */}
              </IconButton>
            </CustomTooltip>
            {/* Search Menu */}
            <Menu
              anchorEl={anchorElSearch}
              open={Boolean(anchorElSearch)}
              onClose={() => handleSearchClose(setAnchorElSearch)}
            >
              <MenuItem
                onClick={() => {
                  filterBetweenDates();
                  setShowMatterData(false);
                  setShowResourceData(true);
                  handleSearchClose(setAnchorElSearch);
                }}
              >
                Resourcewise
              </MenuItem>
              <MenuItem
                onClick={() => {
                  filterBetweenDates();
                  setShowMatterData(true);
                  setShowResourceData(false);
                  handleSearchClose(setAnchorElSearch);
                }}
              >
                Matterwise
              </MenuItem>
            </Menu>
          </Grid>

          {/* Send mail  */}
          <Grid item xs={12} sm={7} md={0.4}>
            <CustomTooltip text="Send Daily Performance Report Mail">
              <IconButton
                onClick={() => {
                  // handleClose(setAnchorElSendMail);
                  if (startSelectedDate !== "" && endSelectedDate !== "") {
                    dispatch(
                      filterTimeByUserIdForAdmin(
                        selectedLawyer,
                        startSelectedDate,
                        endSelectedDate,
                        matterId,
                        needFin,
                        (sendMail = true),
                        adminId,
                        limit,
                        page
                      )
                    );

                    fetchAllLeaves();
                  } else {
                    alert("Select the dates!");
                  }
                }}
                className="text-black hover:bg-gray-200"
                style={{
                  padding: 5, // Adjust padding as needed
                  borderRadius: "50%", // Full rounded for circular button
                }}
              >
                <Mail style={{ fontSize: "20px" }} />
              </IconButton>
            </CustomTooltip>
          </Grid>

          {/* Download Icon Button */}
          <Grid item xs={4} sm={4} md={0.4}>
            <CustomTooltip text="Download Report">
              <IconButton
                onClick={handleDownloadClick}
                className="text-black hover:bg-gray-200"
                style={{
                  padding: 5,
                  borderRadius: "50%",
                }}
              >
                <Download style={{ fontSize: "20px" }} />
              </IconButton>
            </CustomTooltip>
            {/* Download Menu */}
            <Menu
              anchorEl={anchorElDownload}
              open={Boolean(anchorElDownload)}
              onClose={handleDownloadClose}
            >
              <MenuItem onClick={generateDailyPR}>Daily</MenuItem>
              <MenuItem onClick={generateWeeklyPR}>Weekly/Monthly</MenuItem>
              <MenuItem onClick={generateMatterWisePR}>Matterwise</MenuItem>
              {/* <MenuItem onClick={generateResourceWiseAmountReport}>
                Compensation
              </MenuItem> */}
            </Menu>
          </Grid>
        </Grid>
      </Paper>

      {/* Main Table  */}
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: "75vh" }}>
          <Table aria-label="reusable table">
            <TableHead>
              {showResourceData ? (
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell key={index} id="table-header">
                      {column}
                    </TableCell>
                  ))}
                </TableRow>
              ) : (
                <TableRow>
                  {columns2.map((column, index) => (
                    <TableCell key={index} id="table-header">
                      {column}
                    </TableCell>
                  ))}
                </TableRow>
              )}
            </TableHead>

            {loading ? (
              <>
                <TableSkeletonLoader rows={6} colSpan={12} />
              </>
            ) : filteredWorkHour.length === 0 ? (
              <TableRow>
                <TableCell colSpan={13}>
                  <NoDataFound message="Oops! No workhours found." />
                </TableCell>
              </TableRow>
            ) : showResourceData ? (
              sortedData.map((rowData, index) => {
                const {
                  finalDays,
                  name,
                  averageBillableHoursPerDay,
                  averageBilledHoursPerDay,
                  personBillableTime,
                  personWriteoffHours,
                  personNonBillableTime,
                } = rowData;

                return (
                  <TableRow key={index} role="checkbox" tabIndex={-1}>
                    <TableCell>{name}</TableCell>
                    <TableCell>{finalDays ? finalDays : 0}</TableCell>
                    <TableCell>{7 * finalDays}</TableCell>
                    <TableCell>
                      {parseFloat(
                        personBillableTime + personWriteoffHours
                      ).toFixed(2)}
                    </TableCell>
                    <TableCell>
                      {parseFloat(personBillableTime).toFixed(2)}
                    </TableCell>
                    <TableCell>
                      {" "}
                      {((
                        (1 -
                          personBillableTime /
                            parseFloat(
                              personBillableTime + personWriteoffHours
                            )) *
                        100
                      ).toFixed(2) !== "NaN"
                        ? (
                            (1 -
                              personBillableTime /
                                parseFloat(
                                  personBillableTime + personWriteoffHours
                                )) *
                            100
                          ).toFixed(2)
                        : "0.0") + "%"}
                    </TableCell>
                    <TableCell>{averageBillableHoursPerDay}</TableCell>
                    {/* <TableCell>{averageBilledHoursPerDay}</TableCell>
                    <TableCell>{2 * finalDays}</TableCell>
                    <TableCell>{personNonBillableTime.toFixed(2)}</TableCell>
                    <TableCell>
                      {parseFloat(
                        (1 - personBillableTime / (7 * finalDays)) * 100
                      ).toFixed(2) !== "-Infinity"
                        ? parseFloat(
                            (1 - personBillableTime / (7 * finalDays)) * 100
                          ).toFixed(2)
                        : "0.0"}
                      %
                    </TableCell> */}
                  </TableRow>
                );
              })
            ) : (
              matterwiseSortedData.map((rowData, index) => {
                const {
                  matter,
                  teamTotalBillableAmount,
                  teamTotalBilledAmount,
                  teamTotalHours,
                } = rowData;

                return (
                  <>
                    <TableRow key={index} role="checkbox" tabIndex={-1}>
                      <TableCell>{matter.client.name}</TableCell>
                      <TableCell>{matter?.name}</TableCell>
                      <TableCell>{teamTotalHours.toFixed(2)}</TableCell>
                      <TableCell>
                        {teamTotalBillableAmount
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </TableCell>
                      <TableCell>
                        {teamTotalBilledAmount
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </TableCell>
                      {/* <TableCell>
                        {(teamTotalBillableAmount - teamTotalBilledAmount)
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </TableCell> */}
                      <TableCell>
                        {((
                          ((teamTotalBillableAmount - teamTotalBilledAmount) /
                            teamTotalBillableAmount) *
                          100
                        ).toFixed(2) !== "NaN"
                          ? (
                              ((teamTotalBillableAmount -
                                teamTotalBilledAmount) /
                                teamTotalBillableAmount) *
                              100
                            ).toFixed(2)
                          : (0).toFixed(2)) + "%"}
                      </TableCell>
                      <TableCell>
                        {((
                          (teamTotalBilledAmount * 100) /
                          allMatterTotalBilledAmount
                        ).toFixed(2) !== "NaN"
                          ? (
                              (teamTotalBilledAmount * 100) /
                              allMatterTotalBilledAmount
                            ).toFixed(2)
                          : (0).toFixed(2)) + "%"}
                      </TableCell>
                      {/* <TableCell>
                        {(teamTotalBilledAmount / teamTotalHours).toFixed(2) !==
                        "NaN"
                          ? (teamTotalBilledAmount / teamTotalHours).toFixed(2)
                          : "0.00"}
                      </TableCell> */}
                    </TableRow>
                  </>
                );
              })
            )}

            {showMatterData && (
              <>
                <TableRow
                  sx={{
                    backgroundColor: COLORS.primary,
                    color: "white",
                  }}
                >
                  <TableCell colSpan={2}>
                    <b>Total Amount (in Dollars)</b>
                  </TableCell>
                  <TableCell>
                    <b>{totalHoursClocked.toFixed(2)}</b>
                  </TableCell>
                  <TableCell>
                    <b>
                      {allMatterTotalBillableAmount
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </b>
                  </TableCell>
                  <TableCell>
                    <b>
                      {allMatterTotalBilledAmount
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </b>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    backgroundColor: COLORS.primary,
                    color: "white",
                  }}
                >
                  <TableCell colSpan={2}>
                    <b>Total Amount (in Rupees)</b>
                  </TableCell>
                  <TableCell>
                    <b>
                      {(allMatterTotalBillableAmount * 80)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </b>
                  </TableCell>
                  <TableCell>
                    <b>
                      {(allMatterTotalBilledAmount * 80)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </b>{" "}
                  </TableCell>
                  <TableCell colSpan={5}></TableCell>
                </TableRow>
              </>
            )}

            {showResourceData && totalDays !== 0 && (
              <>
                <TableRow
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                  }}
                >
                  <TableCell colSpan={1}>
                    <b>Total Team Performance</b>
                  </TableCell>
                  <TableCell>
                    <b>{totalDays}</b>
                  </TableCell>
                  <TableCell>
                    <b>{7 * totalDays}</b>
                  </TableCell>
                  <TableCell>
                    <b>
                      {totalTeamTime
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </b>
                  </TableCell>
                  <TableCell>
                    <b>
                      {totalTeamBillableTime
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </b>
                  </TableCell>
                  <TableCell>
                    {parseFloat(
                      (1 - totalTeamBillableTime / totalTeamTime) * 100
                    ).toFixed(2)}{" "}
                    %
                  </TableCell>
                  <TableCell>
                    <b>
                      {parseFloat(totalTeamAverageBillableHourPerDay)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </b>
                  </TableCell>
                  {/* <TableCell>
                    <b>
                      {parseFloat(totalTeamAverageBilledHourPerDay)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </b>
                  </TableCell>
                  <TableCell>
                    <b>{2 * totalDays}</b>
                  </TableCell>
                  <TableCell>
                    <b>
                      {totalNonBillableTime
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </b>
                  </TableCell> */}
                </TableRow>
              </>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default PerformanceReport;
