import React, { useEffect, useState } from "react";
import formatDate, {
  ConfirmationAlert,
  errorAlert,
  formatMonthDate,
  successAlert,
  API,
  customStyles,
} from "../../utils/services";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";
import Select from "react-select";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Search, TableChartSharp, TableView } from "@mui/icons-material";
import axiosInstance from "../../helpers/axios";
import CustomTooltip from "../../components/ui/CustomTootltip";
import CustomButton from "../../components/ui/CustomButton";
import { useEmployeeOptions } from "../../helpers/hooks/useEmployeesOptions";
import NoDataFound from "../../components/NoDataFound";
import TableSkeletonLoader from "../../components/ui/TableSkeletonLoader";
import CustomDateRangePicker from "../../components/ui/CustomDateRangePicker";
import CustomModal from "../../components/ui/CustomModal";

const Leave = () => {
  const auth = useSelector((state) => state.auth);
  const { employees, allActiveEmployeesData } = useEmployeeOptions();

  const [leaves, setLeaves] = useState([]);
  const [selectedLawyer, setSelectedLawyer] = useState("");
  const [startSelectedDate, setStartSelectedDate] = useState(() => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    return formatDate(firstDayOfMonth);
  });

  const [endSelectedDate, setEndSelectedDate] = useState(
    formatDate(new Date())
  );

  //modal states
  const [showModal, setShowModal] = useState(false);
  const [editingLeave, setEditingLeave] = useState(null);
  const [modalStartDate, setModalStartDate] = useState(formatDate(new Date()));
  const [modalEndDate, setModalEndDate] = useState(formatDate(new Date()));
  const [modalLeaveDescription, setModalLeaveDescription] = useState("");
  const [userID, setUserID] = useState("");
  const [leaveStatus, setLeaveStatus] = useState("approved");
  const [paidLeave, setPaidLeave] = useState("yes");
  const [leaveType, setLeaveType] = useState("leave");
  const [remarks, setRemarks] = useState("");
  const [showTotalTable, setShowTotalTable] = useState(true);

  //pagination states
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  // const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchAllLeaves();
  }, [page, rowsPerPage, selectedLawyer, startSelectedDate, endSelectedDate]);

  // Function to filter employeesData based on selected resource
  const getFilteredData = () => {
    if (!selectedLawyer) return allActiveEmployeesData;
    return allActiveEmployeesData.filter(
      (employee) => employee._id === selectedLawyer
    );
  };

  // console.log("employees",employees, "employeesData",allActiveEmployeesData)

  const filteredData = getFilteredData();

  //Modal for add and update leave
  const openModal = (leave) => {
    if (leave) {
      setEditingLeave(leave);
      setUserID(leave.user._id);
      setModalStartDate(leave.leaveStartDate);
      setModalEndDate(leave.leaveEndDate);
      setModalLeaveDescription(leave.leaveDescription);
      setLeaveStatus(leave.leaveStatus);
      setPaidLeave(leave.paidLeave);
      setLeaveType(leave.leaveType);
      // console.log("leave data", leave, editingLeave);
    } else {
      setEditingLeave(null);
      setModalStartDate(formatDate(new Date()));
      setModalEndDate(formatDate(new Date()));
      setLeaveStatus("approved");
      setPaidLeave("yes");
      setLeaveType("leave");
      setModalLeaveDescription("");
      setUserID("");
      setRemarks("");
    }
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  //filter function Validation
  const isValidForm = () => {
    if (!(startSelectedDate <= endSelectedDate)) {
      errorAlert("Select Correct Search Dates!!");
      return false;
    } else {
      return true;
    }
  };

  //get leaves
  const fetchAllLeaves = async () => {
    if (isValidForm()) {
      try {
        setLoading(true);
        const response = await axiosInstance.get(`${API}/leaves`, {
          params: {
            adminId: auth.user._id,
            userId: selectedLawyer,
            startSelectedDate: startSelectedDate,
            endSelectedDate: endSelectedDate,
            limit: rowsPerPage,
            page,
          },
        });

        if (response.data) {
          setLeaves(response.data.leavesWithDays);
          // setTotalCount(response.data.totalLeavesCount);
        }
        setLoading(false);
        console.log("Leaves data------", response.data);
      } catch (error) {
        // console.error("Error fetching leaves:", error);
        setLoading(false);
        errorAlert(
          error.response.data.message || "Error Fetching Leaves!!, Try again..."
        );
      }
    }
  };

  //modal form validation
  const modalIsValidForm = () => {
    if (modalStartDate === "" || modalEndDate === "" || userID === "") {
      errorAlert("Start Date, End Date and Resource are required!!");
      return false;
    } else if (!(modalStartDate <= modalEndDate)) {
      errorAlert("Select Correct Dates!!");
      return false;
    } else {
      return true;
    }
  };

  //add and update leave
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (modalIsValidForm()) {
      const formData = {
        admin: auth.user._id,
        user: userID,
        leaveStartDate: modalStartDate,
        leaveEndDate: modalEndDate,
        leaveDescription: modalLeaveDescription,
        leaveStatus: leaveStatus,
        paidLeave: paidLeave,
        leaveType: leaveType,
        remarks,
      };

      // console.log("Leave data", formData);

      try {
        if (editingLeave) {
          // Update existing leave
          const response = await axiosInstance.put(
            `${API}/leaves/${editingLeave._id}`,
            formData
          );
          if (response.data) {
            successAlert(response.data.message);
            fetchAllLeaves();
            closeModal();
          }
        } else {
          // Add new leave
          const response = await axiosInstance.post(`${API}/leaves`, formData);
          if (response.data) {
            successAlert(response.data.message);
            fetchAllLeaves();
            closeModal();
          }
        }
      } catch (error) {
        console.error("Error:", error);
        errorAlert(
          error.response.data.message ||
            (editingLeave
              ? "Error updating leave!! Try again..."
              : "Error creating leave!! Try again...")
        );
      }
      closeModal();
    }
  };

  //delete
  const handleDeleteLeave = (id) => {
    // console.log(id);

    toast(
      <ConfirmationAlert
        onDeleteOff={() => onDeleteLeave(id)}
        question={"Are you sure to delete this leave?"}
        answer={"Yes, Delete it"}
        icon={<RiDeleteBin6Line />}
      />,
      {
        position: toast.POSITION.TOP_CENTER,
        closeButton: true,
        autoClose: false,
        draggable: false,
      }
    );
  };

  const onDeleteLeave = async (leaveId) => {
    await axiosInstance
      .delete(`${API}/leaves/${leaveId}`)
      .then((response) => {
        if (response.data) {
          successAlert(response.data.message);
          fetchAllLeaves();
        }
      })
      .catch((error) => {
        errorAlert(
          error.response.data.message || "Error Deleting Leave!!, Try again..."
        );
      });
  };

  // states and functions for date range picker
  const [selectedDateRange, setSelectedDateRange] = useState({
    start: "",
    end: "",
  });

  // Function to receive selected dates from DateRangePickerTst
  const handleDateRangeChange = (startDate, endDate) => {
    setSelectedDateRange({ start: startDate, end: endDate });
    setStartSelectedDate(startDate);
    setEndSelectedDate(endDate);
    console.log(startDate, endDate);
  };

  return (
    <div>
      <ToastContainer />

      {/* Search Bar  */}
      <Paper elevation={0} className="rounded-full">
        <Grid container spacing={1} alignItems="center" marginBottom="1rem">
          {/* Main Heading  */}
          <Grid item container spacing={1} xs={12} sm={7} md={3}>
            <Grid item>
              <Typography variant="h5">Leaves</Typography>
            </Grid>
            <Grid item>
              <CustomTooltip text="Add Leave">
                <CustomButton
                  style={{
                    borderRadius: "50%",
                    minWidth: "5px",
                    height: "30px",
                    transition:
                      "transform 0.3s ease-in-out, background-color 0.3s ease",
                    "&:hover": {
                      backgroundColor: "#886a47",
                      transform: "scale(1.1)",
                    },
                  }}
                  onClick={() => openModal(null)}
                >
                  <AddIcon />
                </CustomButton>
              </CustomTooltip>
            </Grid>
          </Grid>

          {/* Resource  */}
          <Grid item xs={12} sm={5} md={2}>
            <Select
              options={employees}
              value={employees.find(
                (resOption) => resOption.value === `${selectedLawyer}`
              )}
              onChange={(e) => {
                setSelectedLawyer(e.value);
              }}
              isSearchable
              placeholder="Select Resource"
              styles={customStyles}
            />
          </Grid>

          {/* Date Range Picker */}
          <Grid
            item
            xs={2}
            sm={2}
            md={2}
            display="flex"
            alignItems="center"
            gap={1}
          >
            <CustomTooltip text={"Select Dates"}>
              <CustomDateRangePicker
                startDate={selectedDateRange.start}
                endDate={selectedDateRange.end}
                onDateRangeChange={handleDateRangeChange}
                iconSize={30}
              />
            </CustomTooltip>

            <Typography>
              {formatMonthDate(startSelectedDate)} -{" "}
              {formatMonthDate(endSelectedDate)}
            </Typography>
          </Grid>

          {/* Search Icon Button */}
          <Grid item xs={4} sm={4} md={0.4}>
            <CustomTooltip text="Search leaves">
              <IconButton
                onClick={() => fetchAllLeaves()}
                className=" text-black hover:bg-gray-800"
                style={{
                  padding: 5,
                  borderRadius: "50%",
                }}
              >
                <Search style={{ fontSize: "20px" }} />
              </IconButton>
            </CustomTooltip>
          </Grid>

          <Grid item xs={4} sm={4} md={0.4}>
            <CustomTooltip text="Show Total Table">
              <IconButton
                onClick={() => {
                  setShowTotalTable(true);
                }}
                className=" text-black hover:bg-gray-800"
                style={{
                  padding: 5,
                  borderRadius: "50%",
                }}
              >
                <TableView style={{ fontSize: "20px" }} />{" "}
                {/* Custom icon size */}
              </IconButton>
            </CustomTooltip>
          </Grid>

          <Grid item xs={4} sm={4} md={0.4}>
            <CustomTooltip text="Show Entries Table">
              <IconButton
                onClick={() => {
                  setShowTotalTable(false);
                }}
                className=" text-black hover:bg-gray-800"
                style={{
                  padding: 5, // Adjust padding as needed
                  // color: "white",
                  // backgroundColor: "black",
                  borderRadius: "50%", // Full rounded for circular button
                }}
              >
                <TableChartSharp style={{ fontSize: "20px" }} />{" "}
              </IconButton>
            </CustomTooltip>
          </Grid>
        </Grid>
      </Paper>

      {/* Modal  */}
      {showModal && (
        <CustomModal
          showModal={showModal}
          closeModal={closeModal}
          title={editingLeave ? "Edit Leave" : "Add Leave"}
          onSubmit={handleSubmit}
        >
          <div className="flex gap-4 w-full">
            <div style={{ width: "50%" }}>
              <label>Start Date *</label>
              <input
                type="date"
                value={modalStartDate}
                onChange={(e) => setModalStartDate(e.target.value)}
              />
            </div>
            <div style={{ width: "50%" }}>
              <label>End Date *</label>
              <input
                type="date"
                value={modalEndDate}
                onChange={(e) => setModalEndDate(e.target.value)}
              />
            </div>
          </div>

          <div className="flex gap-4 w-full">
            <div style={{ width: "50%" }}>
              <Select
                options={employees}
                value={employees.find(
                  (resOption) => resOption.label === `${userID}`
                )}
                onChange={(e) => {
                  setUserID(e.value);
                }}
                isSearchable
                placeholder="Select Resource"
                styles={customStyles}
              />
            </div>

            <div style={{ width: "50%" }}>
              <select
                style={{ width: "100%" }}
                value={leaveType}
                name="leaveType"
                onChange={(event) => setLeaveType(event.target.value)}
              >
                <option value="">Select Leave Type</option>
                <option value="quarterDay">Quarter Day</option>
                <option value="halfDay">Half Day</option>
                <option value="leave">Leave</option>
                <option value="workFromHome">Work From Home</option>
              </select>
            </div>
          </div>

          <div className="flex gap-4 w-full">
            <div style={{ width: "50%" }}>
              <label>Select Status</label>
              <select
                value={leaveStatus}
                name="leaveStatus"
                onChange={(e) => setLeaveStatus(e.target.value)}
              >
                <option value="">Select Status</option>
                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Reject</option>
              </select>
            </div>

            <div style={{ width: "50%" }}>
              <label>Select Paid Leave</label>
              <select
                value={paidLeave}
                name="paidLeave"
                onChange={(e) => setPaidLeave(e.target.value)}
              >
                <option value="">Select One</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
          </div>

          <textarea
            placeholder="Leave description"
            value={modalLeaveDescription}
            onChange={(e) => setModalLeaveDescription(e.target.value)}
            style={{ width: "100%" }}
          />

          <textarea
            placeholder="Any remarks for HR"
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
            style={{ width: "100%", height: "40px" }}
          />
        </CustomModal>
      )}

      {/* Main Table  */}
      {showTotalTable && (
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: "75vh" }}>
            <Table aria-label="reusable table">
              <TableHead>
                {/* columns row  */}
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>Resource</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Assigned Leaves
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: "bold", textAlign: "center" }}
                  >
                    Used Leaves
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Available Leaves
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Assigned WFH
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: "bold", textAlign: "center" }}
                  >
                    Used WFH
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Available WFH
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <>
                    <TableSkeletonLoader rows={6} colSpan={11} />
                  </>
                ) : filteredData.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={11}>
                      <NoDataFound message="Oops! No Leaves Found" />
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredData.map((employee, index) => (
                    <TableRow
                      key={employee._id}
                      role="checkbox"
                      tabIndex={-1}
                      sx={{
                        backgroundColor: "white",
                      }}
                    >
                      <TableCell>{employee.fullName}</TableCell>
                      <TableCell>{employee.totalLeaves}</TableCell>
                      <TableCell>
                        {leaves
                          .filter(
                            (leave) =>
                              leave.user._id === employee._id &&
                              leave.leaveType !== "workFromHome"
                          )
                          .reduce(
                            (total, leave) =>
                              total + parseFloat(leave.leaveDays),
                            0
                          )}
                      </TableCell>
                      <TableCell>
                        {parseFloat(
                          employee.totalLeaves -
                            leaves
                              .filter(
                                (leave) =>
                                  leave.user._id === employee._id &&
                                  leave.leaveType !== "workFromHome"
                              )
                              .reduce(
                                (total, leave) =>
                                  total + parseFloat(leave.leaveDays),
                                0
                              )
                        )}
                      </TableCell>
                      <TableCell>{employee.totalWorkFromHome}</TableCell>
                      <TableCell>
                        {leaves
                          .filter(
                            (leave) =>
                              leave.user._id === employee._id &&
                              leave.leaveType === "workFromHome"
                          )
                          .reduce(
                            (total, leave) =>
                              total + parseFloat(leave.leaveDays),
                            0
                          )}
                      </TableCell>
                      <TableCell>
                        {parseFloat(
                          employee.totalWorkFromHome -
                            leaves
                              .filter(
                                (leave) =>
                                  leave.user._id === employee._id &&
                                  leave.leaveType === "workFromHome"
                              )
                              .reduce(
                                (total, leave) =>
                                  total + parseFloat(leave.leaveDays),
                                0
                              )
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}

      {/* Entries Table  */}
      {!showTotalTable && (
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: "61vh" }}>
            <Table aria-label="reusable table">
              <TableHead>
                {/* columns row  */}
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Start Date - End Date
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Resource</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Days</TableCell>
                  <TableCell
                    style={{ fontWeight: "bold", textAlign: "center" }}
                  >
                    Narration
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Leave Type
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Paid Leave
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: "bold", textAlign: "center" }}
                  >
                    Edit
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: "bold", textAlign: "center" }}
                  >
                    Delete
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <>
                    <TableSkeletonLoader rows={6} colSpan={11} />
                  </>
                ) : leaves.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={11}>
                      <NoDataFound message="Oops! No Leaves Found." />
                    </TableCell>
                  </TableRow>
                ) : (
                  leaves.map((leave, index) => (
                    <TableRow
                      key={leave._id}
                      role="checkbox"
                      tabIndex={-1}
                      sx={{
                        backgroundColor: "white",
                        fontFamily: "Nunito Sans, sans-serif !important",
                      }}
                    >
                      <TableCell>
                        {formatMonthDate(leave.leaveStartDate)} -{" "}
                        {formatMonthDate(leave.leaveEndDate)}
                      </TableCell>
                      <TableCell>{leave.user.fullName}</TableCell>
                      <TableCell>{leave.leaveDays}</TableCell>
                      <TableCell className="table-cell">
                        {leave.leaveDescription}
                      </TableCell>
                      <TableCell>
                        {leave.leaveType === "workFromHome"
                          ? "Work From Home"
                          : leave.leaveType === "quarterDay"
                          ? "Quarter Day"
                          : leave.leaveType === "halfDay"
                          ? "Half Day"
                          : "Leave"}
                      </TableCell>
                      <TableCell
                        style={{
                          color:
                            leave.leaveStatus === "approved"
                              ? "green"
                              : leave.leaveStatus === "pending"
                              ? "orange"
                              : "red",
                          fontWeight: "bold",
                          padding: "10px",
                        }}
                      >
                        {leave.leaveStatus === "pending"
                          ? "Pending"
                          : leave.leaveStatus === "approved"
                          ? "Approved"
                          : leave.leaveStatus === "rejected" && "Rejected"}
                      </TableCell>
                      <TableCell>{leave.paidLeave}</TableCell>
                      <TableCell className="table-cell">
                        <CiEdit
                          style={{ cursor: "pointer" }}
                          onClick={() => openModal(leave)}
                        />
                      </TableCell>
                      <TableCell className="table-cell">
                        <RiDeleteBin6Line
                          style={{ cursor: "pointer" }}
                          onClick={() => handleDeleteLeave(leave._id)}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                )}
                <TableRow>
                  <TableCell colSpan={2}>Total Leaves</TableCell>
                  <TableCell>
                    {leaves.reduce(
                      (total, curr) => total + parseFloat(curr.leaveDays),
                      0
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
    </div>
  );
};

export default Leave;
