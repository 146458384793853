// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo-hover-effect {
  transition: transform 0.3s ease-in-out;
  height: 50px;
}

.logo-hover-effect:hover {
  transform: scale(1.1);
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/SideDrawer.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;EACtC,YAAY;AACd;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".logo-hover-effect {\n  transition: transform 0.3s ease-in-out;\n  height: 50px;\n}\n\n.logo-hover-effect:hover {\n  transform: scale(1.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
