import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Dialog, DialogPanel, PopoverGroup } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Avatar, Typography } from "@mui/material";
import { Logout } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { authConstants } from "../../actions/constants";
import { billedDesignations } from "../../utils/services";
import { COLORS } from "../../constants/theme";
import CustomTooltip from "./CustomTootltip";

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const auth = useSelector((state) => state.auth);

  const onLogOut = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("user");
    localStorage.removeItem("selectedComponent");
    dispatch({
      type: authConstants.LOGOUT_SUCCESS,
    });
    navigate("/");
  };

  const isActive = (path) => location.pathname === path;

  // Get initials from the user's name
  const getUserInitials = (name) => {
    const nameParts = name?.split(" ") || [];
    const firstNameInitial = nameParts[0]?.charAt(0).toUpperCase() || "";
    const lastNameInitial = nameParts[1]?.charAt(0).toUpperCase() || "";
    return `${firstNameInitial}${lastNameInitial}`;
  };

  return (
    <header className="bg-white border-b border-slate-500">
      <nav
        aria-label="Global"
        className="flex w-full items-center justify-between p-6 lg:px-14"
      >
        <div className="flex lg:flex-1">
          <a href="/resourceDashboard" className="">
            <img
              src={`/assets/Comput_labs_black.png`}
              alt="logo"
              className="h-8 w-auto"
            />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>

        <PopoverGroup className="hidden lg:flex lg:gap-x-12">
          <Typography
            component="a"
            href="/resourceDashboard"
            variant={isActive("/resourceDashboard") ? "body2" : "body1"}
          >
            Dashboard
          </Typography>

          <Typography
            component="a"
            href="/resource/addtime"
            variant={isActive("/resource/addtime") ? "body2" : "body1"}
          >
            Time
          </Typography>
          <Typography
            component="a"
            href="/resource/ope"
            variant={isActive("/resource/ope") ? "body2" : "body1"}
          >
            Expense
          </Typography>
          <Typography
            component="a"
            href="/resource/calendar"
            variant={isActive("/resource/calendar") ? "body2" : "body1"}
          >
            Calendar
          </Typography>
          <Typography
            component="a"
            href="/resource/leaves"
            variant={isActive("/resource/leaves") ? "body2" : "body1"}
          >
            Leave
          </Typography>
          {billedDesignations.includes(auth.user.designation) && (
            <Typography
              component="a"
              href="/resource/exportexcel"
              variant={isActive("/resource/exportexcel") ? "body2" : "body1"}
            >
              TimeImport
            </Typography>
          )}
        </PopoverGroup>

        <div className="hidden lg:flex lg:flex-1 lg:justify-end items-center">
          <CustomTooltip text={auth.user.fullName}>
            <Avatar
              sx={{
                bgcolor: "#f8f8f8",
                marginRight: 2,
                color: COLORS.primary,
                fontSize: "14px",
                fontWeight: "700",
              }}
            >
              {getUserInitials(auth?.user?.fullName)}
            </Avatar>
          </CustomTooltip>
          <button
            onClick={onLogOut}
            className="font-semibold text-white bg-black px-4 py-2 rounded-lg flex align-bottom"
          >
            <Logout className="mx-1" /> Logout
          </button>
        </div>
      </nav>

      {/* Mobile version  */}
      <Dialog
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
        className="lg:hidden"
      >
        <div className="fixed inset-0 z-10" />
        <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="/resourceDashboard" className="-m-1.5 p-1.5">
              <img
                src={`/assets/Comput_labs_black.png`}
                alt="logo"
                className="h-8 w-auto"
              />
            </a>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Typography
                  component="a"
                  href="/resourceDashboard"
                  variant={isActive("/resourceDashboard") ? "body2" : "body1"}
                  className={`-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50`}
                >
                  Dashboard
                </Typography>
                <Typography
                  component="a"
                  href="/resource/addtime"
                  variant={isActive("/resource/addtime") ? "body2" : "body1"}
                  className={`-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50`}
                >
                  Time
                </Typography>
                <Typography
                  component="a"
                  href="/resource/ope"
                  variant={isActive("/resource/ope") ? "body2" : "body1"}
                  className={`-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50`}
                >
                  Expense
                </Typography>
                <Typography
                  component="a"
                  href="/resource/calendar"
                  variant={isActive("/resource/calendar") ? "body2" : "body1"}
                  className={`-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50`}
                >
                  Calendar
                </Typography>
                <Typography
                  component="a"
                  href="/resource/leaves"
                  variant={isActive("/resource/leaves") ? "body2" : "body1"}
                  className={`-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50`}
                >
                  Leave
                </Typography>
                {billedDesignations.includes(auth.user.designation) && (
                  <Typography
                    component="a"
                    href="/resource/exportexcel"
                    variant={
                      isActive("/resource/exportexcel") ? "body2" : "body1"
                    }
                    className={`-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50`}
                  >
                    Timespent
                  </Typography>
                )}
              </div>
              <div className="py-6">
                <button
                  onClick={onLogOut}
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  <Logout /> Logout
                </button>
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
}
