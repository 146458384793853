import React from "react";
import { Card, Grid, Typography, IconButton, Box } from "@mui/material";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";
import { formatMonthDate } from "../../utils/services";
import CustomTooltip from "./CustomTootltip";
import useResponsive from "../../constants/useResponsive";

const OpeCard = ({ time, isFormVisible, handleEdit, handleDelete }) => {
  const { isSm } = useResponsive();

  return (
    <Card
      elevation={0}
      sx={{
        padding: isSm ? 1 : 2,
        marginBottom: 2,
        display: "flex",
        alignItems: "center",
        border: "1px solid #e0e0e0",
        borderRadius: "10px",
        width: "100%",
        overflow: "hidden",
        wordBreak: "break-word",
        boxSizing: "border-box",
      }}
    >
      <Grid container alignItems="center" spacing={2}>
        {/* Work Date */}
        <Grid item xs={isSm ? 12 : 1}>
          <Typography>{formatMonthDate(time.expenseDate)}</Typography>
        </Grid>

        {/* Matter Name */}
        <Grid item xs={isSm ? 12 : isFormVisible ? 3.5 : 2.5}>
          <Typography variant="body2">{time.matter?.name}</Typography>
        </Grid>

        {/* Description */}
        <Grid item xs={isSm ? 12 : isFormVisible ? 4 : 3}>
          <Typography>{time?.description?.slice(0, 50)}</Typography>
        </Grid>

        {/* Amount */}
        <Grid item xs={isSm ? 4 : 1}>
          <Typography variant="body2" align="left">
            {time.amount?.$numberDecimal}
          </Typography>
        </Grid>

        {/* Status */}
        <Grid item xs={isSm ? 4 : 1.5}>
          <Typography
            align="center"
            variant="body2"
            color={time.status === "pending" ? "red" : "green"}
          >
            {time.status}
          </Typography>
        </Grid>

        {/* Bills */}
        {!isFormVisible && !isSm && (
          <Grid item xs={isSm ? 12 : 1.5}>
            <Box
              display="flex"
              flexWrap="wrap"
              gap={1}
              justifyContent={isSm ? "flex-start" : "center"}
            >
              {time.opeBills.map((file, index) => (
                <img
                  key={index}
                  src={file}
                  alt={`Bill ${index + 1}`}
                  style={{
                    maxWidth: isSm ? "100%" : "120px",
                    height: isSm ? "200px" : "100px",
                    marginBottom: "8px",
                  }}
                />
              ))}
            </Box>
          </Grid>
        )}

        {/* Edit and Delete Buttons */}
        <Grid
          item
          xs={isSm ? 4 : isFormVisible ? 1 : 1.5}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <CustomTooltip text="Edit">
            <IconButton
              sx={{ paddingRight: isSm ? "5px" : "10px" }}
              onClick={() => handleEdit(time)}
            >
              <CiEdit size={18} />
            </IconButton>
          </CustomTooltip>

          <CustomTooltip text="Delete">
            <IconButton onClick={() => handleDelete(time._id)}>
              <RiDeleteBin6Line size={16} />
            </IconButton>
          </CustomTooltip>
        </Grid>
      </Grid>
    </Card>
  );
};

export default OpeCard;
