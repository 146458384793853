import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllEmployeesByAdminId } from "../../actions";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  allDesignations,
  API,
  ConfirmationAlert,
  formatDatePrevious,
  formatDateToYYYYMMDD,
  errorAlert,
  successAlert,
} from "../../utils/services";
import ReusableTable from "../../components/DataTable";
import axiosInstance from "../../helpers/axios";
import CustomModal from "../../components/ui/CustomModal";
import {
  RiCloseLine,
  RiDeleteBin6Line,
  RiFileDownloadFill,
} from "react-icons/ri";
import { AiOutlineUpload } from "react-icons/ai"; // Upload icon
import { COLORS } from "../../constants/theme";
import {
  Button,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { UploadFile } from "@mui/icons-material";

const ShowResources = () => {
  const dispatch = useDispatch();
  const adminEmployees = useSelector((state) => state.adminEmployees);
  const { employees, loading, totalCountOfEmployees } = adminEmployees;
  const auth = useSelector((state) => state.auth);

  // STATES
  const [showModal, setShowModal] = useState(false);
  const [editingResource, setEditingResource] = useState(null);

  console.log(editingResource);

  const [userInfo, setUserInfo] = useState({
    fullName: "",
    email: "",
    designation: "",
    compensation: "",
    joiningDate: "",
    password: "",
    passwordRepeat: "",
    status: "active",
    totalLeaves: "",
    totalWorkFromHome: "",
    loading: false,
  });

  const [isFocused, setIsFocused] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]); // Contains URLs of uploaded files
  const [filesToUpload, setFilesToUpload] = useState([]); // Files selected but not yet uploaded
  const [uploading, setUploading] = useState(false);

  const {
    fullName,
    email,
    designation,
    compensation,
    joiningDate,
    password,
    passwordRepeat,
    status,
    totalLeaves,
    totalWorkFromHome,
  } = userInfo;

  // Pagination states
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  // useEffect to fetch employees
  useEffect(() => {
    dispatch(getAllEmployeesByAdminId(rowsPerPage, page));
  }, [dispatch, page, rowsPerPage]);

  // Pagination handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const columns = [
    { id: "fullName", label: "Name", width: "25%" },
    { id: "designation", label: "Designation", width: "20%" },
    { id: "joiningDate", label: "Joining Date", width: "20%" },
    { id: "status", label: "Status", width: "10%" },
    { id: "documentsDisplay", label: "Engagement Terms", width: "10%" },
    { id: "edit", label: "Edit", width: "10%" },
    { id: "delete", label: "Delete", width: "10%" },
  ];

  const rows = employees.map((employee) => ({
    id: employee._id,
    fullName: employee.fullName,
    email: employee.email,
    designation: employee.designation,
    compensation: employee.compensation,
    joiningDate: formatDatePrevious(employee.joiningDate),
    totalLeaves: employee.totalLeaves,
    totalWorkFromHome: employee.totalWorkFromHome,
    status: employee.status,
    documents: employee.documents || [],
  }));

  // Function to delete resource
  const handleDeleteResource = (id) => {
    console.log(id);
    toast(
      <ConfirmationAlert
        onDeleteOff={() => onDeleteResource(id)}
        question={"Are you sure to delete this resource?"}
        answer={"Yes, Delete it"}
        icon={<RiDeleteBin6Line />}
      />,
      {
        position: toast.POSITION.TOP_CENTER,
        closeButton: true,
        autoClose: false,
        draggable: false,
      }
    );
  };

  const onDeleteResource = async (deleteId) => {
    try {
      const response = await axiosInstance.delete(
        `${API}/employee/delete/${deleteId}`
      );

      if (response.data) {
        successAlert("Resource Deleted Successfully!");
        dispatch(getAllEmployeesByAdminId(rowsPerPage, page));
      }
    } catch (error) {
      errorAlert("Deletion of resource failed", "Please try again...");
    }
  };

  // Functions to open and close the modal
  const openModal = (entryData) => {
    if (entryData) {
      console.log("editing resource data", entryData);
      setEditingResource(entryData);
      setUserInfo({
        fullName: entryData.fullName || "",
        email: entryData.email || "",
        designation: entryData.designation || "",
        joiningDate: formatDateToYYYYMMDD(entryData.joiningDate) || "",
        status: entryData.status || "",
        compensation: entryData.compensation || "",
        totalLeaves: entryData.totalLeaves || "",
        totalWorkFromHome: entryData.totalWorkFromHome || "",
      });

      // Ensure documents are consistently an array of objects with 'url'
      let documents = entryData.documents;

      console.log("Existing documents:", documents); // Debugging

      if (documents) {
        if (!Array.isArray(documents)) {
          // If documents is a single string, convert it to an array
          documents = [documents];
        }
        setSelectedFiles(
          documents.map((doc) => (typeof doc === "string" ? { url: doc } : doc))
        );
        console.log("selected files", selectedFiles);
      } else {
        setSelectedFiles([]);
      }
    } else {
      setEditingResource(null);
      setUserInfo({
        fullName: "",
        email: "",
        designation: "",
        compensation: "",
        joiningDate: "",
        totalLeaves: "",
        totalWorkFromHome: "",
        password: "",
        passwordRepeat: "",
        loading: false,
      });
      setSelectedFiles([]);
    }
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setUserInfo({
      fullName: "",
      email: "",
      designation: "",
      compensation: "",
      joiningDate: "",
      totalLeaves: "",
      totalWorkFromHome: "",
      password: "",
      passwordRepeat: "",
      loading: false,
    });
    setSelectedFiles([]);
    setFilesToUpload([]);
  };

  // Validation functions
  const isValidObjField = () => {
    if (
      fullName === "" ||
      email === "" ||
      designation === "" ||
      joiningDate === "" ||
      totalLeaves === "" ||
      totalWorkFromHome === ""
    ) {
      return false;
    } else {
      return true;
    }
  };

  const isValidEmail = (value) => {
    const regx =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regx.test(value);
  };

  const isValidForm = () => {
    if (!isValidObjField()) {
      errorAlert("All Fields are Required!");
      return false;
    }
    if (!fullName.trim() || fullName.length < 3) {
      errorAlert("Name should be 3 characters long!");
      return false;
    }
    if (!isValidEmail(email)) {
      errorAlert("Enter a valid email!");
      return false;
    }
    if (!editingResource) {
      if (!password.trim() || password.length < 6) {
        errorAlert("Password should be 6 characters long!");
        return false;
      }
      if (password !== passwordRepeat) {
        errorAlert("Password does not match!");
        return false;
      }
    }
    if (totalLeaves === "") {
      errorAlert("Assign the leaves to the resource");
      return false;
    }
    if (totalWorkFromHome === "") {
      errorAlert("Assign the work from home to the resource");
      return false;
    }
    return true;
  };

  const handleOnChangeText = (value, fieldName) => {
    setUserInfo({ ...userInfo, [fieldName]: value });
  };

  // File selection handler
  const handleFileSelect = (event) => {
    const filesArray = Array.from(event.target.files);
    setFilesToUpload(filesArray);
  };

  // File upload handler
  const handleFileUpload = async (resourceId = null) => {
    if (filesToUpload.length > 0) {
      try {
        setUploading(true);

        const uploadPromises = filesToUpload.map(async (file) => {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("adminId", auth.user._id);
          if (resourceId) {
            formData.append("resourceId", resourceId);
          }

          const response = await axiosInstance.post(
            `${API}/uploadResourceEngagementTerms`,
            formData
          );

          // Check the response
          console.log("File upload response:", response.data);

          return response.data.url; // Assume response contains uploaded file URL
        });

        const uploadedUrls = await Promise.all(uploadPromises);

        console.log("Uploaded URLs:", uploadedUrls);

        setSelectedFiles((prevSelectedFiles) => {
          const prevFiles = Array.isArray(prevSelectedFiles)
            ? prevSelectedFiles
            : [];

          console.log("Previous selected files:", prevFiles);

          const newSelectedFiles = [
            ...prevFiles,
            ...uploadedUrls.map((url) => ({ url })),
          ];

          console.log("New selected files:", newSelectedFiles);

          return newSelectedFiles;
        });

        setFilesToUpload([]); // Clear filesToUpload after upload
        successAlert("Files uploaded successfully!");
      } catch (error) {
        console.error("File upload error:", error);
        errorAlert("File upload failed, please try again.");
      } finally {
        setUploading(false);
      }
    }
  };

  // File remove handler
  const handleRemoveFile = async (index) => {
    const fileToRemove = selectedFiles[index];

    try {
      await axiosInstance.delete(`${API}/deleteEngagementLetter`, {
        data: { filePath: fileToRemove.url || fileToRemove },
      });

      const newSelectedFiles = selectedFiles.filter((_, i) => i !== index);
      setSelectedFiles(newSelectedFiles);

      successAlert("File deleted successfully from DigitalOcean Spaces.");
    } catch (error) {
      console.error("Error deleting file:", error);
      alert("Failed to delete file. Please try again.");
    }
  };

  const handleRemoveSelectedFile = (index) => {
    const newFilesToUpload = filesToUpload.filter((_, i) => i !== index);
    setFilesToUpload(newFilesToUpload);
  };

  const handleDownloadAllDocuments = async (resourceId) => {
    try {
      const response = await axiosInstance.get(
        `${API}/downloadResourceDocuments/${resourceId}`,
        {
          responseType: "blob", // Important for file downloads
        }
      );

      // Create a URL for the blob
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Create a link and trigger a download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `resource_${resourceId}_documents.zip`);
      document.body.appendChild(link);
      link.click();

      // Clean up
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading documents:", error);
      errorAlert("Failed to download documents.");
    }
  };

  // Form submission handler
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isValidForm()) {
      setUserInfo({ ...userInfo, loading: true });
      try {
        if (!editingResource) {
          // Add new resource
          try {
            const newResourceData = {
              company: auth.user._id,
              fullName,
              email,
              designation,
              compensation,
              joiningDate,
              status: "active",
              passwordRepeat,
              totalLeaves,
              totalWorkFromHome,
              documents: selectedFiles.map((file) => file.url || file),
            };

            const response = await axios.post(`${API}/signup`, newResourceData);

            if (response.data) {
              successAlert(response.data.message);

              // Reset form and state
              setUserInfo({
                fullName: "",
                email: "",
                designation: "",
                compensation: "",
                joiningDate: "",
                password: "",
                passwordRepeat: "",
                totalLeaves: "",
                totalWorkFromHome: "",
                loading: false,
              });
              setFilesToUpload([]);
              setSelectedFiles([]);
              dispatch(getAllEmployeesByAdminId(rowsPerPage, page));
            }
          } catch (error) {
            errorAlert(
              "Error in adding resource: " + error.response.data.message
            );
          }
        } else {
          // Update existing resource
          try {
            const updatedUserInfo = {
              ...userInfo,
              documents: selectedFiles.map((file) => file.url || file),
            };

            const response = await axiosInstance.put(
              `${API}/employee/update/${editingResource.id}`,
              updatedUserInfo
            );

            if (response.data) {
              successAlert("Resource updated successfully");

              // Reset form and state
              setUserInfo({
                fullName: "",
                email: "",
                designation: "",
                compensation: "",
                joiningDate: "",
                status: "",
                totalLeaves: "",
                totalWorkFromHome: "",
                loading: false,
              });
              setFilesToUpload([]);
              setSelectedFiles([]);
            }
            dispatch(getAllEmployeesByAdminId(rowsPerPage, page));
          } catch (error) {
            errorAlert("Error updating resource details, try again...");
          }
        }
        closeModal();
      } catch (error) {
        console.error("Error:", error);
        errorAlert("Error: Something went wrong");
      }
    }
  };

  return (
    <div>
      <ToastContainer />

      <ReusableTable
        componentName={"Resources"}
        columns={columns}
        data={rows.map((row) => ({
          ...row,
          documentsDisplay: (
            <div className="flex items-center gap-2">
              {Array.isArray(row.documents) && row.documents.length > 0 ? (
                // Display a single download button
                <button
                  type="button"
                  onClick={() => handleDownloadAllDocuments(row.id)}
                >
                  <RiFileDownloadFill
                    size={22}
                    title="Download All Documents"
                  />
                </button>
              ) : (
                <button type="button" onClick={() => openModal(row)}>
                  <UploadFile style={{fontSize:"20px"}} titleAccess="Upload Documents" />
                </button>
              )}
            </div>
          ),
        }))}
        onAdd={openModal}
        onEdit={openModal}
        onDelete={handleDeleteResource}
        loading={loading}
        totalCount={totalCountOfEmployees}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />

      {showModal && (
        <CustomModal
          showModal={showModal}
          closeModal={closeModal}
          title={editingResource ? "Edit Resource" : "Add Resource"}
          onSubmit={handleSubmit}
        >
          {/* 1st Line inputs  */}
          <div className="flex gap-4 w-full">
            <div style={{ width: "50%" }}>
              <input
                type="text"
                name="fullName"
                placeholder="Full name"
                value={fullName}
                onChange={(event) =>
                  handleOnChangeText(event.target.value, "fullName")
                }
              />
            </div>
            <div style={{ width: "50%" }}>
              <input
                type="email"
                value={email}
                name="email"
                placeholder="Email address"
                onChange={(event) =>
                  handleOnChangeText(event.target.value, "email")
                }
              />
            </div>
          </div>

          {/* 2nd Line inputs  */}
          <div className="flex gap-4 w-full">
            <div style={{ width: "50%" }}>
              <select
                style={{ width: "100%", height: "30px" }}
                value={designation}
                name="designation"
                onChange={(event) =>
                  handleOnChangeText(event.target.value, "designation")
                }
              >
                <option value="">Select Designation</option>
                {allDesignations.map((designation) => (
                  <option key={designation} value={designation}>
                    {designation}
                  </option>
                ))}
              </select>
            </div>

            <div style={{ width: "50%" }}>
              <input
                type={isFocused || joiningDate ? "date" : "text"}
                name="joiningDate"
                placeholder="Joining date"
                value={joiningDate}
                onFocus={() => setIsFocused(true)}
                onBlur={() => !joiningDate && setIsFocused(false)}
                onChange={(event) =>
                  handleOnChangeText(event.target.value, "joiningDate")
                }
              />
            </div>
          </div>

          {editingResource && (
            <div style={{ width: "100%" }}>
              <p>
                <b>Status</b>
              </p>
              <select
                style={{ width: "100%", padding: 4 }}
                name="status"
                value={status}
                onChange={(event) =>
                  handleOnChangeText(event.target.value, "status")
                }
              >
                <option value="active">Active</option>
                <option value="inActive">Inactive</option>
              </select>
            </div>
          )}

          <div className="flex gap-4 w-full">
            <div style={{ width: "50%" }}>
              <input
                type="number"
                name="totalLeaves"
                placeholder="Assigned leaves"
                value={totalLeaves}
                onChange={(event) =>
                  handleOnChangeText(event.target.value, "totalLeaves")
                }
              />
            </div>

            <div style={{ width: "50%" }}>
              <input
                type="number"
                name="totalWorkFromHome"
                placeholder="Assigned Work From Home"
                value={totalWorkFromHome}
                onChange={(event) =>
                  handleOnChangeText(event.target.value, "totalWorkFromHome")
                }
              />
            </div>
          </div>

          {!editingResource && (
            <div className="flex gap-4 w-full">
              <div style={{ width: "50%" }}>
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={password}
                  onChange={(event) =>
                    handleOnChangeText(event.target.value, "password")
                  }
                />
              </div>
              <div style={{ width: "50%" }}>
                <input
                  type="password"
                  name="passwordRepeat"
                  placeholder="Repeat password"
                  value={passwordRepeat}
                  onChange={(event) =>
                    handleOnChangeText(event.target.value, "passwordRepeat")
                  }
                />
              </div>
            </div>
          )}

          {/* File Upload Section */}
          {editingResource && (
            <div style={{ marginTop: "20px" }}>
              <Typography variant="body2">Engagement Terms</Typography>

              {/* Existing Files */}
              {selectedFiles.length > 0 ? (
                <List dense>
                  {selectedFiles.map((file, index) => (
                    <ListItem
                      key={index}
                      secondaryAction={
                        <IconButton
                          edge="end"
                          aria-label="remove"
                          onClick={() => handleRemoveFile(index)}
                        >
                          <RiCloseLine />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary={
                          <a
                            href={file.url || file}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              textDecoration: "none",
                              color: COLORS.primary,
                            }}
                          >
                            {file.name || `Document ${index + 1}`}
                          </a>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              ) : (
                <p>No Documents</p>
              )}

              {/* Upload New Files */}
              <Button
                variant="contained"
                component="label"
                startIcon={<AiOutlineUpload />}
                style={{ marginTop: "10px" }}
                disabled={uploading}
              >
                Select Documents
                <input
                  type="file"
                  multiple
                  accept=".pdf,.doc,.docx"
                  onChange={handleFileSelect}
                  hidden
                />
              </Button>

              {/* Files Ready to Upload */}
              {filesToUpload.length > 0 && (
                <div style={{ marginTop: "10px" }}>
                  <List dense>
                    {filesToUpload.map((file, index) => (
                      <ListItem
                        key={index}
                        secondaryAction={
                          <IconButton
                            edge="end"
                            aria-label="remove"
                            onClick={() => handleRemoveSelectedFile(index)}
                          >
                            <RiCloseLine />
                          </IconButton>
                        }
                      >
                        <ListItemText primary={file.name} />
                      </ListItem>
                    ))}
                  </List>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      handleFileUpload(
                        editingResource ? editingResource.id : null
                      )
                    }
                    disabled={uploading}
                    startIcon={<AiOutlineUpload />}
                  >
                    {uploading ? "Uploading..." : "Upload Selected Files"}
                  </Button>
                  {uploading && (
                    <LinearProgress style={{ marginTop: "10px" }} />
                  )}
                </div>
              )}
            </div>
          )}
        </CustomModal>
      )}
    </div>
  );
};

export default ShowResources;
