import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addClient, getAllClients } from "../../actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  API,
  ConfirmationAlert,
  errorAlert,
  successAlert,
} from "../../utils/services";
import ReusableTable from "../../components/DataTable";
import axiosInstance from "../../helpers/axios";
import CustomModal from "../../components/ui/CustomModal";
import { RiDeleteBin6Line } from "react-icons/ri";

const ShowClient = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const adminClient = useSelector((state) => state.adminClient);
  const { clientList, totalClients, loading } = adminClient;

  // console.log("Client data", clientList);

  //STATES
  const [showModal, setShowModal] = useState(false);
  const [editingClient, setEditingClient] = useState(null);

  const [clientInfo, setClientInfo] = useState({
    name: "",
    address: "",
    loading: false,
  });

  const { name, address } = clientInfo;

  //pagination states
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  useEffect(() => {
    dispatch(getAllClients(rowsPerPage, page));
  }, [dispatch, page, rowsPerPage]);

  //pagination functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //CLIENTS MAIN TABLE COLUMNS AND ROWS DATA SEND TO TABLE COMPONENT
  const columns = [
    { id: "name", label: "Name", minWidth: 170 },
    { id: "clientAddress", label: "Address", minWidth: 170 },
    { id: "edit", label: "Edit", minWidth: 70 },
    { id: "delete", label: "Delete", minWidth: 70 },
  ];

  const rows = clientList.map((client, index) => ({
    id: client._id,
    name: client.name,
    clientAddress: client.clientAddress,
  }));

  //FUNCTIONS TO DELETE RESOURCE
  const handleDeleteClient = (id) => {
    // console.log("Id", id);
    toast(
      <ConfirmationAlert
        question={"Are you sure to delete this client?"}
        onDeleteOff={() => onDeleteClient(id)}
        answer={"Yes, Delete it"}
        icon={<RiDeleteBin6Line />}
      />,
      {
        position: toast.POSITION.TOP_CENTER,
        closeButton: true,
        autoClose: false,
        draggable: false,
      }
    );
  };

  const onDeleteClient = async (deleteId) => {
    try {
      // console.log("clientIdToDelete", deleteId);

      const response = await axiosInstance.delete(
        `${API}/client/deleteClient/${deleteId}`
      );

      if (response.data) {
        successAlert("Client Deleted Successfully!");
        dispatch(getAllClients(rowsPerPage, page));
      }
    } catch (error) {
      // console.log(error);
      errorAlert("Deletion of client failed", "Please try again...");
    }
  };

  //FUNCTIONS FOR OPEN AND CLOSE THE MODAL

  const openModal = (entryData) => {
    // console.log("User data", entryData);
    if (entryData) {
      setEditingClient(entryData);
      // console.log("Edit data", editingResource);
      setClientInfo({
        name: entryData.name || "",
        address: entryData.clientAddress || "",
      });
    } else {
      setEditingClient(null);
    }
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setClientInfo({
      ...clientInfo,
      name: "",
      address: "",
      loading: false,
    });
  };

  //FUNCTIONS TO CHECK THE FORM DATA VALIDATION

  const isValidObjField = () => {
    if (name === "") {
      return false;
    } else {
      return true;
    }
  };

  const isValidForm = () => {
    //all fields must be filled
    if (!isValidObjField()) return errorAlert("All Fields are Required!");

    return true;
  };

  const handleOnChangeText = (value, fieldName) => {
    setClientInfo({ ...clientInfo, [fieldName]: value });
  };

  //API CAll FOR ADD and UPDATE RESOURCE FEATURE

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isValidForm()) {
      setClientInfo({ ...clientInfo, loading: true });
      // console.log("ClientInfo", clientInfo);
      // console.log("editingClient", editingClient);
      try {
        if (!editingClient) {
          dispatch(await addClient(auth.user._id, name, address));
          setClientInfo({
            ...clientInfo,
            name: "",
            address: "",
            loading: false,
          });
          successAlert("Client Added Successfully!");
          dispatch(getAllClients(rowsPerPage, page));
        } else {
          try {
            // console.log("Edit section", clientInfo);

            const response = await axiosInstance.put(
              `${API}/client/updateClient/${editingClient.id}`,
              {
                name: clientInfo.name,
                clientAddress: clientInfo.address,
              }
            );

            // console.log("Response of edit data", response.data);

            if (response.data) {
              dispatch(getAllClients(rowsPerPage, page));
              successAlert("Client Updated Successfully!");
            }
          } catch (error) {
            errorAlert("Error updating client details, try again...");
          }
        }
        closeModal();
      } catch (error) {
        console.error("Error:", error);
        errorAlert("Error : Something went wrong", error);
      }
    }
  };

  return (
    <div>
      <ToastContainer />

      <ReusableTable
        componentName={"Clients"}
        columns={columns}
        data={rows}
        onAdd={openModal}
        onEdit={openModal}
        onDelete={handleDeleteClient}
        loading={loading}
        totalCount={totalClients}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />

      {showModal && (
        <CustomModal
          showModal={showModal}
          closeModal={closeModal}
          title={editingClient ? "EDIT CLIENT" : "ADD CLIENT"}
          onSubmit={handleSubmit}
        >
          <input
            type="text"
            name="name"
            placeholder="Enter client name"
            value={name}
            onChange={(event) => handleOnChangeText(event.target.value, "name")}
          />

          <input
            type="address"
            value={address}
            name="email"
            placeholder="Enter client address"
            onChange={(event) =>
              handleOnChangeText(event.target.value, "address")
            }
          />
        </CustomModal>
      )}
    </div>
  );
};

export default ShowClient;
