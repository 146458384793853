import { employeeConstants } from "./constants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API } from "../utils/services";
import axiosInstance from "../helpers/axios";

export const getAllEmployeesByAdminId = (rowsPerPage = 0, page = 0) => {
  return async (dispatch) => {
    dispatch({ type: employeeConstants.GET_ALL_EMPLOYEES_REQUEST });
    try {
      const response = await axiosInstance.get(`${API}/employee/getAll`, {
        params: {
          limit: rowsPerPage,
          page,
        },
      });

      // console.log("Response for get all employees by admin", response.data);

      dispatch({
        type: employeeConstants.GET_ALL_EMPLOYEES_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      // console.log("error in fetching employees", error);
      dispatch({
        type: employeeConstants.GET_ALL_EMPLOYEES_FAILURE,
        payload: { error: error.response.data.error },
      });

      toast.error({
        text1: error.response.data.error,
      });
    }
  };
};

export const getAllEmployees = () => {
  return async (dispatch, getState) => {
    dispatch({ type: employeeConstants.GET_ALL_EMPLOYEES_REQUEST });
    // console.log("-----------", getState().auth.user.company);

    await axiosInstance
      .get("/employee/getAllEmployeesByEmployee", {
        params: {
          companyId: getState().auth.user.company,
        },
      })
      .then(async (res) => {
        // console.log("Response for get all employees by employee", res.data);
        dispatch({
          type: employeeConstants.GET_ALL_EMPLOYEES_SUCCESS,
          payload: {
            employees: res.data,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: employeeConstants.GET_ALL_EMPLOYEES_FAILURE,
          payload: { error: error.response.data.error },
        });
        toast.error({
          text1: error.response.data.error,
        });
      });
  };
};
