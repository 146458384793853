import React from "react";

const DateRangePickerTst = ({ startDate, endDate, onDateRangeChange }) => {
  const parseDate = (dateString) => {
    if (!dateString) return null;
    const [year, month, day] = dateString.split("-");
    return new Date(year, month - 1, day, 0, 0, 0); // Set to midnight to avoid timezone issues
  };

  const start = parseDate(startDate);
  const end = parseDate(endDate);
  const [hoveredDate, setHoveredDate] = React.useState(null); // Track hovered date
  const [currentMonth, setCurrentMonth] = React.useState(new Date()); // Current month

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const days = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

  // Get today's date
  const today = new Date();
  const isToday = (date) => {
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const formatDate = (date) => {
    return `${months[date.getMonth()].slice(
      0,
      3
    )} ${date.getDate()}, ${date.getFullYear()}`;
  };

  const isInRange = (date) => {
    const rangeEnd = end || hoveredDate; // Use hovered date if no end date is selected
    return start && rangeEnd && date >= start && date <= rangeEnd;
  };

  const handleDateClick = (date) => {
    const adjustedDate = new Date(date.setHours(0, 0, 0, 0));

    if (!start || (start && end)) {
      onDateRangeChange(adjustedDate.toLocaleDateString("en-CA"), ""); // Set start date and reset end date
      setHoveredDate(null); // Reset hovered date
    } else if (adjustedDate < start) {
      onDateRangeChange(
        adjustedDate.toLocaleDateString("en-CA"),
        start.toLocaleDateString("en-CA")
      ); // Swap if clicked date is before start date
      setHoveredDate(null); // Reset hovered date
    } else {
      onDateRangeChange(
        start.toLocaleDateString("en-CA"),
        adjustedDate.toLocaleDateString("en-CA")
      ); // Set end date
      setHoveredDate(null); // Reset hovered date
    }
  };

  const handleDateMouseEnter = (date) => {
    if (start && !end) {
      setHoveredDate(new Date(date.setHours(0, 0, 0, 0))); // Set the hovered date
    }
  };

  const renderCalendar = (month) => {
    const firstDay = new Date(
      month.getFullYear(),
      month.getMonth(),
      1
    ).getDay();
    const daysInMonth = new Date(
      month.getFullYear(),
      month.getMonth() + 1,
      0
    ).getDate();
    const weeks = [];
    let days = [];

    for (let i = 0; i < firstDay; i++) {
      days.push(<td key={`empty-${i}`} className="p-1"></td>); // Smaller padding
    }

    for (let i = 1; i <= daysInMonth; i++) {
      const date = new Date(month.getFullYear(), month.getMonth(), i, 0, 0, 0);
      const isSelected =
        date.getTime() === start?.getTime() ||
        date.getTime() === end?.getTime();
      const isRangeDate = isInRange(date);
      const isTodayDate = isToday(date);

      days.push(
        <td key={i} className="p-1">
          <button
            onClick={() => handleDateClick(date)}
            onMouseEnter={() => handleDateMouseEnter(date)}
            className={`w-6 h-6 rounded-full flex items-center justify-center text-xs
              ${isSelected ? "bg-black text-white" : ""}
              ${
                isRangeDate && !isSelected
                  ? "bg-gray-300 text-black rounded-full"
                  : ""
              }
              ${!isRangeDate && !isSelected ? "hover:bg-gray-200" : ""}
              ${
                isRangeDate && date > start && !end
                  ? "rounded-full bg-gray-400 text-black"
                  : ""
              }
              ${isTodayDate ? "bg-blue-200 text-black" : ""}
            `}
          >
            {i}
          </button>
        </td>
      );

      if (days.length === 7) {
        weeks.push(<tr key={`week-${weeks.length}`}>{days}</tr>);
        days = [];
      }
    }

    if (days.length > 0) {
      weeks.push(<tr key={`week-${weeks.length}`}>{days}</tr>);
    }

    return weeks;
  };

  const handlePrevMonth = () => {
    setCurrentMonth(
      new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1)
    );
  };

  const handleNextMonth = () => {
    setCurrentMonth(
      new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1)
    );
  };

  return (
    <div
      className="bg-white text-black p-4 rounded-lg shadow-lg"
      style={{ width: "100%", maxWidth: "500px" }}
    >
      <div className="mb-2">
        <button className="bg-gray-200 text-black px-2 py-1 rounded-md w-full text-left text-xs">
          {start ? formatDate(start) : "Start Date"} -{" "}
          {end ? formatDate(end) : "End Date"}
        </button>
      </div>
      <div
        className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2"
        style={{ alignItems: "center" }}
      >
        {[0, 1].map((offset) => (
          <div key={offset} className="flex-1">
            <div className="flex justify-between items-center mb-1">
              <button
                onClick={handlePrevMonth}
                className="text-gray-400 hover:text-white text-xs"
              >
                &lt;
              </button>
              <h2 className="text-sm font-semibold">
                {
                  months[
                    new Date(
                      currentMonth.getFullYear(),
                      currentMonth.getMonth() + offset
                    ).getMonth()
                  ]
                }{" "}
                {new Date(
                  currentMonth.getFullYear(),
                  currentMonth.getMonth() + offset
                ).getFullYear()}
              </h2>
              <button
                onClick={handleNextMonth}
                className="text-gray-400 hover:text-white text-xs"
              >
                &gt;
              </button>
            </div>
            <table className="w-full">
              <thead>
                <tr>
                  {days.map((day) => (
                    <th
                      key={day}
                      className="p-1 text-center text-gray-500 text-xs"
                    >
                      {day}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {renderCalendar(
                  new Date(
                    currentMonth.getFullYear(),
                    currentMonth.getMonth() + offset
                  )
                )}
              </tbody>
            </table>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DateRangePickerTst;
