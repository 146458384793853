import React from "react";
import { Button as MuiButton } from "@mui/material";
import { COLORS } from "../../constants/theme";
import useResponsive from "../../constants/useResponsive";

const CustomButton = ({
  children,
  variant = "contained",
  type = "button",
  color = "white",
  ...props
}) => {
  const { isSm, isMd, isLg } = useResponsive();

  const getFontSize = () => {
    if (isSm) return "10px";
    if (isMd) return "12px";
    if (isLg) return "14px";
    return "12px";
  };

  const getStyles = (variant) => {
    const commonStyles = {
      fontWeight: "bold",
      height: "30px",
      textTransform: "none",
      transition: "transform 0.3s ease-in-out, background-color 0.3s ease",
      fontSize: getFontSize(), // Set font size dynamically
      width: {
        xs: "100%", // full width on extra small screens
        sm: "75%", // 75% width on small screens
        md: "50%", // 50% width on medium screens
        lg: "33%", // 33% width on large screens
        xl: "25%", // 25% width on extra large screens
      },
    };

    switch (variant) {
      case "text":
        return {
          ...commonStyles,
          color: "gray",
          fontWeight: "200",
          height: "16px",
          width: "100%",
        };
      case "outlined":
        return {
          ...commonStyles,
          color: COLORS.primary,
          borderColor: COLORS.primary,
          width: "100%",
          "&:hover": {
            backgroundColor: "rgba(55, 42, 27, 0.1)",
            borderColor: COLORS.primary,
            transform: "scale(1.03)",
          },
        };
      case "contained":
      default:
        return {
          ...commonStyles,
          backgroundColor: COLORS.primary,
          color: "white",
          "&:hover": {
            backgroundColor: COLORS.primary,
            color: "white",
            transform: "scale(1.03)",
          },
        };
    }
  };

  return (
    <MuiButton
      {...props}
      type={type}
      variant={variant}
      sx={{
        ...getStyles(variant),
        ...(type === "submit" && {
          backgroundColor: `${COLORS.primary} !important`,
          color: "white !important",
          marginTop: "5px",
          fontFamily: "sans-serif",
        }),
      }}
    >
      {children}
    </MuiButton>
  );
};

export default CustomButton;
