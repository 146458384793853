import axios from "../helpers/axios";
import { authConstants } from "./constants";
import { API } from "../utils/services";
import axiosInstance from "../helpers/axios";

export const AdminSignIn = (user) => {
  return async (dispatch) => {
    dispatch({ type: authConstants.LOGIN_REQUEST });

    try {
      const res = await axiosInstance.post(`${API}/signin`, { ...user });

      if (res && res.data) {
        const { token, user } = res.data;

        localStorage.setItem("authToken", token);
        localStorage.setItem("user", JSON.stringify(user));

        dispatch({
          type: authConstants.LOGIN_SUCCESS,
          payload: {
            authToken: token,
            user: user,
          },
        });
      } else {
        dispatch({
          type: authConstants.LOGIN_FAILURE,
          payload: { error: "Invalid response from server" },
        });
      }
    } catch (error) {
      console.log("Error in sig in", error);
      dispatch({
        type: authConstants.LOGIN_FAILURE,
        payload: { error: error.response?.data?.message || "Unknown error" },
      });
    }
  };
};

// const savePushNotifyToken = async (token, jwtToken) => {
//   await axios
//     .post(`/pushNotifyToken`, { token: token })
//     .then((response) => {
//       // return response.data
//       // setPostLikeLoading(false);
//       console.log("Save Push Notification", response.data);

//       // socket.emit("chat", giftChatMessage);
//     })
//     .catch((error) => {
//       console.log("Error of save push notification", error);
//       // showMessage({
//       //   message: error?.response?.data?.error,
//       //   type: "danger",
//       // });
//     });
// };

// export const registerForPushNotificationsAsync = async (jwtToken) => {
//   console.log("hbhjbhjbhj");

//   let token;
//   if (Platform.OS === "android") {
//     await Notifications.setNotificationChannelAsync("default", {
//       name: "default",
//       importance: Notifications.AndroidImportance.MAX,
//       vibrationPattern: [0, 250, 250, 250],
//       lightColor: "#FF231F7C",
//     });
//   }

//   if (Device.isDevice) {
//     const { status: existingStatus } =
//       await Notifications.getPermissionsAsync();
//     let finalStatus = existingStatus;
//     if (existingStatus !== "granted") {
//       const { status } = await Notifications.requestPermissionsAsync();
//       finalStatus = status;
//     }
//     if (finalStatus !== "granted") {
//       alert("Failed to get push token for push notification!");
//       return;
//     }
//     token = (await Notifications.getExpoPushTokenAsync()).data;
//     // setToken(JSON.stringify(token));
//     console.log("Here is a generated Token: ", token);
//     AsyncStorage.setItem("pushNotifyToken", token);
//     savePushNotifyToken(token, jwtToken);
//   } else {
//     alert("Must use physical device for Push Notifications");
//   }

//   return token;
// };

export const isUserLoggedIn = () => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");

    if (token) {
      // const user = JSON.parse(AsyncStorage.getItem("user"));
      await axios
        .post(
          `${API}/verifyToken`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(async (res) => {
          const user = localStorage.getItem("user");
          dispatch({
            type: authConstants.LOGIN_SUCCESS,
            payload: {
              token,
              status: user.status,
              user: JSON.parse(user),
            },
          });
        })
        .catch((error) => {
          dispatch({
            type: authConstants.LOGIN_FAILURE,
            payload: { error: error },
          });
        });
    } else {
      dispatch({
        type: authConstants.LOGIN_FAILURE,
        payload: { error: "failed to login" },
      });
    }
  };
};

export const signout = () => {
  return async (dispatch) => {
    dispatch({ type: authConstants.LOGOUT_REQUEST });
    const pushNotifyToken = localStorage.getItem("pushNotifyToken");

    const res = await axios.post("/signout", {
      pushNotifyToken,
    });

    // console.log("Sign out response:", res.status);
    if (res.status === 200) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("selectedComponent");
      dispatch({
        type: authConstants.LOGOUT_SUCCESS,
      });
    } else {
      dispatch({
        type: authConstants.LOGOUT_FAILURE,
        payload: {
          error: res.data.error,
        },
      });
    }
  };
};
