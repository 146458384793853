import React, { useState } from "react";
import { Box, IconButton, Popover } from "@mui/material";
import DateRangePickerTst from "./DateRangePicker";
import { LuCalendarPlus } from "react-icons/lu";

const CustomDateRangePicker = ({
  startDate: initialStartDate,
  endDate: initialEndDate,
  onDateRangeChange,
  iconSize = 25,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  // State to handle selected start and end dates
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(initialEndDate || "");

  // Handle opening the popover
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Handle closing the popover
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "date-range-picker-popover" : undefined;

  // Update start and end dates when user selects new range
  const handleDateRangeChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
    onDateRangeChange(start, end);
  };

  return (
    <Box>
      {/* Calendar Icon to Open Date Range Picker */}
      <IconButton onClick={handleClick} {...props}>
        <LuCalendarPlus
          className=" text-black"
          style={{
            fontSize: "17px",
          }}
        />
      </IconButton>

      {/* Date Range Picker Popover */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        disableEnforceFocus
      >
        {/* Custom Date Range Picker Component */}
        <DateRangePickerTst
          startDate={startDate}
          endDate={endDate}
          onDateRangeChange={(start, end) => {
            handleDateRangeChange(start, end);
            if (start && end) {
              handleClose(); // Close the popover after selecting both start and end dates
            }
          }}
        />
      </Popover>
    </Box>
  );
};

export default CustomDateRangePicker;
