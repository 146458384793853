import React, { useState, useCallback, useEffect } from "react";
import {
  Paper,
  Grid,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  Box,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import { Download, Search } from "@mui/icons-material";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import formatDate, {
  API,
  errorAlert,
  formatCurrency,
  formatLetterDate,
  formatMonthDate,
  getFinancialYearDateRange,
  holidays,
  unBilledDesignations,
} from "../../utils/services";
import { filterTimeByUserIdForAdmin } from "../../actions/admin.time.action";
import { getAllEmployeesByAdminId, getAllMatters } from "../../actions";
import axiosInstance from "../../helpers/axios";
import CustomTooltip from "../../components/ui/CustomTootltip";
import CustomDateRangePicker from "../../components/ui/CustomDateRangePicker";
import { format } from "date-fns";

// Import Recharts components
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  LabelList,
} from "recharts";
import AdminDashboard from "../AdminDashboard";

const Financial = () => {
  // Constants
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const Matter = useSelector((state) => state.matter);
  const adminTime = useSelector((state) => state.adminTime);
  const adminEmployees = useSelector((state) => state.adminEmployees);

  const adminId = auth.user._id;

  const {
    workHours,
    writeoffHours,
    totalClockedWorkHoursAmount,
    pendingInvoicesTotal,
    collectedInvoicesTotal,
  } = adminTime.filteredWorkHour;

  const totalWorkhours = (workHours && workHours.concat(writeoffHours)) || [];

  const { filterLoading } = adminTime;
  const { matters } = Matter;
  const { employees } = adminEmployees;

  // States
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const [startSelectedDate, setStartSelectedDate] = useState(() => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const aprilFirst = new Date(currentYear, 3, 1); // Month is zero-based, so 3 represents April
    return formatDate(aprilFirst); // Adjust `formatDate` to match your desired format
  });

  const [endSelectedDate, setEndSelectedDate] = useState(
    formatDate(new Date())
  );
  const [leaves, setLeaves] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [companyData, setCompanyData] = useState({
    companyName: "",
    companyEmail: "",
    companyAddress: "",
    companyLogo: null,
    companyWebsite: "",
    companyAccountsEmail: "",
  });

  // States and functions for date range picker
  const [selectedDateRange, setSelectedDateRange] = useState({
    start: "",
    end: "",
  });

  const [expenses, setExpenses] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("client.name");

  // Sorting handler
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // Comparator functions
  function descendingComparator(a, b, orderBy) {
    const aValue = getNestedValue(a, orderBy);
    const bValue = getNestedValue(b, orderBy);

    if (bValue < aValue) {
      return -1;
    }
    if (bValue > aValue) {
      return 1;
    }
    return 0;
  }

  function getNestedValue(obj, path) {
    const value = path.split(".").reduce((o, p) => (o ? o[p] : ""), obj);
    return typeof value === "string" ? value.toLowerCase() : value;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedArray = array.map((el, index) => [el, index]);
    stabilizedArray.sort((a, b) => {
      const orderResult = comparator(a[0], b[0]);
      if (orderResult !== 0) return orderResult;
      return a[1] - b[1];
    });
    return stabilizedArray.map((el) => el[0]);
  }

  // Filter and sort matters
  const filteredMatters = matters.filter(
    (item) => item.billable === true && item.status === "enable"
  );

  const sortedMatters = stableSort(
    filteredMatters,
    getComparator(order, orderBy)
  );

  // Function to receive selected dates from DateRangePicker
  const handleDateRangeChange = (startDate, endDate) => {
    setSelectedDateRange({ start: startDate, end: endDate });
    setStartSelectedDate(startDate);
    setEndSelectedDate(endDate);
  };

  // Filtered Resources
  const uniqueNames =
    employees.length > 0
      ? employees
          .filter(
            (item) =>
              !unBilledDesignations.includes(item.designation) &&
              item.status === "active"
          )
          .map((item) => item?.fullName)
      : [];

  const filteredNames = workHours
    ? [...new Set(workHours.map((item) => item.user?.fullName))]
    : [];

  const commonNames = uniqueNames.filter((name) =>
    filteredNames.includes(name)
  );

  // Calculation of allMatterTotalBillableAmount
  let allMatterTotalBillableAmount = 0;
  let allMatterTotalBilledAmount = 0;
  let teamTotalDiscount = 0;
  let totalHoursClocked = 0;
  let totalDaysForRPReport = 0;

  const computeMatterwisePerformanceData = () => {
    let allMatterTotalBillableAmount = 0;
    let allMatterTotalBilledAmount = 0;
    let teamTotalDiscount = 0;
    let totalHoursClocked = 0;

    // First pass: Calculate total values
    sortedMatters.forEach((matter) => {
      let teamTotalBilledAmount = 0;
      let teamTotalBillableAmount = 0;
      let teamTotalHours = 0;

      commonNames.forEach((name) => {
        let personWorkHours = 0;
        let personWriteoffHours = 0;

        workHours
          .filter(
            (result) =>
              result.user?.fullName === name &&
              result.matter &&
              result.matter?.name === matter?.name
          )
          .forEach((result) => {
            const workHour = parseFloat(result.workHour);
            personWorkHours += workHour;
          });

        writeoffHours
          .filter(
            (result) =>
              result.user?.fullName === name &&
              result.matter &&
              result.matter?.name === matter?.name
          )
          .forEach((result) => {
            const workHour = parseFloat(result.discountedHours);
            personWriteoffHours += workHour;
          });

        const newMatter = matters.find(
          (matterItem) => matterItem?.name.trim() === matter?.name
        );

        let price = 0;
        if (newMatter) {
          if (newMatter.pricePerHour) {
            price = parseFloat(newMatter.pricePerHour.$numberDecimal);
          } else if (newMatter.resourceSpecificPrice.length !== 0) {
            const employeeEntry = newMatter.resourceSpecificPrice.find(
              (entry) => entry.includes(name)
            );
            if (employeeEntry) {
              const [, hours] = employeeEntry.split(":");
              price = parseFloat(hours.trim());
            }
          }
        }

        const personTotalBillableAmount =
          matter.currency === "USD"
            ? price * (personWorkHours + personWriteoffHours)
            : (price * (personWorkHours + personWriteoffHours)) / 80;
        const personTotalBilledAmount =
          matter.currency === "USD"
            ? price * personWorkHours
            : (price * personWorkHours) / 80;

        teamTotalHours += personWorkHours + personWriteoffHours;
        teamTotalBilledAmount += personTotalBilledAmount;
        teamTotalBillableAmount += personTotalBillableAmount;
      });

      allMatterTotalBillableAmount += teamTotalBillableAmount;
      allMatterTotalBilledAmount += teamTotalBilledAmount;
      teamTotalDiscount += teamTotalBillableAmount - teamTotalBilledAmount;
      totalHoursClocked += teamTotalHours;
    });

    // Second pass: Calculate each matter's data and contribution to total
    const data = sortedMatters.map((matter) => {
      let teamTotalHours = 0;
      let teamTotalBilledAmount = 0;
      let teamTotalBillableAmount = 0;

      commonNames.forEach((name) => {
        let personWorkHours = 0;
        let personWriteoffHours = 0;

        workHours
          .filter(
            (result) =>
              result.user?.fullName === name &&
              result.matter &&
              result.matter?.name === matter?.name
          )
          .forEach((result) => {
            const workHour = parseFloat(result.workHour);
            personWorkHours += workHour;
          });

        writeoffHours
          .filter(
            (result) =>
              result.user?.fullName === name &&
              result.matter &&
              result.matter?.name === matter?.name
          )
          .forEach((result) => {
            const workHour = parseFloat(result.discountedHours);
            personWriteoffHours += workHour;
          });

        const newMatter = matters.find(
          (matterItem) => matterItem?.name.trim() === matter?.name
        );

        let price = 0;
        if (newMatter) {
          if (newMatter.pricePerHour) {
            price = parseFloat(newMatter.pricePerHour.$numberDecimal);
          } else if (newMatter.resourceSpecificPrice.length !== 0) {
            const employeeEntry = newMatter.resourceSpecificPrice.find(
              (entry) => entry.includes(name)
            );
            if (employeeEntry) {
              const [, hours] = employeeEntry.split(":");
              price = parseFloat(hours.trim());
            }
          }
        }

        const personTotalBillableAmount =
          matter.currency === "USD"
            ? price * (personWorkHours + personWriteoffHours)
            : (price * (personWorkHours + personWriteoffHours)) / 80;
        const personTotalBilledAmount =
          matter.currency === "USD"
            ? price * personWorkHours
            : (price * personWorkHours) / 80;

        teamTotalHours += personWorkHours + personWriteoffHours;
        teamTotalBilledAmount += personTotalBilledAmount;
        teamTotalBillableAmount += personTotalBillableAmount;
      });

      return {
        client: matter?.client?.name,
        matter: matter?.name,
        actualTimeClocked: teamTotalBillableAmount,
        timeBilled: teamTotalBilledAmount,
        discount: teamTotalBillableAmount - teamTotalBilledAmount,
        discountPercentage:
          teamTotalBillableAmount !== 0
            ? parseFloat(
                (
                  ((teamTotalBillableAmount - teamTotalBilledAmount) /
                    teamTotalBillableAmount) *
                  100
                ).toFixed(2)
              )
            : 0.0,
        contributionToTotal:
          allMatterTotalBilledAmount !== 0
            ? parseFloat(
                (
                  (teamTotalBilledAmount * 100) /
                  allMatterTotalBilledAmount
                ).toFixed(2)
              )
            : 0.0,
        hoursClocked: teamTotalHours,
        erph:
          teamTotalHours !== 0
            ? parseFloat((teamTotalBilledAmount / teamTotalHours).toFixed(2))
            : 0.0,
      };
    });

    return data;
  };

  const computeEfficiencyUtilizationData = () => {
    const data = [];
    let totalBillableHours = 0;
    let totalBilledTime = 0;
    let totalNonBilledTime = 0;
    let leaveDays;
    let finalDays;

    const currentDate = new Date(startSelectedDate);
    const endDate = new Date(endSelectedDate);
    let totalDays = 0;

    while (currentDate <= endDate) {
      const dayOfWeek = currentDate.getDay(); // 0 (Sunday) to 6 (Saturday)
      totalDays++;
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        totalDaysForRPReport++;
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    const sortedData = [];

    if (commonNames.length > 0) {
      commonNames.forEach((name, index) => {
        const personWorkHours =
          workHours &&
          workHours.filter(
            (result) =>
              result.user?.fullName === name &&
              result.workDate >= startSelectedDate &&
              result.workDate <= endSelectedDate
          );

        // personWriteoffHours
        const personWriteoffEntries = writeoffHours.filter(
          (result) =>
            result.user?.fullName === name &&
            result.workDate >= startSelectedDate &&
            result.workDate <= endSelectedDate
        );

        const personWriteoffHours =
          personWriteoffEntries.reduce(
            (total, result) => total + parseFloat(result.discountedHours),
            0
          ) || 0.0;

        // personBillableTime
        const personBillableEntries = personWorkHours.filter(
          (item) => item.billable === true
        );

        const personBillableTime =
          personBillableEntries.reduce(
            (total, item) => total + parseFloat(item.workHour),
            0
          ) || 0.0;

        // personNonBillableTime
        const personNonBillableEntries = personWorkHours.filter(
          (item) => item.billable === false
        );

        const personNonBillableTime =
          personNonBillableEntries.reduce(
            (total, item) => total + parseFloat(item.workHour),
            0
          ) || 0.0;

        // days calcs
        leaveDays = leaves
          .filter(
            (leave) =>
              leave.user.fullName === name &&
              leave.leaveStartDate >= startSelectedDate &&
              leave.leaveEndDate <= endSelectedDate
          )
          .reduce((total, item) => (total += parseFloat(item.leaveDays)), 0);

        const holidaysWithinLeavePeriod = holidays.filter((holiday) => {
          return holiday >= startSelectedDate && holiday <= endSelectedDate;
        });

        const holidayDays = holidaysWithinLeavePeriod.length;
        const totalLeaveDaysWithHolidays = leaveDays + holidayDays;

        finalDays = totalDaysForRPReport - totalLeaveDaysWithHolidays;

        // console.log(
        //   "final days",
        //   name,
        //   finalDays,
        //   totalDaysForRPReport,
        //   totalLeaveDaysWithHolidays,
        //   leaves.filter(
        //     (leave) =>
        //       leave.user.fullName === name &&
        //       leave.leaveStartDate >= startSelectedDate &&
        //       leave.leaveEndDate <= endSelectedDate
        //   ),
        //   leaveDays,
        //   holidayDays
        // );

        const totalAvailableBillableHours = 7 * finalDays;

        const billableHoursUtilized = parseFloat(
          (personBillableTime + personWriteoffHours).toFixed(2)
        );

        const actualBilled = parseFloat(personBillableTime.toFixed(2));

        const discounting =
          personBillableTime + personWriteoffHours !== 0
            ? parseFloat(
                (
                  (1 -
                    personBillableTime /
                      (personBillableTime + personWriteoffHours)) *
                  100
                ).toFixed(2)
              )
            : 0.0;

        const avgBillableHrsPerDay = parseFloat(
          ((personBillableTime + personWriteoffHours) / finalDays).toFixed(2)
        );

        const avgBilledHrsPerDay = parseFloat(
          (personBillableTime / finalDays).toFixed(2)
        );

        const availNonBillableHours = 2 * finalDays;

        const nonBillableHoursUtilized = parseFloat(
          personNonBillableTime.toFixed(2)
        );

        const missedBillingPotential = parseFloat(
          (
            (1 - personBillableTime / totalAvailableBillableHours) *
            100
          ).toFixed(2)
        );

        const tableDataArray = {
          resource: name,
          noOfDaysWorked: finalDays,
          availBillableHours: totalAvailableBillableHours,
          billableHoursUtilized: billableHoursUtilized,
          actualBilled: actualBilled,
          discounting: discounting,
          avgBillableHrsPerDay: avgBillableHrsPerDay,
          avgBilledHrsPerDay: avgBilledHrsPerDay,
          availNonBillableHours: availNonBillableHours,
          nonBillableHoursUtilized: nonBillableHoursUtilized,
          missedBillingPotential: missedBillingPotential,
        };

        totalBillableHours += billableHoursUtilized;

        totalBilledTime += actualBilled;
        totalNonBilledTime += nonBillableHoursUtilized;

        const rowData = {
          data: tableDataArray,
          value: avgBillableHrsPerDay,
        };

        sortedData.push(rowData);
      });
    }

    sortedData.sort((a, b) => b.value - a.value);

    sortedData.forEach((rowData) => {
      data.push(rowData.data);
    });

    // Add total row
    data.push({
      resource: "Total Hours",
      noOfDaysWorked: "",
      availBillableHours: commonNames.length * 7 * totalDaysForRPReport,
      billableHoursUtilized: totalBillableHours.toFixed(2),
      actualBilled: totalBilledTime.toFixed(2),
      discounting:
        totalBillableHours !== 0
          ? parseFloat(
              ((1 - totalBilledTime / totalBillableHours) * 100).toFixed(2)
            )
          : 0.0,
      avgBillableHrsPerDay: "",
      avgBilledHrsPerDay: "",
      availNonBillableHours: commonNames.length * 2 * totalDaysForRPReport,
      nonBillableHoursUtilized: totalNonBilledTime,
      missedBillingPotential: "",
    });

    return data;
  };

  // GET
  const fetchAllInvoices = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`${API}/invoices`);

      // Update state with fetched invoices
      setInvoices(response.data.invoices);
    } catch (error) {
      errorAlert("Error fetching Invoices. Try again...");
    }
  }, []);

  const fetchAllLeaves = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`${API}/leaves`, {
        params: {
          adminId: auth.user._id,
          userId: "",
          startSelectedDate,
          endSelectedDate,
          limit: 0,
          page: 0,
        },
      });

      setLeaves(response.data.leavesWithDays);
    } catch (error) {
      errorAlert("Error fetching leaves. Try again...");
    }
  }, [auth.user._id, endSelectedDate, startSelectedDate]);

  const fetchCompanyDetails = useCallback(async () => {
    try {
      const response = await axiosInstance.get(
        `${API}/company/details/${adminId}`
      );

      setCompanyData({
        ...companyData,
        companyName: response.data.companyName || "",
        companyEmail: response.data.companyEmail || "",
        companyAddress: response.data.companyAddress || "",
        companyLogo: response.data.companyLogo || "",
        companyWebsite: response.data.companyWebsite || "",
        companyAccountsEmail: response.data.companyAccountsEmail || "",
      });
    } catch (error) {
      errorAlert("Error in fetching company details!!");
    }
  }, [adminId, companyData]);

  const fetchAllopees = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`${API}/expense/admin`, {
        params: {
          user: "",
          fromDate: startSelectedDate, // Format the date as 'YYYY-MM-DD'
          toDate: endSelectedDate,
          matterId: "",
          adminId,
          limit: 0,
          page: 0,
        },
      });

      if (response.data) {
        setExpenses(response.data.expenses);
      }
    } catch (error) {
      errorAlert("Error in fetching OPE data");
      console.error("Error in fetching OPE data", error);
    }
  }, [adminId, endSelectedDate, startSelectedDate]);

  // Filter function and Validation
  const isValidObjFieldForFilters = useCallback(() => {
    if (startSelectedDate === "" || endSelectedDate === "") {
      return false;
    } else {
      return true;
    }
  }, [endSelectedDate, startSelectedDate]);

  const isValidFilters = useCallback(() => {
    // All fields must be filled
    if (!isValidObjFieldForFilters()) return errorAlert("Enter the dates!!");

    return true;
  }, [isValidObjFieldForFilters]);

  const filterBetweenDates = useCallback(() => {
    if (isValidFilters()) {
      const rowsPerPage = 0;
      const page = 0;
      dispatch(
        filterTimeByUserIdForAdmin(
          "",
          startSelectedDate,
          endSelectedDate,
          "",
          true,
          false,
          adminId,
          rowsPerPage,
          page
        )
      );

      dispatch(getAllMatters(auth.user._id));
      dispatch(getAllEmployeesByAdminId());
      fetchAllInvoices();
      fetchAllLeaves();
      fetchCompanyDetails();
      fetchAllopees();
    }
  }, [
    isValidFilters,
    dispatch,
    startSelectedDate,
    endSelectedDate,
    adminId,
    auth.user._id,
    fetchAllLeaves,
    fetchCompanyDetails,
    fetchAllInvoices,
    fetchAllopees,
  ]);

  useEffect(() => filterBetweenDates(), []);

  // Additional state for top N selection
  const [topN, setTopN] = useState(10);

  const handleTopNChange = (event) => {
    setTopN(event.target.value);
  };

  // Prepare data for charts with grouping
  const MATTER_THRESHOLD = topN; // Use selected top N matters

  const matterPerformanceData = computeMatterwisePerformanceData();

  // Sort matters by contribution descending
  const sortedMatterData = [...matterPerformanceData].sort(
    (a, b) => b.contributionToTotal - a.contributionToTotal
  );

  // Get top N matters
  const topMatters = sortedMatterData.slice(0, MATTER_THRESHOLD);

  // Calculate "Others"
  const otherMatters = sortedMatterData.slice(MATTER_THRESHOLD);

  const othersContribution = otherMatters.reduce(
    (acc, curr) => acc + curr.contributionToTotal,
    0
  );

  // Prepare data for chart
  const matterChartData = [
    ...topMatters,
    ...(othersContribution > 0
      ? [
          {
            matter: "Others",
            contributionToTotal: othersContribution,
          },
        ]
      : []),
  ];

  // Similar preparation for resource chart
  const RESOURCE_THRESHOLD = topN;

  const resourcePerformanceData = computeEfficiencyUtilizationData().filter(
    (item) => item.resource !== "Total Hours"
  );

  const sortedResourceData = [...resourcePerformanceData].sort(
    (a, b) => b.billableHoursUtilized - a.billableHoursUtilized
  );

  const topResources = sortedResourceData.slice(0, RESOURCE_THRESHOLD);

  const otherResources = sortedResourceData.slice(RESOURCE_THRESHOLD);
  const othersBillableHours = otherResources.reduce(
    (acc, curr) => acc + curr.billableHoursUtilized,
    0
  );

  const resourceChartData = [
    ...topResources,
    ...(othersBillableHours > 0
      ? [
          {
            resource: "Others",
            billableHoursUtilized: othersBillableHours,
          },
        ]
      : []),
  ];

  // Formatting dates
  const formattedMonthStartDate = formatMonthDate(startSelectedDate);
  const formattedMonthEndDate = formatMonthDate(endSelectedDate);

  const computeCollectedInvoicesData = () => {
    const data = [];
    if (invoices.length > 0) {
      invoices
        .filter((invoice) => invoice.status === "collected")

        .forEach((invoice) => {
          data.push({
            raisedDate: formatMonthDate(invoice.invoiceDate),
            invoiceNumber: invoice.invoiceNumber,
            invoicePeriod: `${formatMonthDate(
              invoice.startDate
            )} - ${formatMonthDate(invoice.endDate)}`,
            matter: invoice.matter.name,
            collectionDate: formatMonthDate(invoice.collectionDate),
            amount: parseFloat(invoice.amount.$numberDecimal),
          });
        });
    }
    return data;
  };

  const computePendingInvoicesData = () => {
    const data = [];
    if (invoices.length > 0) {
      invoices
        .filter((invoice) => invoice.status === "pending")
        .forEach((invoice) => {
          data.push({
            raisedDate: formatMonthDate(invoice.invoiceDate),
            invoiceNumber: invoice.invoiceNumber,
            invoicePeriod: `${formatMonthDate(
              invoice.startDate
            )} - ${formatMonthDate(invoice.endDate)}`,
            matter: invoice.matter.name,
            amount: parseFloat(invoice.amount.$numberDecimal),
          });
        });
    }
    return data;
  };

  const totalInvoiceAmounts = [
    { name: "Collected", value: collectedInvoicesTotal || 0 },
    { name: "Pending", value: pendingInvoicesTotal || 0 },
  ];

  const COLORS2 = [
    "#6C63FF", // Lavender blue
    "#BFD7EA", // Soft blue
    "#FFC107", // Soft amber
    "#8DC3A7", // Soft teal green
    "#FF8C94", // Soft coral
    "#4E5D6C", // Muted dark blue-gray
    "#6C757D", // Soft gray
    "#ADB5BD", // Light gray
    "#17A2B8", // Light cyan
    "#E9ECEF", // Off-white gray
    "#A9A9A9", // Dark gray
    "#FFD700", // Gold
  ];

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  }

  const clockedHoursByMonth = totalWorkhours.reduce((acc, entry) => {
    const workDate = new Date(entry.workDate);
    const monthKey = format(workDate, "MMM yyyy"); // E.g., 'Apr 2023'

    // Initialize the month key if it doesn't exist
    if (!acc[monthKey]) {
      acc[monthKey] = 0;
    }

    // Add the work hours to the month's total
    acc[monthKey] += parseFloat(entry.workHour);

    return acc;
  }, {});

  // Process expenses data
  const expensesByMonth = expenses.reduce((acc, expense) => {
    const expenseDate = new Date(expense.expenseDate);
    const monthKey = format(expenseDate, "MMM yyyy"); // E.g., 'Apr 2023'

    // Convert amount to INR if necessary
    let amount = parseFloat(expense.amount.$numberDecimal);
    if (expense.currency === "USD") {
      amount *= 80; // Adjust conversion rate as needed
    }

    // Accumulate amounts by month
    if (acc[monthKey]) {
      acc[monthKey] += amount;
    } else {
      acc[monthKey] = amount;
    }

    return acc;
  }, {});

  // Convert the expensesByMonth object into an array and sort it
  const expensesChartData = Object.entries(expensesByMonth)
    .map(([month, amount]) => {
      const date = new Date(month + " 1"); // Create a date object for sorting
      return { month, amount, date };
    })
    .sort((a, b) => a.date - b.date);

  // Prepare the clockedHoursByMonth data into a format suitable for the chart
  const clockedHoursChartData = Object.entries(clockedHoursByMonth)
    .map(([month, hours]) => ({
      month,
      hours,
    }))
    .reverse();

  //constants for pdf
  const link1Text = companyData.companyAccountsEmail;
  const link1URL = `mailto:${companyData.companyAccountsEmail}`;
  const link2Text = companyData.companyWebsite;
  const link2URL = companyData.companyWebsite;

  // Function to generate PDF (if needed)
  const generateFinancialReport = () => {
    const doc = new jsPDF({
      orientation: "landscape",
    });

    const formattedStartDate = formatLetterDate(startSelectedDate);
    const formattedEndDate = formatLetterDate(endSelectedDate);

    const formattedMonthStartDate = formatMonthDate(startSelectedDate);
    const formattedMonthEndDate = formatMonthDate(endSelectedDate);

    const dateRangeLength =
      (297 - doc.getTextWidth(`${formattedStartDate} - ${formattedEndDate}`)) /
      2;

    //Header fixed for every new page
    const pageWidth = doc.internal.pageSize.getWidth();
    const rightMargin = 15; // You can adjust the right margin as needed
    const logoDesiredHeight = 20; // Set the desired height for the logo
    const logoYPosition = 10; // Set the Y position for the logo
    const logoXPosition = 13; // Set the X position for the logo

    function addHeader() {
      // Set the font style, size, and position of the header text
      const fontSize = 8;

      doc.setFont("helvetica", "normal");
      doc.setFontSize(fontSize);

      // Add the image to the header
      // doc.addImage(companyData.companyLogo, "PNG", 15, 10, 70, 9);
      doc.addImage(
        companyData.companyLogo,
        "PNG",
        logoXPosition,
        logoYPosition,
        0,
        logoDesiredHeight
      );

      // right header
      doc.setTextColor(46, 115, 176);

      const link2Width = doc.getTextWidth(link2Text);
      const link2X = pageWidth - link2Width - rightMargin;
      doc.textWithLink(link2Text, link2X, 19, {
        url: link2URL,
        underline: true,
      });

      // Set link color to blue and add underline
      const link1Width = doc.getTextWidth(link1Text);
      const link1X = pageWidth - link1Width - rightMargin;
      doc.textWithLink(link1Text, link1X, 22, {
        url: link1URL,
        underline: true,
      });

      doc.setTextColor(0);

      // Calculate text width and position to align it right
      const addressWidth = doc.getTextWidth(companyData.companyAddress);
      const addressX = pageWidth - addressWidth - rightMargin;
      doc.text(companyData.companyAddress, addressX, 26);

      // Reset text color to black
    }

    const FinancialReport = `/assets/FinancialReport.jpg`;

    doc.addImage(FinancialReport, "JPEG", 0, 0, 297, 210);
    addHeader();

    const fontSize = 20;
    doc.setFontSize(fontSize);

    // date range
    doc.setFont("helvetica", "italic");
    doc.setTextColor(0, 0, 0); // Black color
    doc.setFontSize(15);
    doc.text(
      `${formattedStartDate} - ${formattedEndDate}`,
      dateRangeLength,
      100
    );

    //horizontal line
    doc.setLineWidth(0.3);
    doc.setDrawColor(136, 106, 71);
    doc.line(90, 110, 200, 110);

    //-------------------------3rd Page -- Matterwise Peformance Report---------------------

    doc.addPage();
    addHeader();

    doc.setFont("helvetica", "bold");
    doc.setTextColor(136, 106, 71);
    doc.setFontSize(15);
    doc.text(
      `Matterwise Performance Report (${formattedMonthStartDate} - ${formattedMonthEndDate})`,
      (297 -
        doc.getTextWidth(
          `Matterwise Performance Report (${formattedMonthStartDate} - ${formattedMonthEndDate})`
        )) /
        2,
      40
    );
    doc.setTextColor(0, 0, 0);
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);

    //Table Data 1
    const matterTableColumns = [
      "Client",
      "Matter",
      "Clocked Hours",
      "Clocked Amount (USD)",
      "Billed Amount (USD)",
      // "Discount",
      "Discount %",
      "Contribution To Total %",
      // "ERPH",
    ];
    const matterTableData = [];

    if (sortedMatters.length > 0) {
      // console.log(sortedMatters);
      sortedMatters.forEach((matter, index) => {
        let currency;

        if (matter) {
          currency = matter.currency === "USD" ? "$" : "Rs. ";
          // console.log("Currency: ", currency);
          // Use the currency value as needed
        } else {
          currency = "";
          // console.log("Matter not found.");
        }

        let matterTotalBilledAmount = 0;
        let persontotalHours = 0;
        let teamTotalHours = 0;
        let teamTotalBilledHours = 0;
        let teamTotalBilledAmount = 0;
        let teamTotalBillableAmount = 0;

        commonNames.forEach((name) => {
          let personWorkHours = 0;
          let personWriteoffHours = 0;

          workHours
            .filter(
              (result) =>
                result.user?.fullName === name &&
                result.matter?.name === matter?.name &&
                result.workDate >= startSelectedDate && // Add start date filter
                result.workDate <= endSelectedDate // Add end date filter
            )
            .forEach((result) => {
              const workHour = parseFloat(result.workHour);
              personWorkHours += workHour;
            });

          writeoffHours
            .filter(
              (result) =>
                result.user?.fullName === name &&
                result.matter?.name === matter?.name &&
                result.workDate >= startSelectedDate &&
                result.workDate <= endSelectedDate
            )
            .forEach((result) => {
              const workHour = parseFloat(result.discountedHours);
              personWriteoffHours += workHour;
            });

          persontotalHours =
            parseFloat(personWorkHours) + parseFloat(personWriteoffHours);

          const newMatter = matters.find(
            (matterItem) => matterItem?.name.trim() === matter?.name.trim()
          );

          let price;
          if (newMatter) {
            if (newMatter.pricePerHour) {
              price = newMatter.pricePerHour.$numberDecimal;
            } else if (newMatter.resourceSpecificPrice.length !== 0) {
              const employeeEntry = newMatter.resourceSpecificPrice.find(
                (entry) => entry.includes(name)
              );

              if (employeeEntry) {
                const [, hours] = employeeEntry.split(":");
                price = hours.trim();
              }
            } else {
              price = 0;
            }
          }

          // console.log("Price ---------", price, persontotalHours);

          if (price) {
            const personTotalBilledAmount =
              matter.currency === "USD"
                ? price * personWorkHours
                : (price * personWorkHours) / 80;
            const personTotalBillableAmount =
              matter.currency === "USD"
                ? price * (personWorkHours + personWriteoffHours)
                : (price * (personWorkHours + personWriteoffHours)) / 80;
            teamTotalHours += persontotalHours;
            teamTotalBilledHours += personWorkHours;
            teamTotalBillableAmount += personTotalBillableAmount;
            teamTotalBilledAmount += personTotalBilledAmount;
          }
        });

        const matterTableDataArray = [
          matter?.client?.name,
          matter?.name,
          teamTotalHours.toFixed(2),
          teamTotalBillableAmount
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          teamTotalBilledAmount
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          // (teamTotalBillableAmount - teamTotalBilledAmount)
          //   .toFixed(2)
          //   .toString()
          //   .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          ((
            ((teamTotalBillableAmount - teamTotalBilledAmount) /
              teamTotalBillableAmount) *
            100
          ).toFixed(2) !== "NaN"
            ? (
                ((teamTotalBillableAmount - teamTotalBilledAmount) /
                  teamTotalBillableAmount) *
                100
              ).toFixed(2)
            : (0).toFixed(2)) + "%",
          ((teamTotalBilledAmount * 100) / allMatterTotalBilledAmount).toFixed(
            2
          ) + "%",
          (teamTotalBilledAmount / teamTotalHours).toFixed(2) !== "NaN"
            ? (teamTotalBilledAmount / teamTotalHours).toFixed(2)
            : "0.00",
        ];

        matterTableData.push(matterTableDataArray);
      });
    }

    const matterFooterRow = [
      {
        content: "Total Amount  (billed in USD)",
        colSpan: 2,
        styles: {
          fillColor: [97, 68, 58],
          textColor: [255, 255, 255],
          fontStyle: "bold",
        },
      },
      {
        content: allMatterTotalBillableAmount
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        // colSpan: 3,
        styles: {
          fillColor: [97, 68, 58],
          textColor: [255, 255, 255],
          fontStyle: "bold",
        },
      },
      {
        content: allMatterTotalBilledAmount
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        // colSpan: 3,
        styles: {
          fillColor: [97, 68, 58],
          textColor: [255, 255, 255],
          fontStyle: "bold",
        },
      },
    ];

    const matterFooterRow1 = [
      {
        content: "Total Amount (billed in INR)",
        colSpan: 2,
        styles: {
          fillColor: [97, 68, 58],
          textColor: [255, 255, 255],
          fontStyle: "bold",
        },
      },
      (allMatterTotalBillableAmount * 80)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      (allMatterTotalBilledAmount * 80)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      teamTotalDiscount
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      "",
      "",
      totalHoursClocked.toFixed(2),
    ];

    matterTableData.push(matterFooterRow);
    matterTableData.push(matterFooterRow1);

    doc.autoTable(matterTableColumns, matterTableData, {
      startY: 50,
      theme: "grid", // Set the theme to 'grid' to display internal lines
      tableLineColor: [0, 0, 0], // Color of the table lines (black in this example)
      tableLineWidth: 0.1, // Width of the table lines in units (default is 0.1)
      didParseCell: function (data) {
        if (data.row.index === 0 && data.row.section === "head") {
          data.cell.styles.fillColor = [136, 106, 71];
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
        }
        if (
          data.row.index === data.table.body.length - 1 &&
          data.row.section === "body"
        ) {
          data.cell.styles.fillColor = [97, 68, 58];
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.fontStyle = "bold";
        }
        if (data.row.section === "body") {
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
        }
        if (
          data.row.section === "body" &&
          data.column.index !== 0 &&
          data.column.index !== 1
        ) {
          data.cell.styles.halign = "right";
          data.cell.styles.cellWidth = 27;
        }
        if (data.column.index === 1 && data.column.index === 0) {
          data.cell.styles.cellWidth = 80;
        }
      },
    });

    // Table Data 2 - Comparison Table

    //-------------------------4th Page -- Resourcewise Performance Report--------------------
    doc.addPage();
    addHeader();

    doc.setFont("helvetica", "bold");
    doc.setTextColor(136, 106, 71);
    doc.setFontSize(15);
    doc.text(
      `Efficiency and Utilization Report (${formattedMonthStartDate} - ${formattedMonthEndDate})`,
      (297 -
        doc.getTextWidth(
          `Efficiency and Utilization Report (${formattedMonthStartDate} - ${formattedMonthEndDate})`
        )) /
        2,
      40
    );
    doc.setTextColor(0, 0, 0);
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);

    const resourceTableColumns = [
      "Resource",
      "No. of days worked",
      "Avail Billable Hours",
      "Billable Hours Utilized",
      "Actual Billed",
      "Discounting",
      "Avg Billable hrs/day",
      "Avg Billed hrs/day",
      "Avail Non-Billable Hours",
      "Non-Billable Hours Utilized",
      "Missed Billing Potential(7 hrs/day)",
    ];

    const resourceTableData = [];

    let totalBillableHours = 0;
    let totalBilledTime = 0;
    let totalNonBilledTime = 0;
    let totalDiscount = 0;
    const sortedData = [];
    let leaveDays;
    let finalDays;

    if (commonNames.length > 0) {
      commonNames.forEach((name, index) => {
        const personWorkHours =
          workHours &&
          workHours.filter(
            (result) =>
              result.user?.fullName === name &&
              result.workDate >= startSelectedDate &&
              result.workDate <= endSelectedDate
          );

        //personWriteoffHours
        const personWriteoffEntries = writeoffHours.filter(
          (result) =>
            result.user?.fullName === name &&
            result.workDate >= startSelectedDate &&
            result.workDate <= endSelectedDate
        );

        const personWriteoffHours =
          personWriteoffEntries.reduce(
            (total, result) => total + parseFloat(result.discountedHours),
            0
          ) || 0.0;

        //personBillableTime
        const personBillableEntries = personWorkHours.filter(
          (item) => item.billable === true
        );

        const personBillableTime =
          personBillableEntries.reduce(
            (total, item) => total + parseFloat(item.workHour),
            0
          ) || 0.0;

        //personNonBillableTime

        const personNonBillableEntries = personWorkHours.filter(
          (item) => item.matter?.pricePerHour?.$numberDecimal === "0"
        );

        const personNonBillableTime =
          personNonBillableEntries.reduce(
            (total, item) => total + parseFloat(item.workHour),
            0
          ) || 0.0;

        //days calcs
        leaveDays = leaves
          .filter(
            (leave) =>
              leave.user.fullName === name &&
              leave.leaveStartDate >= startSelectedDate &&
              leave.leaveEndDate <= endSelectedDate
          )
          .reduce((total, item) => (total += parseFloat(item.leaveDays)), 0);

        // console.log("Leave Days", name, leaveDays);

        const holidaysWithinLeavePeriod = holidays.filter((holiday) => {
          return holiday >= startSelectedDate && holiday <= endSelectedDate;
        });

        const holidayDays = holidaysWithinLeavePeriod.length;
        const totalLeaveDaysWithHolidays = leaveDays + holidayDays;

        finalDays = totalDaysForRPReport - totalLeaveDaysWithHolidays;

        const tableDataArray = [
          name,
          finalDays,
          7 * finalDays,
          parseFloat(personBillableTime + personWriteoffHours).toFixed(2),
          parseFloat(personBillableTime).toFixed(2),
          ((
            (1 -
              personBillableTime / (personBillableTime + personWriteoffHours)) *
            100
          ).toFixed(2) !== "NaN"
            ? (
                (1 -
                  personBillableTime /
                    (personBillableTime + personWriteoffHours)) *
                100
              ).toFixed(2)
            : 0.0) + "%",
          parseFloat(
            (personBillableTime + personWriteoffHours) / finalDays
          ).toFixed(2),
          parseFloat(personBillableTime / finalDays).toFixed(2),
          2 * finalDays,
          personNonBillableTime.toFixed(2),
          parseFloat((1 - personBillableTime / (7 * finalDays)) * 100).toFixed(
            1
          ) + "%",
        ];

        totalBillableHours += parseFloat(
          personBillableTime + personWriteoffHours
        );

        totalBilledTime += parseFloat(personBillableTime);
        totalNonBilledTime += parseFloat(personNonBillableTime.toFixed(2));

        const rowData = {
          data: tableDataArray,
          value: parseFloat(
            (personBillableTime + personWriteoffHours) / finalDays
          ).toFixed(2),
        };

        sortedData.push(rowData);
      });
    }

    sortedData.sort((a, b) => b.value - a.value);

    sortedData.forEach((rowData) => {
      resourceTableData.push(rowData.data);
    });

    const resourceFooterRow = [
      "Total Hours",
      "",
      commonNames.length * 7 * finalDays,
      totalBillableHours.toFixed(2),
      totalBilledTime.toFixed(2),
      parseFloat(
        ((1 - totalBilledTime / totalBillableHours) * 100).toFixed(2)
      ) + "%",
      "",
      "",
      commonNames.length * 2 * finalDays,
      totalNonBilledTime.toFixed(2),
      "",
    ];

    resourceTableData.push(resourceFooterRow);

    //Efficiency and Utilization Table
    doc.autoTable(resourceTableColumns, resourceTableData, {
      startY: 50,
      theme: "grid", // Set the theme to 'grid' to display internal lines
      tableLineColor: [0, 0, 0], // Color of the table lines (black in this example)
      tableLineWidth: 0.1, // Width of the table lines in units (default is 0.1)
      didParseCell: function (data) {
        if (data.row.index === 0 && data.row.section === "head") {
          data.cell.styles.fillColor = [136, 106, 71];
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
        }
        if (
          data.row.index === data.table.body.length - 1 &&
          data.row.section === "body"
        ) {
          data.cell.styles.fillColor = [97, 68, 58];
          data.cell.styles.textColor = [255, 255, 255];
        }
        if (data.row.section === "body") {
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
          data.cell.styles.halign = "right";
          data.cell.styles.cellWidth = 23.5; // Set the width to 50 units
        }
        if (data.column.index === 0) {
          data.cell.styles.halign = "left";
          data.cell.styles.cellWidth = 33.7; // Set the width to 50 units
        }
      },
    });

    //-----------------------7th Page -- Invoices Tracker complete with reminders--------------
    doc.addPage();
    addHeader();

    //COMMON IN BOTH PENDING AND COLLECTED INVOICES

    //COLLECTED INVOICES DATA
    const invoiceCollectedTableColumns = [
      "Raised Date",
      "Invoice No.",
      "Invoice Period",
      "Matter",
      "Collection Date",
      "Amount",
    ];

    doc.setFont("helvetica", "bold");
    doc.setTextColor(136, 106, 71);
    doc.setFontSize(15);
    doc.text(
      `Collections in this period (${formattedMonthStartDate} - ${formattedMonthEndDate})`,
      (297 -
        doc.getTextWidth(
          `Collections in this period (${formattedMonthStartDate} - ${formattedMonthEndDate})`
        )) /
        2,
      40
    );

    const invoiceCollectedTableData = [];

    if (invoices.length > 0) {
      invoices
        .filter((invoice) => invoice.status === "collected")

        .forEach((invoice) => {
          const invoiceRowData = [
            formatMonthDate(invoice.invoiceDate),
            invoice.invoiceNumber,
            `${formatMonthDate(invoice.startDate)} - ${formatMonthDate(
              invoice.endDate
            )}`,
            invoice.matter.name,
            formatMonthDate(invoice.collectionDate),
            "INR " +
              parseFloat(invoice.amount.$numberDecimal)
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          ];
          invoiceCollectedTableData.push(invoiceRowData);
        });
    }

    const invoiceCollectedFooterRow = [
      {
        content: "Total Collected Amount",
        colSpan: 5,
      },
      "INR " +
        invoices
          .filter((invoice) => invoice.status === "collected")
          .filter(
            (invoice) =>
              invoice.collectionDate >= startSelectedDate &&
              invoice.collectionDate <= endSelectedDate
          )
          .reduce(
            (total, invoice) =>
              parseFloat(invoice.amount.$numberDecimal) + total,
            0
          )
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    ];

    invoiceCollectedTableData.push(invoiceCollectedFooterRow);

    doc.autoTable(invoiceCollectedTableColumns, invoiceCollectedTableData, {
      startY: 50,
      theme: "grid", // Set the theme to 'grid' to display internal lines
      tableLineColor: [0, 0, 0], // Color of the table lines (black in this example)
      tableLineWidth: 0.1, // Width of the table lines in units (default is 0.1)
      didParseCell: function (data) {
        if (data.row.index === 0 && data.row.section === "head") {
          data.cell.styles.fillColor = [136, 106, 71];
          data.cell.styles.textColor = [255, 255, 255];
        }
        if (
          data.row.index === data.table.body.length - 1 &&
          data.row.section === "body"
        ) {
          data.cell.styles.fillColor = [97, 68, 58];
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.fontStyle = "bold";
        }
        if (data.row.section === "body" && data.column.index === 5) {
          data.cell.styles.halign = "right";
          data.cell.styles.cellWidth = 35; // Set the width to 50 units
        }
      },
    });

    //PENDING INVOICES DATA
    doc.text(
      `Pending Invoices Tracker (${formattedMonthStartDate} - ${formattedMonthEndDate})`,
      (297 -
        doc.getTextWidth(
          `Invoices Tracker (${formattedMonthStartDate} - ${formattedMonthEndDate})`
        )) /
        2,
      doc.lastAutoTable.finalY + 20
    );

    doc.setTextColor(0, 0, 0);
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);

    const invoicePendingTableData = [];
    const invoicePendingTableColumns = [
      "Raised Date",
      "Invoice No.",
      "Invoice Period",
      "Matter",
      "Amount (INR)",
    ];

    if (invoices.length > 0) {
      invoices
        .filter((invoice) => invoice.status === "pending")
        .forEach((invoice) => {
          const invoiceRowData = [
            formatMonthDate(invoice.invoiceDate),
            invoice.invoiceNumber,
            `${formatMonthDate(invoice.startDate)} - ${formatMonthDate(
              invoice.endDate
            )}`,
            invoice.matter.name,
            parseFloat(invoice.amount.$numberDecimal)
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          ];
          invoicePendingTableData.push(invoiceRowData);
        });
    }

    const invoicePendingFooterRow = [
      {
        content: "Total Pending Amount",
        colSpan: 4,
      },
      invoices
        .filter((invoice) => invoice.status === "pending")
        .reduce(
          (total, invoice) =>
            parseFloat(
              // invoice.matter.currency === "USD"
              //   ? invoice.amount.$numberDecimal * 80
              //   :
              invoice.amount.$numberDecimal
            ) + total,
          0
        )
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    ];

    invoicePendingTableData.push(invoicePendingFooterRow);

    const startY1 = doc.lastAutoTable.finalY + 30;

    doc.autoTable(invoicePendingTableColumns, invoicePendingTableData, {
      startY: startY1,
      theme: "grid", // Set the theme to 'grid' to display internal lines
      tableLineColor: [0, 0, 0], // Color of the table lines (black in this example)
      tableLineWidth: 0.1, // Width of the table lines in units (default is 0.1)
      didParseCell: function (data) {
        if (data.row.index === 0 && data.row.section === "head") {
          data.cell.styles.fillColor = [136, 106, 71];
          data.cell.styles.textColor = [255, 255, 255];
        }
        if (
          data.row.index === data.table.body.length - 1 &&
          data.row.section === "body"
        ) {
          data.cell.styles.fillColor = [97, 68, 58];
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.fontStyle = "bold";
        }
        if (data.row.section === "body" && data.column.index === 4) {
          data.cell.styles.halign = "right";
          data.cell.styles.cellWidth = 35; // Set the width to 50 units
        }
      },
    });

    //END TABLES

    doc.save(
      `Financial_Report ${formattedMonthStartDate} - ${formattedMonthEndDate}.pdf`
    );
  };

  return (
    <>
      <ToastContainer />
      <div>
        {/* Search Bar  */}
        <Paper elevation={0} className="rounded-full">
          <Grid container spacing={1} alignItems="center" marginBottom="1rem">
            {/* Main Heading  */}
            <Grid item container spacing={1} xs={12} sm={7} md={3}>
              <Grid item>
                <Typography variant="h5">Financial Dashboard</Typography>
              </Grid>
            </Grid>

            {/* Date Range Picker */}
            <Grid
              item
              xs={2}
              sm={2}
              md={2}
              display="flex"
              alignItems="center"
              gap={1}
            >
              <CustomTooltip text={"Select Dates"}>
                <CustomDateRangePicker
                  startDate={selectedDateRange.start}
                  endDate={selectedDateRange.end}
                  onDateRangeChange={handleDateRangeChange}
                  iconSize={30}
                />
              </CustomTooltip>

              <Typography>
                {formatMonthDate(startSelectedDate)} -{" "}
                {formatMonthDate(endSelectedDate)}
              </Typography>
            </Grid>

            {/* Search Icon Button */}
            <Grid item xs={4} sm={4} md={0.4}>
              <CustomTooltip text="Search workhours">
                <IconButton
                  onClick={filterBetweenDates}
                  className=" text-black hover:bg-gray-800"
                  style={{
                    padding: 5, // Adjust padding as needed
                    borderRadius: "50%", // Full rounded for circular button
                  }}
                >
                  <Search style={{ fontSize: "20px" }} />{" "}
                  {/* Custom icon size */}
                </IconButton>
              </CustomTooltip>
            </Grid>

            {/* Download Icon Button */}
            <Grid item xs={4} sm={4} md={0.4}>
              <CustomTooltip text="Download Report">
                <IconButton
                  onClick={generateFinancialReport}
                  className=" text-black hover:bg-gray-800"
                  style={{
                    padding: 5,
                    borderRadius: "50%",
                  }}
                >
                  <Download style={{ fontSize: "20px" }} />
                </IconButton>
              </CustomTooltip>
            </Grid>
          </Grid>
        </Paper>

        {/* Summary Cards */}
        <Grid container spacing={3} style={{ marginBottom: "1rem" }}>
          <Grid item xs={12} sm={6} md={3}>
            <Paper elevation={3} style={{ padding: "1rem" }}>
              <Typography variant="subtitle1">
                Total Billed Since April
              </Typography>
              <Typography variant="h5">
                {formatCurrency(
                  parseFloat(pendingInvoicesTotal + collectedInvoicesTotal)
                )}
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Paper elevation={3} style={{ padding: "1rem" }}>
              <Typography variant="subtitle1">Total Collections</Typography>
              <Typography variant="h5">
                {formatCurrency(collectedInvoicesTotal)}
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Paper elevation={3} style={{ padding: "1rem" }}>
              <Typography variant="subtitle1">Pending Invoices</Typography>
              <Typography variant="h5">
                {formatCurrency(pendingInvoicesTotal)}
              </Typography>
            </Paper>
          </Grid>
        </Grid>

        {/* Tabs for content navigation */}
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          style={{ marginBottom: "1rem" }}
        >
          <Tab label="Dashboard" />
          <Tab label="Matter Performance" />
          <Tab label="Resource Efficiency" />
          <Tab label="Invoices" />
          <Tab label="Reimbursements" />
          <Tab label="Clocked Hours" />
        </Tabs>

        <TabPanel value={tabIndex} index={0}>
          <AdminDashboard employees={employees} />
        </TabPanel>

        {/* Matterwise Performance Report Table */}
        <TabPanel value={tabIndex} index={1}>
          <FormControl
            variant="outlined"
            size="small"
            style={{ minWidth: 120, marginBottom: "1rem" }}
          >
            <InputLabel>Top N</InputLabel>
            <Select value={topN} onChange={handleTopNChange} label="Top N">
              {[5, 10, 15, 20].map((number) => (
                <MenuItem key={number} value={number}>
                  Top {number}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Matter Contribution Bar Chart */}
          <Paper elevation={3} style={{ marginTop: "1rem", padding: "1rem" }}>
            <Typography variant="h6" gutterBottom>
              Matter Contribution
              {/* ({formattedMonthStartDate} -{" "}
              {formattedMonthEndDate}) */}
            </Typography>

            <ResponsiveContainer width="100%" height={500}>
              <BarChart
                data={matterChartData}
                margin={{ top: 20, right: 40, left: 40, bottom: 100 }} // Increased bottom margin
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="matter"
                  tick={{
                    angle: -20, // Rotate the labels
                    dy: 30, // Adjust y-axis offset to prevent overlap
                    fontSize: 12, // Adjust font size if needed
                  }}
                  interval={0} // Show all labels
                />
                <YAxis />
                <RechartsTooltip formatter={(value) => `${value}%`} />
                <Legend
                  verticalAlign="top" // Position legend at the top
                  align="right" // Align legend to the right side
                />{" "}
                <Bar
                  dataKey="contributionToTotal"
                  fill="#8884d8"
                  name="Contribution (%)"
                >
                  {matterChartData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS2[index % COLORS2.length]}
                    />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Paper>

          <Paper elevation={3} style={{ marginTop: "1rem", padding: "1rem" }}>
            <Typography variant="h6" gutterBottom>
              Matterwise Performance Report
            </Typography>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sortDirection={orderBy === "client" ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === "client.name"}
                        direction={orderBy === "client.name" ? order : "asc"}
                        onClick={(event) =>
                          handleRequestSort(event, "client.name")
                        }
                      >
                        Client
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      sortDirection={orderBy === "name" ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === "name"}
                        direction={orderBy === "name" ? order : "asc"}
                        onClick={(event) => handleRequestSort(event, "name")}
                      >
                        Matter
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="right">Clocked Hours</TableCell>
                    <TableCell align="right">Clocked Amount</TableCell>
                    <TableCell align="right">Billed Amount</TableCell>
                    <TableCell align="right">Discount %</TableCell>
                    <TableCell align="right">Contribution To Total %</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {computeMatterwisePerformanceData().map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.client}</TableCell>
                      <TableCell>{row.matter}</TableCell>
                      <TableCell align="right">
                        {parseFloat(row.hoursClocked).toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </TableCell>
                      <TableCell align="right">
                        {parseFloat(row.actualTimeClocked).toLocaleString(
                          "en-IN",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {parseFloat(row.timeBilled).toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </TableCell>

                      <TableCell align="right">
                        {row.discountPercentage}
                      </TableCell>
                      <TableCell align="right">
                        {row.contributionToTotal}
                      </TableCell>
                    </TableRow>
                  ))}

                  <TableRow>
                    <TableCell colSpan={2}>
                      <strong>Total Amount (billed in USD)</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>
                        {allMatterTotalBillableAmount
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>
                        {allMatterTotalBilledAmount
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </strong>
                    </TableCell>
                    <TableCell colSpan={5}></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <strong>Total Amount (billed in INR)</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>
                        {(allMatterTotalBillableAmount * 80)
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>
                        {(allMatterTotalBilledAmount * 80)
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>
                        {teamTotalDiscount
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </strong>
                    </TableCell>
                    <TableCell colSpan={4}></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </TabPanel>

        {/* Efficiency and Utilization Report Table */}
        <TabPanel value={tabIndex} index={2}>
          <FormControl
            variant="outlined"
            size="small"
            style={{ minWidth: 120, marginBottom: "1rem" }}
          >
            <InputLabel>Top N</InputLabel>
            <Select value={topN} onChange={handleTopNChange} label="Top N">
              {[5, 10, 15, 20].map((number) => (
                <MenuItem key={number} value={number}>
                  Top {number}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Resource Contribution Bar Chart */}
          <Paper elevation={3} style={{ marginTop: "1rem", padding: "1rem" }}>
            <Typography variant="h6" gutterBottom>
              Resource Contribution
            </Typography>
            <ResponsiveContainer width="100%" height={500}>
              <BarChart
                data={resourceChartData}
                margin={{ top: 20, right: 40, left: 40, bottom: 80 }} // Increased margins
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="resource"
                  tick={{
                    angle: -20, // Rotate the labels
                    dy: 30, // Adjust y-axis offset to prevent overlap
                    fontSize: 12, // Adjust font size if needed
                  }}
                  interval={0} // Show all labels
                />
                <YAxis />
                <RechartsTooltip formatter={(value) => `${value} hrs`} />
                <Legend
                  verticalAlign="top" // Position legend at the top
                  align="right" // Align legend to the right side
                />{" "}
                <Bar
                  dataKey="billableHoursUtilized"
                  fill="#8884d8"
                  name="Clocked Hours"
                >
                  {resourceChartData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS2[index % COLORS2.length]}
                    />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Paper>

          <Paper elevation={3} style={{ marginTop: "1rem", padding: "1rem" }}>
            <Typography variant="h6" gutterBottom>
              Efficiency and Utilization Report
            </Typography>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Resource</TableCell>
                    <TableCell align="right">Days worked</TableCell>
                    <TableCell align="right">Available Clocked Hours</TableCell>
                    <TableCell align="right">Clocked Hours</TableCell>
                    <TableCell align="right">Billed Hours</TableCell>
                    <TableCell align="right">W/O</TableCell>
                    <TableCell align="right">Avg Clocked hrs/day</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {computeEfficiencyUtilizationData().map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.resource}</TableCell>
                      <TableCell align="right">{row.noOfDaysWorked}</TableCell>
                      <TableCell align="right">
                        {parseFloat(row.availBillableHours).toFixed(2)}
                      </TableCell>
                      <TableCell align="right">
                        {parseFloat(row.billableHoursUtilized).toFixed(2)}
                      </TableCell>
                      <TableCell align="right">
                        {parseFloat(row.actualBilled).toFixed(2)}
                      </TableCell>
                      <TableCell align="right">
                        {parseFloat(row.discounting).toFixed(2)}
                      </TableCell>
                      <TableCell align="right">
                        {parseFloat(row.avgBillableHrsPerDay).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </TabPanel>

        {/* Invoices  */}
        <TabPanel value={tabIndex} index={3}>
          {/* Invoices Pie Chart  */}
          <Paper
            elevation={3}
            style={{
              marginTop: "1rem",
              padding: "1rem",
              backgroundColor: "#ffffff",
            }}
          >
            <Typography variant="h6" gutterBottom>
              Total Invoice Amounts
            </Typography>
            <ResponsiveContainer width="100%" height={400}>
              <PieChart>
                <Pie
                  data={totalInvoiceAmounts}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={150}
                  label={(entry) =>
                    `${entry.name}: ₹${entry.value.toLocaleString("en-IN", {
                      minimumFractionDigits: 2,
                    })}`
                  }
                  labelStyle={{
                    fill: "white",
                    fontSize: "12px",
                  }}
                >
                  {totalInvoiceAmounts.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS2[index % COLORS2.length]}
                    />
                  ))}
                </Pie>
                <RechartsTooltip
                  formatter={(value) =>
                    `₹${value.toLocaleString("en-IN", {
                      minimumFractionDigits: 2,
                    })}`
                  }
                  contentStyle={{
                    backgroundColor: "white",
                    border: "none",
                    color: "#F2F2F2",
                  }}
                />
                <Legend iconType="circle" layout="horizontal" align="center" />
              </PieChart>
            </ResponsiveContainer>
          </Paper>

          {/* Pending Invoices */}
          <Paper elevation={3} style={{ marginTop: "1rem", padding: "1rem" }}>
            <Typography variant="h6" gutterBottom>
              Pending Invoices Tracker ({formattedMonthStartDate} -{" "}
              {formattedMonthEndDate})
            </Typography>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Raised Date</TableCell>
                    <TableCell>Invoice No.</TableCell>
                    <TableCell>Invoice Period</TableCell>
                    <TableCell>Matter</TableCell>
                    <TableCell align="right">Amount (INR)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {computePendingInvoicesData().map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.raisedDate}</TableCell>
                      <TableCell>{row.invoiceNumber}</TableCell>
                      <TableCell>{row.invoicePeriod}</TableCell>
                      <TableCell>{row.matter}</TableCell>
                      <TableCell align="right">
                        {row.amount.toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell colSpan={4}>
                      <strong>Total Pending Amount</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>
                        {computePendingInvoicesData()
                          .reduce((total, row) => total + row.amount, 0)
                          .toLocaleString("en-IN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                      </strong>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>

          {/* Collected Invoices */}
          <Paper elevation={3} style={{ marginTop: "1rem", padding: "1rem" }}>
            <Typography variant="h6" gutterBottom>
              Collections in this period ({formattedMonthStartDate} -{" "}
              {formattedMonthEndDate})
            </Typography>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Raised Date</TableCell>
                    <TableCell>Invoice No.</TableCell>
                    <TableCell>Invoice Period</TableCell>
                    <TableCell>Matter</TableCell>
                    <TableCell>Collection Date</TableCell>
                    <TableCell align="right">Amount (INR)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {computeCollectedInvoicesData().map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.raisedDate}</TableCell>
                      <TableCell>{row.invoiceNumber}</TableCell>
                      <TableCell>{row.invoicePeriod}</TableCell>
                      <TableCell>{row.matter}</TableCell>
                      <TableCell>{row.collectionDate}</TableCell>
                      <TableCell align="right">
                        {row.amount.toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell colSpan={5}>
                      <strong>Total Collected Amount</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>
                        {computeCollectedInvoicesData()
                          .reduce((total, row) => total + row.amount, 0)
                          .toLocaleString("en-IN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                      </strong>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </TabPanel>

        {/* Reimbursements  */}
        <TabPanel value={tabIndex} index={4}>
          {/* Monthly Bar Graph Reimbursements  */}

          <Paper elevation={3} style={{ marginTop: "1rem", padding: "1rem" }}>
            <ResponsiveContainer width="100%" height={400}>
              <BarChart
                data={expensesChartData}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis
                  tickFormatter={(value) => `₹${(value / 1000).toFixed(2)}k`}
                />
                <Legend />
                <Tooltip
                  formatter={(value) =>
                    `₹${parseFloat(value).toLocaleString("en-IN", {
                      minimumFractionDigits: 2,
                    })}`
                  }
                />
                <Bar dataKey="amount" fill="#8884d8" name="Reimbursements">
                  {expensesChartData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS2[index % COLORS2.length]}
                    />
                  ))}
                  <LabelList
                    dataKey="amount"
                    position="top"
                    formatter={(value) =>
                      `₹${parseFloat(value).toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                      })}`
                    }
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </TabPanel>

        {/* Clocked Hours  */}
        <TabPanel value={tabIndex} index={5}>
          {/* Monthly Bar Graph for Clocked Hours */}
          <Paper elevation={3} style={{ marginTop: "1rem", padding: "1rem" }}>
            <ResponsiveContainer width="100%" height={400}>
              <BarChart
                data={clockedHoursChartData}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis tickFormatter={(value) => `${value.toFixed(2)} hrs`} />
                <Legend />
                <Tooltip
                  formatter={(value) =>
                    `${parseFloat(value).toLocaleString("en-IN", {
                      minimumFractionDigits: 2,
                    })} hrs`
                  }
                />
                <Bar dataKey="hours" fill="#8884d8" name="Clocked Hours">
                  {clockedHoursChartData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS2[index % COLORS2.length]}
                    />
                  ))}
                  <LabelList
                    dataKey="hours"
                    position="top"
                    formatter={(value) =>
                      `${parseFloat(value).toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                      })} hrs`
                    }
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </TabPanel>
      </div>
    </>
  );
};

export default Financial;
