import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  CssBaseline,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { errorAlert } from "../../utils/services";
import CustomButton from "../../components/ui/CustomButton";
import { AdminSignIn } from "../../actions/auth.actions";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password should be 6 characters long"),
});

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  // Password input view button
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onForgotPassword = () => {
    navigate("/forgotPassword");
  };

  const onLogIn = async (data) => {
    const { email, password } = data;
    const user = {
      email,
      password,
    };
    dispatch(AdminSignIn(user));
  };

  useEffect(() => {
    if (auth.error) {
      errorAlert(auth.error);
    }
  }, [auth.error]);

  useEffect(() => {
    if (auth?.authenticate) {
      if (auth.user?.role === "admin") {
        navigate("/adminDashboard");
      } else if (auth.user?.role === "employee") {
        navigate("/resource/addtime");
      } else {
        navigate("/");
      }
    }
  }, [auth?.authenticate, auth.user, navigate]);

  return (
    <>
      <ToastContainer />
      <CssBaseline />
      <Grid container>
        {/* Left Image  */}
        <Grid
          item
          md={6}
          justifyContent="center"
          alignItems="center"
          height="100vh"
          sx={{
            display: { xs: "none", md: "flex" },
            backgroundImage: `url(/assets/login-bg-image.jpeg)`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Typography
            variant="h3"
            align="center"
            color="white"
            fontWeight="600"
          >
            Welcome to Compüt Labs
          </Typography>
        </Grid>

        {/* Right Form  */}
        <Grid
          item
          md={6}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          padding={5}
          width="100%"
          gap="15px"
        >
          {/* Logo  */}
          <Box
            justifyContent="center"
            alignItems="center"
            sx={{
              display: { md: "none", xs: "flex" },
            }}
          >
            <img
              src={`/assets/Comput_labs_black.png`}
              alt="logo"
              style={{ maxWidth: "30%" }}
            />
          </Box>

          <Typography variant="h5">Log in to Comput</Typography>

          {/* Form  */}
          <Box
            component="form"
            onSubmit={handleSubmit(onLogIn)}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <TextField
              fullWidth
              variant="outlined"
              label="Email address"
              margin="normal"
              {...register("email")}
              error={!!errors.email}
              helperText={errors.email?.message}
            />

            <FormControl
              fullWidth
              variant="outlined"
              margin="normal"
              error={!!errors.password}
            >
              <InputLabel
                htmlFor="outlined-adornment-password"
                sx={{ color: errors.password ? "red" : "" }}
              >
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                {...register("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
                sx={{
                  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "red",
                    },
                }}
              />
              {errors.password && (
                <p className="errorMessage">{errors.password?.message}</p>
              )}
            </FormControl>

            <CustomButton type="submit">Log In</CustomButton>
          </Box>

          {/* Extra Button  */}
          <Box
            display="flex"
            justifyContent="center"
            width="100%"
            marginTop="10px"
          >
            <CustomButton
              variant="text"
              color="gray"
              onClick={onForgotPassword}
            >
              Forgot password?
            </CustomButton>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default SignIn;
