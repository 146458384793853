import React, { useCallback, useEffect, useState } from "react";
import formatDate, {
  API,
  authToken,
  customStyles,
  errorAlert,
  formatLetterDate,
  formatMonthDate,
  successAlert,
} from "../../utils/services";
import axios from "axios";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import jsPDF from "jspdf";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import { Download, Search, Undo } from "@mui/icons-material";
import axiosInstance from "../../helpers/axios";
import { useMattersOptions } from "../../helpers/hooks/useMattersOptions";
import { useEmployeeOptions } from "../../helpers/hooks/useEmployeesOptions";
import { useTSEmployees } from "../../helpers/hooks/useTSEmployees";
import NoDataFound from "../../components/NoDataFound";
import CustomTooltip from "../../components/ui/CustomTootltip";
import CustomDateRangePicker from "../../components/ui/CustomDateRangePicker";
import CustomModal from "../../components/ui/CustomModal";
import { RiDeleteBin6Line } from "react-icons/ri";
import { CiEdit } from "react-icons/ci";

const WriteOff = () => {
  const { matters, filteredMatters } = useMattersOptions();
  const { filteredEmployees } = useEmployeeOptions();

  const [writeOffHours, setWriteOffHours] = useState([]);
  const { commonNames } = useTSEmployees(writeOffHours);

  const auth = useSelector((state) => state.auth);
  const adminId = auth.user._id;

  const [startSelectedDate, setStartSelectedDate] = useState(() => {
    const today = new Date();
    const pastDate = new Date(today.setDate(today.getDate() - 30));
    return formatDate(pastDate);
  });
  const [endSelectedDate, setEndSelectedDate] = useState(
    formatDate(new Date())
  );

  const formattedStartDate = formatLetterDate(startSelectedDate);
  const formattedEndDate = formatLetterDate(endSelectedDate);

  //Search filter states
  const [date, setDate] = useState(new Date());
  const [selectedMatterName, setSelectedMatterName] = useState("");
  const [matter, setMatter] = useState("");
  const [client, setClient] = useState("");
  const [selectedLawyer, setSelectedLawyer] = useState("");

  //Modal states
  const [showModal, setShowModal] = useState(false);
  const [modalMatter, setModalMatter] = useState(""); //matter id
  const [modalSelectedMatterName, setModalSelectedMatterName] = useState("");
  const [modalClient, setModalClient] = useState("");
  const [modalSelectedLawyerName, setModalSelectedLawyerName] = useState("");
  const [modalSelectedLawyerId, setModalSelectedLawyerId] = useState("");
  const [entryId, setEntryId] = useState("");
  const [workDescription, setWorkDescription] = useState("");
  const [comments, setComments] = useState("");
  const [workHour, setWorkHour] = useState(0);
  const [workMin, setWorkMin] = useState(30);

  //paginated states
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalCount, setTotalCount] = useState(0);

  //company details states
  const [companyData, setCompanyData] = useState({
    companyName: "",
    companyEmail: "",
    companyAddress: "",
    companyLogo: null,
  });

  //multiple selection entries states
  const [selectedEntries, setSelectedEntries] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  // states and functions for date range picker
  const [selectedDateRange, setSelectedDateRange] = useState({
    start: "",
    end: "",
  });

  // Function to receive selected dates from DateRangePickerTst
  const handleDateRangeChange = (startDate, endDate) => {
    setSelectedDateRange({ start: startDate, end: endDate });
    setStartSelectedDate(startDate);
    setEndSelectedDate(endDate);
    console.log(startDate, endDate);
  };

  //pagination functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //GET - discounted hours
  const filterBetweenDates = useCallback(async () => {
    const adminId = auth.user._id;

    try {
      const { data } = await axiosInstance.get(
        `${API}/writeoffs/getWriteoffHoursByAdmin`,
        {
          params: {
            adminId,
            user: selectedLawyer,
            fromDate: startSelectedDate,
            toDate: endSelectedDate,
            matterId: matter,
            limit: rowsPerPage,
            page,
          },
        }
      );

      setWriteOffHours(data.workHours);
      setTotalCount(data.totalCount);
    } catch (error) {
      errorAlert(error.response?.data?.error || "An error occurred");
    }
  }, [
    auth.user._id,
    selectedLawyer,
    startSelectedDate,
    endSelectedDate,
    matter,
    rowsPerPage,
    page,
  ]);

  //GET - company details
  const fetchCompanyDetails = useCallback(async () => {
    try {
      const { data } = await axios.get(`${API}/company/details/${adminId}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });

      setCompanyData((prevData) => ({
        ...prevData,
        companyName: data.companyName || "",
        companyEmail: data.companyEmail || "",
        companyAddress: data.companyAddress || "",
        companyLogo: data.companyLogo || "",
        companyWebsite: data.companyWebsite || "",
        companyAccountsEmail: data.companyAccountsEmail || "",
      }));
    } catch (error) {
      errorAlert("Error in fetching company details!!");
    }
  }, [adminId, setCompanyData]);

  //Modal for edit discounted hours
  const openModal = (time) => {
    // console.log("time:", time);

    setShowModal(true);
    setEntryId(time._id);
    setWorkDescription(time.workDescription);
    setDate(time.workDate);
    setModalSelectedLawyerId(time.user._id);
    setModalSelectedLawyerName(time.user.fullName);
    setModalMatter(time.matter);
    setModalSelectedMatterName(time.matter.name);
    setComments(time.comments);
    const hour = time.discountedHours.split(".");
    setWorkHour(hour[0]);
    setWorkMin(Math.floor((hour[1] * 6) / 10));
  };

  const closeModal = () => {
    setShowModal(false);
    setEntryId("");
    setDate(new Date());
    setWorkDescription("");
    setModalSelectedLawyerName("");
    setModalSelectedLawyerId("");
    setModalMatter("");
    setModalSelectedMatterName("");
    setModalClient("");
    setWorkHour(0);
    setWorkMin(30);
  };

  //Main edit discounted hours function
  const handleSubmit = async (e) => {
    e.preventDefault();

    const time = {
      user: modalSelectedLawyerId,
      workDate: date,
      matter: modalMatter,
      workDescription,
      discountedHours: `${workHour}.${workMin * (10 / 6)}`,
    };

    console.log("Put request Data", entryId, time);

    try {
      const response = await axiosInstance.put(
        `${API}/writeoff/update/${entryId}`,
        time
      );

      // console.log("Writeoff updated successfully:", response.data);

      if (response.data) {
        successAlert("Writeoff updated successfully!");
        setWriteOffHours((prevFilteredWorkHour) => {
          // Include the new entry in the updated state
          const updatedWorkHour = [...prevFilteredWorkHour, response.data];
          return updatedWorkHour;
        });

        closeModal();
        filterBetweenDates();
      }
    } catch (error) {
      console.error("Error updating writeoff:", error);
      errorAlert("Error Updating Write Off Entry");
    }

    closeModal();
  };

  //mupltiple select entries functions
  const handleSelectEntry = (id, checked) => {
    if (checked) {
      setSelectedEntries([...selectedEntries, id]);
    } else {
      setSelectedEntries(selectedEntries.filter((entryId) => entryId !== id));
    }
  };

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    setSelectAll(isChecked);

    // If "Select All" checkbox is checked, set all entry IDs as selected; otherwise, clear selection
    setSelectedEntries(
      isChecked
        ? writeOffHours
            .filter((entry) => entry.workDate >= startSelectedDate)
            .map((entry) => entry._id)
        : []
    );
  };

  // Single Writeoff Soft Delete
  const handleSoftDeleteWriteoffSingle = async (entryId) => {
    try {
      const response = await axiosInstance.delete(
        `${API}/writeoff/softdelete/${entryId}`
      );

      if (response.data) {
        successAlert("Write-off deleted successfully");

        // Remove the entry from the UI
        setWriteOffHours((prev) =>
          prev.filter((entry) => entry._id !== entryId)
        );

        // Store the deleted entry for undo functionality
        // setDeletedItem(response.data.deletedWriteoff);

        // Open Snackbar for undo
        // setSingleDeleteSnackbarOpen(true);
      } else {
        errorAlert("Failed to delete the write-off.");
      }
    } catch (error) {
      errorAlert("An error occurred while deleting the write-off.");
    }
  };

  // Multiple Writeoff Soft Delete
  const handleSoftDeleteWriteoffMultiple = async () => {
    if (selectedEntries.length === 0) {
      errorAlert("No entries selected for delete");
      return;
    }

    try {
      // Send delete requests for each selected entry
      // const deletePromises = selectedEntries.map((entryId) => {
      //   return axiosInstance.delete(`${API}/writeoff/softdelete/${entryId}`);
      // });

      // const responses = await Promise.all(deletePromises);

      successAlert("Selected write-offs deleted");

      // Store the deleted write-off entries for undo functionality
      // const deletedWriteoffs = responses.map(
      //   (response) => response.data.deletedWriteoff
      // );
      // setDeletedEntries(deletedWriteoffs);

      // Remove the entries from the UI after soft delete
      setWriteOffHours((prev) =>
        prev.filter((entry) => !selectedEntries.includes(entry._id))
      );

      // Open Snackbar for undo
      // setMultipleDeleteSnackbarOpen(true);

      // Clear selected entries
      setSelectedEntries([]);
      setSelectAll(false);
    } catch (error) {
      errorAlert("An error occurred while deleting the selected write-offs.");
    }
  };

  // Rollback Single Entry
  const handleRollbackWriteoffSingle = async (entryId) => {
    try {
      const response = await axiosInstance.put(
        `${API}/workhour/admin/rollback/${entryId}`
      );

      if (response.data) {
        successAlert("Single write-off entry rolled back successfully");

        // Remove the entry from the write-off list in the UI
        setWriteOffHours((prev) =>
          prev.filter((entry) => entry._id !== entryId)
        );

        // Store the rolled-back entry for undo functionality
        // setRollbackItem(response.data.updatedWorkHour); // Store the rolled-back entry

        // Open Snackbar for undo
        // setSingleRollbackSnackbarOpen(true);
      } else {
        errorAlert("Failed to rollback the write-off entry.");
      }
    } catch (error) {
      errorAlert("An error occurred while rolling back the write-off entry.");
    }
  };

  // Rollback Multiple Entry
  const handleRollbackWriteoffMultiple = async () => {
    if (selectedEntries.length === 0) {
      errorAlert("No entries selected for rollback");
      return;
    }

    try {
      // Send rollback requests for each selected entry
      // const rollbackPromises = selectedEntries.map((entryId) => {
      //   return axiosInstance.put(`${API}/workhour/admin/rollback/${entryId}`);
      // });

      // const responses = await Promise.all(rollbackPromises);

      successAlert("Selected write-off entries rolled back");

      // Store the rolled-back entries for undo functionality
      // const rolledBackItems = responses.map((response) => response.data.entry);
      // setRollbackEntries(rolledBackItems);

      // Remove the entries from the write-off list in the UI
      setWriteOffHours((prev) =>
        prev.filter((entry) => !selectedEntries.includes(entry._id))
      );

      // Open Snackbar for undo
      // setMultipleRollbackSnackbarOpen(true);

      // Clear selected entries
      setSelectedEntries([]);
      setSelectAll(false);
    } catch (error) {
      errorAlert(
        "An error occurred while rolling back the selected write-off entries."
      );
    }
  };

  //useeffects
  useEffect(() => {
    fetchCompanyDetails();
  }, [fetchCompanyDetails]);

  useEffect(() => {
    filterBetweenDates();
  }, [filterBetweenDates]);

  //below functions and calculations to generate discounted hours report
  const doubleUsedTableColumns = [
    "Associate Name",
    "Discounted Time",
    "Hourly Rate",
    "Total Amount",
  ];

  const doubleUsedTableData = [];

  let currency;
  const matterCurr = matters.find((m) => m._id === matter);
  if (matterCurr) {
    currency = matterCurr.currency === "USD" ? "$" : "Rs. ";
    // console.log("Currency: ", currency);
    // Use the currency value as needed
  } else {
    currency = "";
    // console.log("Matter not found.");
  }

  let teamTotalBilledHours = 0;
  let teamTotalBilledAmount = 0;

  if (commonNames.length > 0) {
    commonNames.forEach((name) => {
      let personWriteoffHours = 0;

      if (writeOffHours) {
        writeOffHours
          .filter(
            (result) =>
              result.user?.fullName === name &&
              result.matter._id === matter &&
              result.workDate >= startSelectedDate && // Add start date filter
              result.workDate <= endSelectedDate // Add end date filter
          )
          .forEach((result) => {
            const workHour = parseFloat(result.workHour);
            personWriteoffHours += workHour;
          });
      }

      const newMatter = matters.find(
        (matter) => matter.name.trim() === selectedMatterName.trim()
      );

      let price;
      if (newMatter) {
        if (newMatter.pricePerHour) {
          price = newMatter.pricePerHour.$numberDecimal;
        } else if (newMatter.resourceSpecificPrice.length !== 0) {
          const employeeEntry = newMatter.resourceSpecificPrice.find((entry) =>
            entry.includes(name)
          );

          if (employeeEntry) {
            const [, hours] = employeeEntry.split(":");
            price = hours.trim();
          }
        } else {
          price = 0;
        }
      }

      if (price) {
        let personTotalBilledAmount = price * personWriteoffHours;
        // teamTotalHours += personWriteoffHours;
        teamTotalBilledHours += personWriteoffHours;
        teamTotalBilledAmount += personTotalBilledAmount;

        const data1 = [
          name,
          personWriteoffHours.toFixed(2),
          currency + price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          currency +
            personTotalBilledAmount
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        ];

        doubleUsedTableData.push(data1);
      }
    });
  }

  const columns = [
    "WorkDate",
    "Resource",
    "Matter",
    "Narration",
    "WorkHours",
    "Edit",
    "Delete",
    "Rollback",
  ];

  // Add footer row
  const footerRow = [
    {
      content: "Total Hours",
      styles: {
        fillColor: [97, 68, 58],
        textColor: [255, 255, 255],
        fontStyle: "bold",
      },
    },
    {
      content: teamTotalBilledHours.toFixed(2),
      styles: {
        fillColor: [97, 68, 58],
        textColor: [255, 255, 255],
        fontStyle: "bold",
      },
    },
    {
      content: "",
      styles: {
        fillColor: [97, 68, 58],
        textColor: [255, 255, 255],
        fontStyle: "bold",
      },
    },
    {
      content:
        currency +
        teamTotalBilledAmount
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      styles: {
        fillColor: [97, 68, 58],
        textColor: [255, 255, 255],
        fontStyle: "bold",
      },
    },
  ];

  doubleUsedTableData.push(footerRow);

  const generateWriteoff = () => {
    const doc = new jsPDF();

    const link1Text = companyData.companyAccountsEmail;
    const link1URL = `mailto:${companyData.companyAccountsEmail}`;
    const link2Text = companyData.companyWebsite;
    const link2URL = companyData.companyWebsite;

    const pageWidth = doc.internal.pageSize.getWidth();
    const rightMargin = 15;
    const logoDesiredHeight = 20;
    const logoYPosition = 10;
    const logoXPosition = 13;

    //Header fixed for every new page
    function addHeader() {
      const fontSize = 8;

      doc.setFont("helvetica", "normal");
      doc.setFontSize(fontSize);

      doc.addImage(
        companyData.companyLogo,
        "PNG",
        logoXPosition,
        logoYPosition,
        0,
        logoDesiredHeight
      );

      // right header
      doc.setTextColor(46, 115, 176);

      const link2Width = doc.getTextWidth(link2Text);
      const link2X = pageWidth - link2Width - rightMargin;
      doc.textWithLink(link2Text, link2X, 19, {
        url: link2URL,
        underline: true,
      });

      // Set link color to blue and add underline
      const link1Width = doc.getTextWidth(link1Text);
      const link1X = pageWidth - link1Width - rightMargin;
      doc.textWithLink(link1Text, link1X, 22, {
        url: link1URL,
        underline: true,
      });

      doc.setTextColor(0);

      // Calculate text width and position to align it right
      const addressWidth = doc.getTextWidth(companyData.companyAddress);
      const addressX = pageWidth - addressWidth - rightMargin;
      doc.text(companyData.companyAddress, addressX, 26);
    }

    const Timespent = `/assets/Timespent.jpg`;
    doc.addImage(Timespent, "JPEG", 0, 0, 210, 297);
    doc.addImage(
      companyData.companyLogo,
      "PNG",
      logoXPosition,
      logoYPosition,
      0,
      logoDesiredHeight
    );

    const fontSize = 20;
    doc.setFontSize(fontSize);

    //center alignment width calculations
    const clientNameLength = (210 - doc.getTextWidth(client)) / 2;
    const matterNameLength = (210 - doc.getTextWidth(selectedMatterName)) / 2;

    const dateRangeLength =
      (210 - doc.getTextWidth(`${formattedStartDate} - ${formattedEndDate}`)) /
      2;

    //client name
    doc.setFont("helvetica", "bold");
    doc.setTextColor(136, 106, 71);
    doc.setFontSize(20);
    doc.text(client, clientNameLength, 120);

    //matter name
    doc.setFontSize(20);
    doc.setFont("helvetica", "normal");
    doc.text(selectedMatterName, matterNameLength, 130);

    //horizontal line
    doc.setLineWidth(0.3);
    doc.setDrawColor(136, 106, 71);
    doc.line(40, 145, 175, 145);

    //date range
    doc.setFont("helvetica", "italic");
    doc.setTextColor(0, 0, 0); // Black color
    doc.setFontSize(15);
    doc.text(
      `${formattedStartDate} - ${formattedEndDate}`,
      dateRangeLength + 10,
      160
    );
    doc.text(
      `(Discounted Time)`,
      (210 - doc.getTextWidth(`(Discounted Time)`)) / 2,
      170
    );

    // ----------------2nd Page --------------------

    doc.addPage();
    addHeader();
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");

    doc.text("Dear Sir / Ma'am", 15, 40);
    doc.setFont("helvetica", "bold");
    doc.setTextColor(136, 106, 71);
    doc.text(
      `${client} - ${selectedMatterName} From Period ${formatMonthDate(
        startSelectedDate
      )} To ${formatMonthDate(endSelectedDate)}`,
      14,
      50
    );
    doc.setTextColor(0, 0, 0);
    doc.setFont("helvetica", "normal");
    doc.text(
      "Please find enclosed our memorandum of discounted time-spent on the given matter for the said period.",
      15,
      60
    );

    //---------------------------------Table Page 2----------------------------

    const startY = 70; // Starting Y-coordinate for the table

    doc.autoTable(doubleUsedTableColumns, doubleUsedTableData, {
      startY,
      theme: "grid",
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.1,

      didParseCell: function (data) {
        if (data.row.index === 0 && data.row.section === "head") {
          // Header row styles
          data.cell.styles.fillColor = [136, 106, 71];
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.halign = "left"; // Align header text to the left
          data.cell.styles.fontStyle = "bold";
          data.cell.styles.fontSize = 9;
        } else if (data.row.section === "body") {
          // Body row styles
          if (data.column.index !== 0) {
            // Right-align all columns except the first column
            data.cell.styles.halign = "right";
          }
        } else if (data.row.index === doubleUsedTableData.length - 1) {
          // Footer row align
          data.cell.styles.halign = "right"; // Align footer text to the right
          data.cell.styles.fillColor = [226, 213, 203];
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.fontStyle = "bold";
        } else if (
          data.row.index === doubleUsedTableData.length - 1 &&
          data.row.section === "foot"
        ) {
          // Footer row styles
          data.cell.styles.fillColor = [226, 213, 203];
          data.cell.styles.textColor = [255, 255, 255];
        }

        // Set padding for table data cells
        if (
          data.row.section === "body" &&
          data.column.index === 0 &&
          data.row.index !== -1 &&
          data.row.index !== doubleUsedTableData.length - 1
        ) {
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
          data.cell.styles.textColor = [0, 0, 0];
        }
        if (
          data.row.section === "body" &&
          data.column.index === 1 &&
          data.row.index !== -1 &&
          data.row.index !== doubleUsedTableData.length - 1
        ) {
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
          data.cell.styles.textColor = [0, 0, 0];
        }
        if (
          data.row.section === "body" &&
          data.column.index === 2 &&
          data.row.index !== -1 &&
          data.row.index !== doubleUsedTableData.length - 1
        ) {
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
          data.cell.styles.textColor = [0, 0, 0];
        }
        if (
          data.row.section === "body" &&
          data.column.index === 3 &&
          data.row.index !== -1 &&
          data.row.index !== doubleUsedTableData.length - 1
        ) {
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
          data.cell.styles.textColor = [0, 0, 0];
        }
        if (
          data.row.section === "body" &&
          data.column.index === 4 &&
          data.row.index !== -1 &&
          data.row.index !== doubleUsedTableData.length - 1
        ) {
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
          data.cell.styles.textColor = [0, 0, 0];
        }
      },
    });

    // Calculate the table height
    const tableHeight = doc.autoTable.previous.finalY - startY;

    doc.text(
      "Summary of discount on this matter is provided below ",
      15,
      startY + tableHeight + 10
    );
    doc.setFont("helvetica", "bold");
    doc.setTextColor(136, 106, 71);
    doc.text(
      "Total Amount for the above period",
      15,
      startY + tableHeight + 20
    );
    doc.text(
      currency +
        teamTotalBilledAmount
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      doc.internal.pageSize.getWidth() -
        doc.getTextWidth(
          currency +
            teamTotalBilledAmount
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        ) -
        15,
      startY + tableHeight + 20
    );

    doc.setTextColor(0, 0, 0);
    doc.setFont("helvetica", "normal");

    doc.text("Yours Sincerely,", 15, startY + tableHeight + 30);
    doc.setFont("helvetica", "bold");
    doc.text(auth.user.fullName, 15, startY + tableHeight + 35);
    doc.setFont("helvetica", "normal");

    // --------------------------------------3rd Page----------------------------------------------------
    doc.addPage();
    addHeader();

    let startY1 = 35;

    commonNames.forEach((name, index) => {
      const writeOffEntries = writeOffHours.filter(
        (result) => result.user?.fullName === name
      );
      const personWriteOffHours = writeOffEntries.reduce(
        (total, result) => total + parseFloat(result.workHour),
        0
      );

      const headerRow = [
        {
          content: name,
          colSpan: 2,
          styles: {
            cellWidth: "auto", // Set cell width to auto to allow content alignment
            halign: "left",
            fillColor: [136, 106, 71],
            textColor: [255, 255, 255],
            fontStyle: "bold", // Set the font style to bold
          },
        },
        {
          content: "Discounted Time",
          styles: {
            cellWidth: "auto", // Set cell width to auto to allow content alignment
            halign: "left",
            fillColor: [136, 106, 71],
            textColor: [255, 255, 255],
            fontStyle: "bold", // Set the font style to bold
          },
        },
      ];

      const tableRows = [headerRow];

      // Add individual data rows for the person
      writeOffEntries.forEach((result) => {
        const data = [
          formatMonthDate(result.workDate),
          result.workDescription.trim(" "),
          parseFloat(result.workHour).toFixed(2),
        ];
        tableRows.push(data);
      });

      const footerRow = [
        {
          content: "Total Time",
          colSpan: 2,
          styles: {
            halign: "left",
            fillColor: [97, 68, 58],
            textColor: [255, 255, 255],
            fontStyle: "bold",
          },
        },
        {
          content: personWriteOffHours.toFixed(2),
          styles: {
            halign: "right",
            fillColor: [97, 68, 58],
            textColor: [255, 255, 255],
            fontStyle: "bold",
          },
        },
      ];

      tableRows.push(footerRow);

      const filteredTableRows = tableRows.filter((row) =>
        row.some((cell) => cell !== "")
      );

      doc.autoTable({
        body: filteredTableRows,
        startY: startY1,
        theme: "grid",
        tableLineColor: [0, 0, 0],
        tableLineWidth: 0.1,
        didParseCell: function (data) {
          if (data.row.index === 0 && data.row.section === "head") {
            // Header row styles
            data.cell.styles.fillColor = [136, 106, 71];
            data.cell.styles.textColor = [255, 255, 255];
            data.cell.styles.halign = "left";
            data.cell.styles.valign = "left"; // Align the text vertically at the center
            data.cell.styles.fontStyle = "bold"; // Set the font style to bold
          } else if (
            data.row.index === filteredTableRows.length - 1 &&
            data.row.section === "body"
          ) {
            // Footer row styles
            data.cell.styles.fillColor = [97, 68, 58];
            data.cell.styles.textColor = [255, 255, 255];
            // data.cell.styles.halign = "right";
            data.cell.styles.fontStyle = "bold";
          } else if (data.row.section === "body") {
            // Body row styles
            if (data.column.index !== 1) {
              // Right-align all columns except the first column
              data.cell.styles.halign = "left";
            }
          }
          // Set padding for table data cells
          if (
            data.row.section === "body" &&
            data.column.index === 0 &&
            data.row.index !== 0 &&
            data.row.index !== tableRows.length - 1
          ) {
            data.cell.styles.cellPadding = {
              top: 3,
              right: 2,
              bottom: 3,
              left: 3,
            };
            data.cell.styles.fontSize = 9;
            data.cell.styles.halign = "left";
            data.cell.styles.cellWidth = 25;
            data.cell.styles.textColor = [0, 0, 0];
          }

          if (
            data.row.section === "body" &&
            data.column.index === 1 &&
            data.row.index !== 0 &&
            data.row.index !== tableRows.length - 1
          ) {
            data.cell.styles.cellPadding = {
              top: 3,
              right: 4,
              bottom: 3,
              left: 4,
            };
            data.cell.styles.fontSize = 9;
            data.cell.styles.fontStyle = "times";
            data.cell.styles.halign = "left";
            data.cell.styles.cellWidth = 130; // Set the width to 100 units
            data.cell.styles.textColor = [0, 0, 0];
          }

          if (
            data.row.section === "body" &&
            data.column.index === 2 &&
            data.row.index !== 0 &&
            data.row.index !== tableRows.length - 1
          ) {
            data.cell.styles.cellPadding = {
              top: 3,
              right: 2,
              bottom: 3,
              left: 2,
            };
            data.cell.styles.halign = "right";
            data.cell.styles.fontSize = 9;
            data.cell.styles.cellWidth = 27;
            data.cell.styles.textColor = [0, 0, 0];
          }
        },
      });

      startY1 = doc.lastAutoTable.finalY + 10; // Update startY for the next table
    });

    doc.save(
      `${client} - ${selectedMatterName} From Period ${formatMonthDate(
        startSelectedDate
      )} To ${formatMonthDate(endSelectedDate)}.pdf`
    );
  };

  return (
    <div>
      <ToastContainer />

      {/* Search Bar  */}
      <Paper elevation={0} className="rounded-full">
        <Grid container spacing={1} alignItems="center" marginBottom="1rem">
          {/* Main Heading  */}
          <Grid item container spacing={1} xs={12} sm={7} md={3}>
            <Grid item>
              <Typography variant="h5">Write Off</Typography>
            </Grid>
          </Grid>

          {/* Matters  */}
          <Grid item xs={12} sm={7} md={2.5}>
            <Select
              options={filteredMatters}
              value={filteredMatters.find(
                (option) => option.label === `${selectedMatterName} | ${client}`
              )}
              onChange={(e) => {
                setMatter(e.value);
                setSelectedMatterName(e.label.split("|")[0]);
                setClient(e.label.split("|")[1]);
              }}
              isSearchable
              placeholder="Select Matter"
              styles={customStyles}
            />
          </Grid>

          {/* Resources  */}
          <Grid item xs={12} sm={5} md={2}>
            <Select
              options={filteredEmployees}
              value={filteredEmployees.find(
                (resOption) => resOption.label === `${selectedLawyer}`
              )}
              onChange={(e) => {
                setSelectedLawyer(e.value);
              }}
              isSearchable
              placeholder="Resource"
              styles={customStyles}
            />
          </Grid>

          {/* Date Range Picker */}
          <Grid
            item
            xs={2}
            sm={2}
            md={2}
            display="flex"
            alignItems="center"
            gap={1}
          >
            <CustomTooltip text={"Select Dates"}>
              <CustomDateRangePicker
                startDate={selectedDateRange.start}
                endDate={selectedDateRange.end}
                onDateRangeChange={handleDateRangeChange}
                iconSize={30}
              />
            </CustomTooltip>

            <Typography>
              {formatMonthDate(startSelectedDate)} -{" "}
              {formatMonthDate(endSelectedDate)}
            </Typography>
          </Grid>

          {/* Search Icon Button */}
          <Grid item xs={4} sm={4} md={0.4}>
            <CustomTooltip text="Search workhours">
              <IconButton
                onClick={() => filterBetweenDates()}
                className=" text-black hover:bg-gray-800"
                style={{
                  padding: 5, // Adjust padding as needed
                  // color: "white",
                  // backgroundColor: "black",
                  borderRadius: "50%", // Full rounded for circular button
                }}
              >
                <Search style={{ fontSize: "20px" }} /> {/* Custom icon size */}
              </IconButton>
            </CustomTooltip>
          </Grid>

          {/* Download Icon Button */}
          <Grid item xs={4} sm={4} md={0.4}>
            <CustomTooltip text="Download Report">
              <IconButton
                onClick={generateWriteoff}
                className=" text-black hover:bg-gray-800"
                style={{
                  padding: 5,
                  // color: "white",
                  // backgroundColor: "black",
                  borderRadius: "50%",
                }}
              >
                <Download style={{ fontSize: "20px" }} />
              </IconButton>
            </CustomTooltip>
          </Grid>
        </Grid>

        {selectedEntries.length > 1 && (
          <Grid container spacing={1} alignItems="baseline">
            <Grid item xs={12} sm={4} md={0.5}>
              <CustomTooltip text="Delete all">
                <IconButton onClick={handleSoftDeleteWriteoffMultiple}>
                  <RiDeleteBin6Line style={{ fontSize: "25px" }} />
                </IconButton>
              </CustomTooltip>
            </Grid>

            <Grid item xs={12} sm={4} md={0.5}>
              <CustomTooltip text="Rollback all">
                <IconButton onClick={handleRollbackWriteoffMultiple}>
                  <Undo style={{ fontSize: "25px" }} />
                </IconButton>
              </CustomTooltip>
            </Grid>
          </Grid>
        )}
      </Paper>

      {/* Modal  */}
      {showModal && (
        <CustomModal
          showModal={showModal}
          closeModal={closeModal}
          title="Edit Write Off" // Dynamic title
          onSubmit={handleSubmit}
        >
          <div className="flex gap-4 w-full">
            <div style={{ width: "50%" }}>
              <input
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </div>

            <div style={{ width: "50%" }}>
              <Select
                options={filteredEmployees}
                value={filteredEmployees.find(
                  (resOption) =>
                    resOption.label === `${modalSelectedLawyerName}`
                )}
                onChange={(e) => {
                  setModalSelectedLawyerId(e.value);
                  setModalSelectedLawyerName(e.label);
                  // console.log(e.label);
                }}
                isSearchable
                placeholder="Select Resource"
                styles={customStyles}
              />
            </div>
          </div>

          <div className="flex gap-4 w-full">
            <div style={{ width: "100%" }}>
              {modalSelectedMatterName}
              <Select
                options={filteredMatters}
                value={filteredMatters.find(
                  (option) =>
                    option.label ===
                    `${modalSelectedMatterName} | ${modalClient}`
                )}
                onChange={(e) => {
                  setModalMatter(e.value);
                  setModalSelectedMatterName(e.label.split("|")[0]);
                  setModalClient(e.label.split("|")[1]);
                }}
                isSearchable
                placeholder="Select Matter"
                styles={customStyles}
              />
            </div>
          </div>

          <div className="flex gap-4 w-full">
            <div style={{ width: "50%" }}>
              <select
                value={workHour}
                name="workHour"
                onChange={(event) => {
                  setWorkHour(event.target.value);
                }}
              >
                <option value="">Select Work Hour *</option>
                {Array.from({ length: 19 }, (_, i) => (
                  <option key={i} value={i}>
                    {i}
                  </option>
                ))}
              </select>
            </div>

            <div style={{ width: "50%" }}>
              <select
                value={workMin}
                name="workMin"
                onChange={(event) => {
                  setWorkMin(event.target.value);
                }}
              >
                <option value="">Select Work Minutes *</option>
                {Array.from({ length: 10 }, (_, i) => (
                  <option key={i * 6} value={i * 6}>
                    {i * 6}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <textarea
            type="text"
            placeholder="Work description"
            value={workDescription}
            onChange={(e) => setWorkDescription(e.target.value)}
          />

          <div className="w-full">
            <input
              style={{ width: "100%" }}
              type="text"
              placeholder="Comments for the reviewer"
              value={comments}
              onChange={(e) => setComments(e.target.value)}
            />
          </div>
        </CustomModal>
      )}

      {/* Main Table  */}
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle1" style={{ marginLeft: "10px", fontWeight:700 }}>
            Recent Writeoffs
          </Typography>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
        <TableContainer sx={{ maxHeight: "68vh" }}>
          <Table aria-label="reusable table">
            <TableHead>
              <TableRow>
                <TableCell id="table-header">
                  <CustomTooltip text="Select All">
                    <input
                      type="checkbox"
                      className="custom-checkbox"
                      checked={selectAll}
                      onChange={(e) => handleSelectAll(e)}
                    />
                  </CustomTooltip>
                </TableCell>
                {columns.map((column, index) => (
                  <TableCell key={index} id="table-header">
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {writeOffHours.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={11}>
                    <NoDataFound message="Oops! No writeoffs found!" />
                  </TableCell>
                </TableRow>
              ) : (
                writeOffHours.map((time, index) => (
                  <TableRow key={time._id} role="checkbox" tabIndex={-1}>
                    <TableCell>
                      <input
                        type="checkbox"
                        className="custom-checkbox"
                        checked={selectedEntries.includes(time._id)}
                        onChange={(e) =>
                          handleSelectEntry(time._id, e.target.checked)
                        }
                      />
                    </TableCell>
                    <TableCell>{formatMonthDate(time.workDate)}</TableCell>
                    <TableCell>{time?.user?.fullName}</TableCell>
                    <TableCell>{time.matter.name}</TableCell>
                    <TableCell>{time.workDescription}</TableCell>
                    <TableCell>
                      {time.discountedHours
                        ? time.discountedHours + " hrs"
                        : "0"}
                    </TableCell>
                    <TableCell className="table-cell">
                      <CiEdit
                        style={{ cursor: "pointer" }}
                        onClick={() => openModal(time)}
                      />
                    </TableCell>
                    <TableCell className="table-cell">
                      <RiDeleteBin6Line
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleDeleteWriteOff(time._id)}
                        onClick={() => handleSoftDeleteWriteoffSingle(time._id)}
                      />
                    </TableCell>
                    <TableCell className="table-cell">
                      <Undo
                        style={{ cursor: "pointer" }}
                        onClick={() => handleRollbackWriteoffSingle(time._id)}
                      />
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default WriteOff;
