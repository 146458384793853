import { createTheme, responsiveFontSizes } from "@mui/material/styles";

export const COLORS = {
  primary: "#000000", // Black in hex format
  primary2: "#f8f8f8", // Light gray
  secondary: "#FFFFFF", // White in hex format
};

// Create a Material-UI theme with your primary colors and default settings
let baseTheme = createTheme({
  palette: {
    primary: {
      main: COLORS.primary,
    },
    secondary: {
      main: COLORS.primary2,
    },
    background: {
      default: COLORS.secondary,
      paper: COLORS.secondary,
    },
    text: {
      primary: COLORS.primary,
    },
  },
  typography: {
    fontFamily: '"Nunito Sans", sans-serif',
    fontSize: 12,
    body1: {
      fontSize: "12px",
      "@media (max-width:600px)": {
        fontSize: "10px", // Small screen font size
      },
      "@media (min-width:960px)": {
        fontSize: "12px", // Medium screen font size
      },
      "@media (min-width:1300px)": {
        fontSize: "14px", // Large screen font size
      },
    },
    body2: {
      fontSize: "12px",
      fontWeight: 700,
      "@media (max-width:600px)": {
        fontSize: "10px", // Small screen font size
      },
      "@media (min-width:960px)": {
        fontSize: "12px", // Medium screen font size
      },
      "@media (min-width:1300px)": {
        fontSize: "14px", // Large screen font size
      },
    },
    h5: {
      fontFamily: "sans-serif", // Override font family
      fontSize: "20px", // Custom font size
      fontWeight: "bold", // Custom font weight
      color: COLORS.primary, // Use primary color
      lineHeight: 1.5, // Adjust line-height
      marginY: "15px", // Custom margin (will need to be added in the usage or with additional styling as MUI does not support marginY directly in typography)
    },
  },
  shape: {
    borderRadius: 8,
  },
  spacing: 8,
});

// Then, create the full theme with the component overrides
let theme = createTheme(baseTheme, {
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          ...baseTheme.typography.body1,
          padding: "15px 16px",
        },
        head: {
          backgroundColor: COLORS.primary2, // Apply primary2 as background color for Table Head cells
          color: COLORS.primary, // Use primary color for text
          fontWeight: 700, // Bold text for header
          position: "sticky", // Make the header sticky
          top: 0, // Stick to the top
          zIndex: 1, // Ensure it's above other content
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: COLORS.primary2, // Set background color of Table Head globally
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: baseTheme.palette.primary.main,
          padding: baseTheme.spacing(1),
          fontSize: "1rem",
          "&.MuiIconButton-sizeSmall": {
            fontSize: "1rem",
            padding: baseTheme.spacing(0.5),
          },
          "&.MuiIconButton-sizeLarge": {
            fontSize: "2rem",
            padding: baseTheme.spacing(1.5),
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          "&.MuiSvgIcon-fontSizeSmall": {
            fontSize: "1rem",
          },
          "&.MuiSvgIcon-fontSizeLarge": {
            fontSize: "2rem",
          },
        },
      },
    },
  },
});

// Apply responsive font sizes to the theme, but only for variants that need it
theme = responsiveFontSizes(theme);

export default theme;

// export const COLORS = {
//   primary: "black", //black
//   primary2: "#f8f8f8", //light gray
//   transparentPrimary: "rgba(55, 42, 27, 0.4)",
//   cardPrimary: "rgba(136, 106, 71, 0.1)",
//   orange: "#FFA133",
//   lightBrown: "#ea8570",
//   lightBrown2: "#FDDED4",
//   lightBrown3: "#FFD9AD",
//   green: "#27AE60",
//   green2: "#86DC3D",
//   red: "#FF1717",
//   blue: "#0064C0",
//   darkBlue: "#111A2C",
//   darkGray: "#525C67",
//   darkGray2: "#757D85",
//   gray: "#898B9A",
//   gray2: "#BBBDC1",
//   gray3: "#CFD0D7",
//   lightGray1: "#DDDDDD",
//   lightGray2: "#F5F5F8",
//   lightGray3: "#e6e5e3",
//   white2: "#FBFBFB",
//   white: "#FFFFFF",
//   black: "#000000",

//   transparent: "transparent",
//   transparentBlack1: "rgba(0, 0, 0, 0.1)",
//   transparentBlack7: "rgba(0, 0, 0, 0.7)",
//   transparentGray: "rgba(150, 150, 150, 0.4)",
//   transparentRed: "rgba(255,23,23, 0.1)",
// };
// export const SIZES = {
//   // global sizes
//   base: 8,
//   font: 14,
//   radius: 12,
//   padding: 24,

//   // font sizes
//   largeTitle: 40,
//   h1: 30,
//   h2: 22,
//   h2B: 18,
//   h3: 16,
//   h4: 14,
//   h5: 12,
//   body1: 30,
//   body2: 22,
//   body3: 16,
//   body4: 14,
//   body5: 12,
// };

// export const FONTS = {
//   largeTitle: { fontSize: SIZES.largeTitle },
//   h1: { fontSize: SIZES.h1, lineHeight: 36 },
//   h2: { fontSize: SIZES.h2, lineHeight: 30, fontWeight: "600" },
//   h2B: { fontSize: SIZES.h2B, lineHeight: 22, fontWeight: "600" },
//   h3: { fontSize: SIZES.h3, lineHeight: 22 },
//   h4: { fontSize: SIZES.h4, lineHeight: 22 },
//   body1: { fontSize: SIZES.body1, lineHeight: 36 },
//   h5: { fontSize: SIZES.h5, lineHeight: 22 },
//   body2: { fontSize: SIZES.body2, lineHeight: 30 },
//   body3: { fontSize: SIZES.body3, lineHeight: 22 },
//   body4: { fontSize: SIZES.body4, lineHeight: 22 },
//   body5: { fontSize: SIZES.body5, lineHeight: 22 },
// };

// const appTheme = { COLORS, SIZES, FONTS };

// export default appTheme;
