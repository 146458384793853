// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#main-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: fit-content;
  background-color: black;
}

#main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  width: 50vw;
  padding: 2rem;
  border-radius: 10px;
  background-color: white;
  margin: 5rem 0;

  /* background-image: url("../../../../../assets/formsBackground.jpg");
      background-position: center;
      background-size: cover; */
}

#resource-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent;
  padding: 40px;
  width: 80%;
  border: 1px solid rgb(155, 136, 136);
  border-radius: 30px;
  box-shadow: 1px 2px 3px 3px rgba(0, 0, 0, 0.2);
}

.form-input {
  width: 80%;
}

#form-selection-btn {
  background-color: black;
  color: white;
  width: 50%;
  height: 45px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/admin/AddMatter.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;EACT,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,cAAc;;EAEd;;+BAE6B;AAC/B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,UAAU;EACV,oCAAoC;EACpC,mBAAmB;EACnB,8CAA8C;AAChD;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,uBAAuB;EACvB,YAAY;EACZ,UAAU;EACV,YAAY;AACd","sourcesContent":["#main-form-container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: fit-content;\n  background-color: black;\n}\n\n#main {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 30px;\n  width: 50vw;\n  padding: 2rem;\n  border-radius: 10px;\n  background-color: white;\n  margin: 5rem 0;\n\n  /* background-image: url(\"../../../../../assets/formsBackground.jpg\");\n      background-position: center;\n      background-size: cover; */\n}\n\n#resource-form {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  background: transparent;\n  padding: 40px;\n  width: 80%;\n  border: 1px solid rgb(155, 136, 136);\n  border-radius: 30px;\n  box-shadow: 1px 2px 3px 3px rgba(0, 0, 0, 0.2);\n}\n\n.form-input {\n  width: 80%;\n}\n\n#form-selection-btn {\n  background-color: black;\n  color: white;\n  width: 50%;\n  height: 45px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
