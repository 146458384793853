// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-searchBar {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.upper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  gap: 2rem;
}

input {
  padding: 0.5rem;
  width: 60%;
}

.middle {
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 2rem;
  height: 25px;
}

.lower {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin: 1rem;
  padding: 0.5rem;
}

.buttons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 1rem 0;
}

.date {
  width: fit-content;
}

.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  width: 60%;
  max-height: 300px;
  overflow-y: auto;
  background-color: #ffffff;
  border: 1px solid #ccc;
  /* border-top: none; */
  border: none;
}

.search-results ul {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.search-results li {
  padding: 0 10px;
  cursor: pointer;
}

.search-results li:hover {
  background: #f1f1f1;
}
`, "",{"version":3,"sources":["webpack://./src/pages/admin/Timespent.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,SAAS;AACX;;AAEA;EACE,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,qBAAqB;EACrB,SAAS;EACT,YAAY;AACd;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;EACT,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,UAAU;EACV,iBAAiB;EACjB,gBAAgB;EAChB,yBAAyB;EACzB,sBAAsB;EACtB,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,qBAAqB;EACrB,mBAAmB;EACnB,gBAAgB;EAChB,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".main-searchBar {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n\n.upper {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin: 1rem;\n  gap: 2rem;\n}\n\ninput {\n  padding: 0.5rem;\n  width: 60%;\n}\n\n.middle {\n  display: flex;\n  justify-content: center;\n  align-items: baseline;\n  gap: 2rem;\n  height: 25px;\n}\n\n.lower {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 2rem;\n  margin: 1rem;\n  padding: 0.5rem;\n}\n\n.buttons {\n  display: flex;\n  justify-content: space-evenly;\n  align-items: center;\n  margin: 1rem 0;\n}\n\n.date {\n  width: fit-content;\n}\n\n.search-results {\n  position: absolute;\n  top: 100%;\n  left: 0;\n  width: 60%;\n  max-height: 300px;\n  overflow-y: auto;\n  background-color: #ffffff;\n  border: 1px solid #ccc;\n  /* border-top: none; */\n  border: none;\n}\n\n.search-results ul {\n  display: flex;\n  flex-direction: column;\n  justify-items: center;\n  align-items: center;\n  list-style: none;\n  margin: 0;\n  padding: 0;\n}\n\n.search-results li {\n  padding: 0 10px;\n  cursor: pointer;\n}\n\n.search-results li:hover {\n  background: #f1f1f1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
