import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllMatters } from "../../actions";
import "jspdf-autotable";
import formatDate, {
  formatMonthDate,
  API,
  errorAlert,
  successAlert,
} from "../../utils/services";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Typography,
  Box,
  Tooltip,
  IconButton,
  TableBody,
} from "@mui/material";
import axiosInstance from "../../helpers/axios";
import NoDataFound from "../../components/NoDataFound";
import { ToastContainer } from "react-toastify";
import TableSkeletonLoader from "../../components/ui/TableSkeletonLoader";
import CustomTooltip from "../../components/ui/CustomTootltip";
import CustomDateRangePicker from "../../components/ui/CustomDateRangePicker";
import InfoIcon from "@mui/icons-material/Info";

const TimespentTracker = () => {
  const dispatch = useDispatch();

  //redux states
  const auth = useSelector((state) => state.auth);
  const Matter = useSelector((state) => state.matter);

  const [startSelectedDate, setStartSelectedDate] = useState(() => {
    const today = new Date();
    const pastDate = new Date(today.setDate(today.getDate() - 7));
    return formatDate(pastDate);
  });
  const [endSelectedDate, setEndSelectedDate] = useState(
    formatDate(new Date())
  );
  const needFin = false;
  const matterId = "";
  let sendMail;
  const adminId = auth.user._id;

  const [filteredWorkHour, setFilteredWorkHour] = useState([]);
  const [loading, setLoading] = useState(true);
  const [enteredNames, setEnteredNames] = useState({}); // To store names entered for each matter

  //matter names filteration
  const matterNames = filteredWorkHour.map((entry) => entry.matter.name);

  const billableMatters = Matter.matters
    .filter((item) => item.billable === true && item.status === "enable")
    .map((m) => ({
      id: m._id,
      name: m.name,
      lastSentTSDate: m.lastSentTSDate,
      enteredBy: m.enteredBy || "", // Include the enteredBy field
    }));

  function findCommonElements() {
    const commonElements = [];
    for (let i = 0; i < billableMatters.length; i++) {
      if (matterNames.includes(billableMatters[i].name)) {
        commonElements.push({
          id: billableMatters[i].id,
          name: billableMatters[i].name,
          lastSentTSDate: billableMatters[i].lastSentTSDate,
          enteredBy: billableMatters[i].enteredBy, // Include enteredBy in the result
        });
      }
    }
    return commonElements;
  }

  const uniqueMatterNames = findCommonElements();

  //GET - TS data
  const filterBetweenDates = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `${API}/workHour/filterWorkHourByUserId`,
        {
          params: {
            user: "",
            fromDate: startSelectedDate,
            toDate: endSelectedDate,
            matterId,
            adminId,
            needFin,
            sendMail,
          },
        }
      );
      setFilteredWorkHour(response.data.workHours);
    } catch (error) {
      errorAlert("Error fetching work hours!");
    } finally {
      setLoading(false);
    }
  }, [adminId, endSelectedDate, needFin, sendMail, startSelectedDate]);

  const handleAddTsDate = async (matterId, date) => {
    try {
      const enteredBy = enteredNames[matterId]; // Get the entered name for the specific matter

      if (!enteredBy) {
        return errorAlert("Please enter your name before updating the date.");
      }

      // Send the request to update both the date and the enteredBy fields
      const response = await axiosInstance.put(`${API}/updateLastSentTSDate`, {
        id: matterId,
        lastSentTSDate: date,
        enteredBy: enteredBy, // Include the enteredBy field
      });

      const data = await response.data;
      dispatch(getAllMatters(auth.user._id)); // Fetch updated matters list after update
      data && successAlert("Date and Entered By updated!!");
    } catch (error) {
      console.error("Error updating lastSentTSDate and enteredBy:", error);
    }
  };

  const handleNameChange = (matterId, name) => {
    setEnteredNames((prevNames) => ({
      ...prevNames,
      [matterId]: name, // Update the entered name for the specific matter
    }));
  };

  // states and functions for date range picker
  const [selectedDateRange, setSelectedDateRange] = useState({
    start: "",
    end: "",
  });

  // Function to receive selected dates from DateRangePickerTst
  const handleDateRangeChange = (startDate, endDate) => {
    setSelectedDateRange({ start: startDate, end: endDate });
    setStartSelectedDate(startDate);
    setEndSelectedDate(endDate);
    console.log(startDate, endDate);
  };

  useEffect(() => {
    if (startSelectedDate && endSelectedDate) {
      filterBetweenDates();
    }
  }, [startSelectedDate, endSelectedDate, filterBetweenDates]);

  useEffect(() => {
    dispatch(getAllMatters(auth.user._id));
  }, [auth.user._id, dispatch]);

  // Only initialize enteredNames once when uniqueMatterNames is populated
  useEffect(() => {
    if (
      Object.keys(enteredNames).length === 0 &&
      uniqueMatterNames.length > 0
    ) {
      const initialNames = {};
      uniqueMatterNames.forEach((matter) => {
        initialNames[matter.id] = matter.enteredBy || ""; // Initialize enteredBy if it exists
      });
      setEnteredNames(initialNames);
    }
  }, [uniqueMatterNames, enteredNames]);

  return (
    <>
      <ToastContainer />

      <Paper elevation={0} className="rounded-full">
        <Grid
          container
          spacing={1}
          display="flex"
          alignItems="center"
          marginBottom="1rem"
        >
          {/* Main Heading  */}
          <Grid item xs={2} sm={2} md={3} display="flex" gap={0.5}>
            <Typography variant="h5">Timespent Tracker</Typography>
            <Tooltip
              title="*This will only display matters where time has been entered."
              placement="top"
              arrow
            >
              <IconButton>
                <InfoIcon style={{ fontSize: "20px" }} />
              </IconButton>
            </Tooltip>
          </Grid>

          {/* Date Range Picker */}
          <Grid
            item
            xs={2}
            sm={2}
            md={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={1}
          >
            <CustomTooltip text={"Select Dates"}>
              <CustomDateRangePicker
                startDate={selectedDateRange.start}
                endDate={selectedDateRange.end}
                onDateRangeChange={handleDateRangeChange}
                iconSize={30}
              />
            </CustomTooltip>

            <Grid item display="flex" flexDirection="row" gap={1}>
              <Typography>{formatMonthDate(startSelectedDate)} </Typography>
              <Typography>-</Typography>
              <Typography>{formatMonthDate(endSelectedDate)}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      {/* Main Table  */}
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: "75vh" }}>
          <Box className="flex justify-between mx-10">
            <Typography variant="body2" align="center" marginY={2} gutterBottom>
              MATTERWISE TIMESPENT TRACKER ({formatMonthDate(startSelectedDate)}{" "}
              - {formatMonthDate(endSelectedDate)})
            </Typography>

            <Typography variant="body2" align="center" marginY={2} gutterBottom>
              Total Matters : {uniqueMatterNames.length}
            </Typography>
          </Box>

          <Table aria-label="reusable table">
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>Sr No.</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Matter</TableCell>
                {/* <TableCell style={{ fontWeight: "bold" }}>Period</TableCell> */}
                <TableCell style={{ fontWeight: "bold" }}>
                  Last Sent TS Date
                </TableCell>
                <TableCell>Entered by</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {loading ? (
                <TableSkeletonLoader rows={6} colSpan={11} />
              ) : uniqueMatterNames.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={13}>
                    <NoDataFound />
                  </TableCell>
                </TableRow>
              ) : (
                uniqueMatterNames.map((matter, index) => (
                  <TableRow key={index} role="checkbox" tabIndex={-1}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell
                      style={{
                        color: matter.lastSentTSDate ? "inherit" : "red", // Apply red color if lastSentTSDate is missing
                        fontWeight: matter.lastSentTSDate ? "normal" : "bold", // Optional: Make it bold if missing
                      }}
                    >
                      {matter.name}
                    </TableCell>
                    <TableCell>
                      <input
                        type="date"
                        value={
                          matter.lastSentTSDate
                            ? matter.lastSentTSDate.split("T")[0]
                            : ""
                        }
                        min={startSelectedDate}
                        max={endSelectedDate}
                        onChange={(e) =>
                          handleAddTsDate(matter.id, e.target.value)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <input
                        type="text"
                        placeholder="Enter name"
                        value={enteredNames[matter.id] || ""} // Use the enteredNames state
                        onChange={(e) =>
                          handleNameChange(matter.id, e.target.value)
                        } // Update enteredNames on change
                      />
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default TimespentTracker;
