import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Grid,
  TextField,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Card,
  CardContent,
} from "@mui/material";
import { Add, Check, Close } from "@mui/icons-material";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { API } from "../utils/services";
import CustomButton from "./ui/CustomButton";
import { RiDeleteBin6Line } from "react-icons/ri";
import { CiEdit } from "react-icons/ci";

const AddHolidays = () => {
  const [dates, setDates] = useState([""]);
  const [message, setMessage] = useState("");
  const [holidays, setHolidays] = useState([]);
  const [editDate, setEditDate] = useState(null);
  const [editId, setEditId] = useState(null);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    fetchHolidays();
  }, []);

  const fetchHolidays = async () => {
    try {
      const response = await axios.get(`${API}/holidays`);
      setHolidays(response.data);
    } catch (error) {
      console.error("Error fetching holidays:", error);
    }
  };

  const handleDateChange = (index, event) => {
    const newDates = dates.map((date, dateIndex) => {
      if (index !== dateIndex) return date;
      return event.target.value;
    });
    setDates(newDates);
  };

  const handleAddDate = () => {
    setDates([...dates, ""]);
  };

  const handleRemoveDate = (index) => {
    setDates(dates.filter((date, dateIndex) => index !== dateIndex));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.post(`${API}/holidays`, { dates });
      setMessage("Holidays added successfully!");
      setDates([""]);
      fetchHolidays();
    } catch (error) {
      setMessage("Error adding holidays. Please try again.");
    }
  };

  const handleEditClick = (holiday) => {
    setEditDate(holiday.date.split("T")[0]);
    setEditId(holiday._id);
  };

  const handleEditSubmit = async () => {
    try {
      await axios.put(`${API}/holidays/${editId}`, { date: editDate });
      setEditId(null);
      setEditDate(null);
      fetchHolidays();
      setMessage("Holiday updated successfully!");
    } catch (error) {
      setMessage("Error updating holiday. Please try again.");
    }
  };

  const handleDeleteClick = (id) => {
    setDeleteId(id);
    setOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await axios.delete(`${API}/holidays/${deleteId}`);
      setOpen(false);
      setDeleteId(null);
      fetchHolidays();
      setMessage("Holiday deleted successfully!");
    } catch (error) {
      setMessage("Error deleting holiday. Please try again.");
    }
  };

  return (
    <Card elevation={0}>
      <CardContent>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <Typography variant="h5">Add Holidays</Typography>
          </Grid>
          <Grid item xs={12} md={6} mt={7}>
            <form onSubmit={handleSubmit}>
              {dates.map((date, index) => (
                <Grid
                  container
                  spacing={2}
                  key={index}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item xs={8} md={8} marginBottom={1}>
                    <TextField
                      fullWidth
                      size="small"
                      type="date"
                      value={date}
                      onChange={(event) => handleDateChange(index, event)}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <CustomButton
                      variant="outlined"
                      onClick={() => handleRemoveDate(index)}
                    >
                      Remove
                    </CustomButton>
                  </Grid>
                </Grid>
              ))}

              <Grid
                container
                spacing={2}
                marginTop={1}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={8} md={8}>
                  <CustomButton
                    variant="outlined"
                    onClick={handleAddDate}
                    startIcon={<Add />}
                  >
                    Add Another Date
                  </CustomButton>
                </Grid>
                <Grid item xs={4} md={4}>
                  <CustomButton
                    variant="contained"
                    type="submit"
                    style={{ width: "100%" }}
                  >
                    Submit
                  </CustomButton>
                </Grid>
              </Grid>
            </form>

            {message && (
              <Typography
                variant="body1"
                color="error"
                style={{ marginTop: "10px" }}
              >
                {message}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid item xs={12}>
              <Typography variant="h5" align="center">
                Holidays Calendar
              </Typography>
            </Grid>

            <Box display="flex" mt={3} justifyContent="center">
              <Calendar
                tileContent={({ date, view }) =>
                  holidays.find(
                    (holiday) =>
                      new Date(holiday.date).toDateString() ===
                      date.toDateString()
                  ) ? (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="100%"
                      height="100%"
                    >
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={() =>
                          handleEditClick(
                            holidays.find(
                              (holiday) =>
                                new Date(holiday.date).toDateString() ===
                                date.toDateString()
                            )
                          )
                        }
                      >
                        <CiEdit fontSize="small" />
                      </IconButton>
                      <IconButton
                        color="secondary"
                        size="small"
                        onClick={() =>
                          handleDeleteClick(
                            holidays.find(
                              (holiday) =>
                                new Date(holiday.date).toDateString() ===
                                date.toDateString()
                            )._id
                          )
                        }
                      >
                        <RiDeleteBin6Line fontSize="small" />
                      </IconButton>
                    </Box>
                  ) : null
                }
                tileClassName={({ date, view }) =>
                  holidays.find(
                    (holiday) =>
                      new Date(holiday.date).toDateString() ===
                      date.toDateString()
                  )
                    ? "holiday-tile"
                    : null
                }
              />
            </Box>

            <Dialog open={Boolean(editId)} onClose={() => setEditId(null)}>
              <DialogTitle>Edit Holiday</DialogTitle>
              <DialogContent>
                <TextField
                  fullWidth
                  type="date"
                  value={editDate}
                  onChange={(e) => setEditDate(e.target.value)}
                  variant="outlined"
                />
              </DialogContent>
              <DialogActions>
                <CustomButton
                  style={{ width: "40%" }}
                  onClick={handleEditSubmit}
                  startIcon={<Check />}
                >
                  Save
                </CustomButton>
                <CustomButton
                  variant="outlined"
                  style={{ width: "40%", marginRight: "15px" }}
                  onClick={() => setEditId(null)}
                  startIcon={<Close />}
                >
                  Cancel
                </CustomButton>
              </DialogActions>
            </Dialog>

            <Dialog open={open} onClose={() => setOpen(false)}>
              <DialogTitle>Confirm Delete</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to delete this holiday?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <CustomButton
                  style={{ width: "50%" }}
                  onClick={handleDeleteConfirm}
                  startIcon={<Check />}
                >
                  Confirm
                </CustomButton>
                <CustomButton
                  style={{ width: "40%", marginRight: "15px" }}
                  variant="outlined"
                  onClick={() => setOpen(false)}
                  startIcon={<Close />}
                >
                  Cancel
                </CustomButton>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AddHolidays;
