import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Box,
  CssBaseline,
  Typography,
  Card,
  CardContent,
  Select,
  MenuItem,
  Container,
  Skeleton,
} from "@mui/material";
import axiosInstance from "../helpers/axios";
import {
  API,
  billedDesignations,
  errorAlert,
  formatMonthDate,
} from "../utils/services";
import { getAllEmployeesByAdminId } from "../actions";
import LineChart from "../components/graphs/LineGraphs";
// import BarChart from "../components/graphs/BarGraph";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { COLORS } from "../constants/theme";
import useResponsive from "../constants/useResponsive";

const AdminDashboard = ({ employees }) => {
  // const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [activeInterval, setActiveInterval] = useState("monthly");
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  // const [barChartData, setBarChartData] = useState({
  //   labels: [],
  //   datasets: [],
  // });

  const [dashboardTotals, setDashboardTotals] = useState({
    todayHours: 0,
    todayBillableHours: 0,
    todayNonBillableHours: 0,
    thisMonthHours: 0,
    thisMonthBillableHours: 0,
    thisMonthNonBillableHours: 0,
    thisYearHours: 0,
    thisYearBillableHours: 0,
    thisYearNonBillableHours: 0,
  });

  const [startEndDate, setStartEndDate] = useState({ start: "", end: "" });

  const { isSm, isXl } = useResponsive();

  const todayDate = format(new Date(), "MMMM d, yyyy");

  useEffect(() => {
    // dispatch(getAllEmployeesByAdminId());
    fetchDashboardData();
    handleIntervalChange("daily");
  }, []);

  const fetchDashboardData = async () => {
    try {
      const response = await axiosInstance.get(
        `${API}/workHour/adminDashboardTotals`
      );

      const {
        todayHours,
        todayBillableHours,
        todayNonBillableHours,
        thisWeekHours,
        thisWeekBillableHours,
        thisWeekNonBillableHours,
        thisMonthHours,
        thisMonthBillableHours,
        thisMonthNonBillableHours,
        thisYearHours,
        thisYearBillableHours,
        thisYearNonBillableHours,
      } = response.data;

      setDashboardTotals({
        todayHours,
        todayBillableHours,
        todayNonBillableHours,
        thisWeekHours,
        thisWeekBillableHours,
        thisWeekNonBillableHours,
        thisMonthHours,
        thisMonthBillableHours,
        thisMonthNonBillableHours,
        thisYearHours,
        thisYearBillableHours,
        thisYearNonBillableHours,
      });
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  const getAdminWorkHourChart1 = async (interval) => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `${API}/workHour/getAdminWorkHoursChart1`,
        { params: { interval } }
      );
      setLoading(false);
      return response.data?.workHoursData;
    } catch (error) {
      setLoading(false);
      errorAlert("Error fetching data");
      console.error("Error fetching dashboard data:", error);
    }
  };

  const getLabelsForInterval = useCallback((interval) => {
    switch (interval) {
      case "daily":
        return getLastNDaysLabels(7);
      case "weekly":
        return getLastNWeeksLabels(4);
      case "monthly":
        return getLastNMonthsLabels(12);
      case "yearly":
        return getLastNYearsLabels(4);
      default:
        return [];
    }
  }, []);

  const getLastNDaysLabels = (n) => {
    const labels = [];
    const today = new Date();
    for (let i = n - 1; i >= 0; i--) {
      const date = new Date(today);
      date.setDate(date.getDate() - i);
      labels.push(formatMonthDate(date.toISOString().split("T")[0]));
    }
    return labels;
  };

  const getLastNWeeksLabels = (n) => {
    const labels = [];
    const today = new Date();
    for (let week = n - 1; week >= 0; week--) {
      const startOfWeek = new Date(today);
      startOfWeek.setDate(today.getDate() - today.getDay() - (week - 1) * 7);
      labels.push(formatMonthDate(startOfWeek.toISOString().split("T")[0]));
    }
    return labels;
  };

  const getLastNMonthsLabels = (n) => {
    const labels = [];
    const today = new Date();
    for (let month = n - 1; month >= 0; month--) {
      const firstOfMonth = new Date(
        today.getFullYear(),
        today.getMonth() - month,
        1
      );
      labels.push(
        firstOfMonth.toLocaleDateString("default", { month: "short" })
      );
    }
    return labels;
  };

  const getLastNYearsLabels = (n) => {
    const labels = [];
    const currentYear = new Date().getFullYear();
    for (let year = n - 1; year >= 0; year--) {
      labels.push(`${currentYear - year}`);
    }
    return labels;
  };

  const activeAssociatesAndPartners = employees.filter(
    (emp) =>
      billedDesignations.includes(emp.designation) && emp.status === "active"
  );

  const handleIntervalChange = useCallback(
    async (interval) => {
      setActiveInterval(interval);
      let availableHours = {};

      const workHoursData = await getAdminWorkHourChart1(interval);

      const labels = getLabelsForInterval(interval);
      const availableHoursData = [];
      const billableHoursData = [];
      const billedHoursData = [];

      if (employees.length > 0) {
        availableHours = {
          daily: 7 * activeAssociatesAndPartners.length,
          weekly: 35 * activeAssociatesAndPartners.length,
          monthly: 154 * activeAssociatesAndPartners.length,
          yearly: 1820 * activeAssociatesAndPartners.length,
        };
      }

      workHoursData.length > 0 &&
        workHoursData.forEach(({ billableWorkHours, billedWorkHours }) => {
          availableHoursData.push(availableHours[interval]);
          billableHoursData.push(billableWorkHours);
          billedHoursData.push(billedWorkHours);
        });

      const newChartData = {
        labels,
        datasets: [
          // {
          //   label: "Available Hours",
          //   data: availableHoursData,
          //   fill: true,
          //   borderColor: "#4bc0c0",
          //   backgroundColor: "rgba(75, 192, 192, 0.5)",
          // },
          {
            label: "Clocked Hours",
            data: billableHoursData.reverse(),
            borderColor: "#ffcd56",
            backgroundColor: "rgba(255, 205, 86, 0.5)",
          },
          // {
          //   label: "Billed Hours",
          //   data: billedHoursData.reverse(),
          //   borderColor: "#ff6384",
          //   backgroundColor: "rgba(255, 99, 132, 0.5)",
          // },
        ],
      };

      setChartData(newChartData);

      // Update start and end date
      setStartEndDate({
        start: labels[labels.length - 2],
        end: labels[labels.length - 1],
      });

      // Update Bar Chart as well
      // handleIntervalChangeBarGraph(interval);
    },
    [getLabelsForInterval, employees, activeAssociatesAndPartners.length]
  );

  // Get the data based on the selected interval
  const getIntervalData = () => {
    switch (activeInterval) {
      case "daily":
        return {
          totalHours: dashboardTotals.todayHours,
          billableHours: dashboardTotals.todayBillableHours,
          nonBillableHours: dashboardTotals.todayNonBillableHours,
        };
      case "weekly":
        return {
          totalHours: dashboardTotals.thisWeekHours,
          billableHours: dashboardTotals.thisWeekBillableHours,
          nonBillableHours: dashboardTotals.thisWeekNonBillableHours,
        };
      case "monthly":
        return {
          totalHours: dashboardTotals.thisMonthHours,
          billableHours: dashboardTotals.thisMonthBillableHours,
          nonBillableHours: dashboardTotals.thisMonthNonBillableHours,
        };
      case "yearly":
        return {
          totalHours: dashboardTotals.thisYearHours,
          billableHours: dashboardTotals.thisYearBillableHours,
          nonBillableHours: dashboardTotals.thisYearNonBillableHours,
        };
      default:
        return {
          totalHours: 0,
          billableHours: 0,
          nonBillableHours: 0,
        };
    }
  };

  const { totalHours, billableHours, nonBillableHours } = getIntervalData();

  // console.log("employees admin",employees)

  return (
    <>
      <CssBaseline />
      <Container maxWidth={isXl ? "xl" : "md"} sx={{ my: 2 }}>
        <Grid
          container
          spacing={2}
          display="flex"
          flexDirection={isSm ? "column" : "row"}
          justifyContent="center"
          alignItems="center"
          paddingX={5}
        >
          {/* Interval Selector and Date */}
          <Grid item xs={12} sm={12}>
            <Box
              display="flex"
              justifyContent={isSm ? "center" : "start"}
              alignItems="center"
              gap={2}
              marginBottom={2}
            >
              <Typography variant="h6" fontWeight="550">
                {todayDate}
              </Typography>
              <Select
                value={activeInterval}
                onChange={(e) => handleIntervalChange(e.target.value)} // Correctly update both graphs
                className="h-8 bg-[#F8F8F8] border-hidden"
                sx={{
                  fontWeight: "bold",
                  "& .MuiSelect-select": {
                    fontWeight: "bold",
                  },
                }}
              >
                <MenuItem value="daily">Daily</MenuItem>
                <MenuItem value="weekly">Weekly</MenuItem>
                <MenuItem value="monthly">Monthly</MenuItem>
                <MenuItem value="yearly">Yearly</MenuItem>
              </Select>
              <Typography variant="body1">
                {activeInterval === "weekly"
                  ? `${startEndDate.start} - ${startEndDate.end} `
                  : startEndDate.end}
              </Typography>
            </Box>
          </Grid>

          {/* Cards Section */}
          <Grid item container xs={12} justifyContent="center">
            <Grid container spacing={2}>
              {/* Total active lawyers  */}
              <Grid item xs={12} sm={6} md={3}>
                <Card
                  sx={{
                    height: 110,
                    padding: "0px 0px",
                    backgroundColor: COLORS.primary2,
                    borderRadius: "10px",
                    boxShadow: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/admin/allresources")}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      height: "100%",
                    }}
                  >
                    <Typography variant="body2">Active Fee Earners</Typography>
                    <Typography variant="h5">
                      {employees.length > 0 &&
                        employees.filter(
                          (emp) =>
                            emp.status === "active" &&
                            billedDesignations.includes(emp.designation)
                        ).length}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              {/* Total Hours Card */}
              <Grid item xs={12} sm={6} md={3}>
                <Card
                  sx={{
                    height: 110,
                    padding: "0px 20px",
                    backgroundColor: "#F8F8F8",
                    borderRadius: "10px",
                    boxShadow: "none",
                  }}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      height: "100%",
                    }}
                  >
                    <Typography variant="body2">Total Hours</Typography>
                    <Typography variant="h5">
                      {totalHours.toFixed(2)} h
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              {/* Billable Hours Card */}
              <Grid item xs={12} sm={6} md={3}>
                <Card
                  sx={{
                    height: 110,
                    padding: "0px 20px",
                    backgroundColor: "#F8F8F8",
                    borderRadius: "10px",
                    boxShadow: "none",
                  }}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      height: "100%",
                    }}
                  >
                    <Typography variant="body2">Clocked Hours</Typography>
                    <Typography variant="h5">
                      {billableHours.toFixed(2)} h
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              {/* Non-Billable Hours Card */}
              <Grid item xs={12} sm={6} md={3}>
                <Card
                  sx={{
                    height: 110,
                    padding: "0px 20px",
                    backgroundColor: "#F8F8F8",
                    borderRadius: "10px",
                    boxShadow: "none",
                  }}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      height: "100%",
                    }}
                  >
                    <Typography variant="body2">Non-Billable Hours</Typography>
                    <Typography variant="h5">
                      {nonBillableHours.toFixed(2)} h
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          {/* Line Chart */}
          <Grid item xs={12}>
            <Box
              width="100%"
              sx={{
                borderRadius: "10px",
              }}
            >
              {loading ? (
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  height="400px"
                  gap="20px"
                  borderRadius="10px"
                >
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height={400}
                    sx={{ bgcolor: "#E0E0E0", borderRadius: "10px" }}
                  />
                  <Typography variant="h5">Graph Loading...</Typography>
                </Box>
              ) : (
                <LineChart data={chartData} />
              )}
            </Box>
          </Grid>
        </Grid>

        {/* Bar Graph */}
        {/* <Grid item xs={12} sm={12} md={12} marginTop="20px">
          <BarChart data={barChartData} />
        </Grid> */}
      </Container>
    </>
  );
};

export default AdminDashboard;
